import {Component, OnInit} from '@angular/core';
import {EditService} from './edit.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';


@Component({
    selector: 'app-edit',
    templateUrl: './edit.component.html',
    styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
    
    user: any = {};
    loading: boolean;
    errors: boolean;
    display:boolean;
    labeltext:any;
    EditProfileForm: FormGroup;
    DOBSelectedDates: Date;
    constructor(private referralService: EditService) { 
        
    }
    ngOnInit() {
        
    }
    

    
    

    getUserProfile() {
        
        
        
    }

    

    

}