import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { GroupSidebarService } from './sidebar.service';
import { FollowersService } from '../../followers/followers.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ConfirmationService} from 'primeng/api';
import { ProfileService } from '../../profile/profile.service';
import {MessageService} from 'primeng/api';
import { Location } from '@angular/common';
import { imagePathHelper } from "../../../helpers/imagePathHelper";
import Swal from "sweetalert2";
import { AuthService } from 'app/services/auth.service';
import { Globals } from 'app/globals';
import { CookiesService } from 'app/shared/services/cookies.services';
import { EncryptionService } from 'app/shared/services/encryption.services';
@Component({
    selector: 'app-group-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})

export class GroupSidebarComponent implements OnInit {
    @ViewChild('myInput')
    myInputVariable: ElementRef;
    thoughtmodal=false;
    user: any;
    allOwnGroupList:any=[];
    imagePath = imagePathHelper;
    ThoughtDayForm: FormGroup;
    editProfileUrl:boolean = (((this.router.url.split("/"))[2] == 'profile'));
    groupCreationPage:boolean = (((this.router.url.split("/"))[2] == 'creation'));
    groupName :string = ((this.router.url.split("/"))[3]);
    constructor(private cookieService: CookiesService, private encryptionServices: EncryptionService, private globals: Globals,private followersService: FollowersService,private SidebarService: GroupSidebarService,private profileService: ProfileService, private router: Router,private fb: FormBuilder, private messageService: MessageService, private confirmationService: ConfirmationService,private location: Location,public authService: AuthService) { }

    ngOnInit() {
        this.user = this.authService.getLoggedInUser();
        this.ThoughtDayForm = this.fb.group({
            thought: ['', [Validators.required]],
        });
        this.getOwnGroupList();
    }

    get thought() {
        return this.ThoughtDayForm.controls.thought
    }
    getOwnGroupList() {
        this.allOwnGroupList=[];
        this.SidebarService.ownGroupList().subscribe(res => {
            if (res) {
                console.log('res',res)
                this.allOwnGroupList = res;
                console.log('res',this.allOwnGroupList)
            }
        },error => {});
    }
    async updateThought(formData) {
        const params = {
            'thought': formData.thought,
        }
        Swal.fire({
            icon: 'warning',
            title: 'Thought of the day',
            showCancelButton: true,
            html: 'Do you want to update/change your thought ?',
            confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Yes',
              confirmButtonAriaLabel: 'Yes',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i> No',
              cancelButtonAriaLabel: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                this.SidebarService.updateThoughtDay(params).subscribe((res: any) => {
                    
                    if(res.success){
                        this.getUserProfile();
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: res.message
                        });
                        this.thoughtmodal=false;
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: res.message
                        });
                        this.thoughtmodal=false;
                    }
                }, (err: any) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: err.error
                        
                    });
                    this.thoughtmodal=false;
                });
            }
    });

    }

    getUserProfile() {
       // this.FollowedUser=[];
        this.profileService.profile().subscribe(res => {
            //this.user = res;  
            this.cookieService.set(this.globals.tw_user_cookie, this.encryptionServices.encrypt(JSON.stringify(res)));
            this.user = this.authService.getLoggedInUser();       
        },error => {});
    }
      

    GroupDashboard(Group){
        this.SidebarService.setGroupData(Group);
        this.router.navigateByUrl(`group/dashboard/${Group.uuid}`);
    }

    thoughtModal(){
        this.thoughtmodal=true;
        this.ThoughtDayForm.controls['thought'].setValue(this.user.thought);
    }


    goBack() {
        this.location.back();
    }

    
    goTravelHistory(){
        let groupId = this.SidebarService.groupData.uuid;
        this.router.navigate(['group/travelplan/'+ groupId]);
    }
    goNewPost(){
        let groupId = this.SidebarService.groupData.id;
        let groupUuid = this.SidebarService.groupData.uuid;
        this.router.navigate(['group/post/'+ groupId+'/'+groupUuid]);
    }
    GoAddBlog(){
        this.router.navigate(['solo/blogs'])
    }
    GoReferral(){
        this.router.navigate(['solo/referral'])
    }
    GoAddBrand(){
        this.router.navigate(['solo/list_brand'])
    }

    GoFollowers(){
        let groupId = this.SidebarService.groupData.uuid;
        this.router.navigate(['group/followers/'+groupId])
    }

    GoRoutes(){
        this.router.navigate(['solo/routes'])
    }
    GoInbox(){
        this.router.navigate(['solo/messages/send'])
    }

    GoSoloSettings(){
        this.router.navigate(['solo/profile/settings'])
    }
    
    GoNews(){
        this.router.navigate(['solo/news/list'])
    }

    // thoughtModal(){
    //     this.thoughtmodal=true;
    //     this.ThoughtDayForm.controls['thought'].setValue(this.user.thought);
    // }

      switchToUserProfile(username){
        this.router.navigateByUrl(`userprofile/${username}`);
    }
}
