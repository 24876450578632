import { Injectable } from '@angular/core';
import { BaseService } from '../../../_services/base.service';

@Injectable()
export class GroupCreationService {

    constructor(private baseService: BaseService) { }

        getFollowersList() {
            return this.baseService.getRequest(`user/follow/users`).toPromise();
        }

        addUpdate(formData) {
            return this.baseService.postFile(`group/create`,formData);
        }

    }



