import { Component, OnInit } from '@angular/core';
import { ListBrandService } from './list.service';
import { generalHelper } from '../../../helpers/generalHelper';
import Swal from "sweetalert2";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';

@Component({
    selector: 'app-addbrand',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})

export class ListBrandComponent implements OnInit {
    generalHelper = generalHelper;
    AddBrandForm: FormGroup;
    user: any = {};
    loading: boolean;
    errors: boolean;
    addBrand:boolean;
    endoresd:[];
    Brand:any;
    BrandId: any = [];
    BrandName: any = [];
    filteredBrandId:any = [];
    filteredBrandName:any = [];
    FilterBrandUniqueId:any;
    editEndoresd:any;
    SelectedBrandName:any;
    SelectedBrandId:any;
    constructor(private BrandService: ListBrandService,private fb: FormBuilder,private confirmationService: ConfirmationService, private messageService: MessageService, private router: Router) { }

    ngOnInit() {
        this.getEndoresd();
    }

    getEndoresd() {
        this.BrandService.getEndoresd().toPromise().then(res => {
            this.endoresd = res;
        }).catch(e => {});
    }
    
    addBrandModal() {
        this.router.navigate(['solo/add_brand'])
    }

    editBrandModal(id) {
        this.router.navigateByUrl(`solo/edit_brand/${id}`);
    }

}
