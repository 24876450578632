import { Injectable } from '@angular/core';
import { CookieService  } from 'ngx-cookie-service';
import { Globals } from './../../globals';

@Injectable()
export class CookiesService {    
   
    constructor(private cookieService: CookieService,private globals: Globals ) { }  
    
    check(name: string){  
        let response;      
        if(this.cookieService.check(name))           
            response = true;       
        else
            response = false;
        
        return response;
    }

    get(name: string){  
        let response;      
        if(this.cookieService.get(name))           
            response = this.cookieService.get(name);       
        else
            response = false;
        
        return response;
    }

    set(name: string, value: string){ 
        let response;   
        this.cookieService.set(name,value,this.globals.cookieExpiration,'/',this.globals.hostname,this.globals.cookieSecured,"Strict");
       
        if(this.get(name)) 
            response = this.get(name);       
        else
            response = false;
        
        return response;
    }

    delete(name: string, hostname: string){ 
        let response;    

        this.cookieService.delete(name, "/", hostname);
        if(this.get(name)) 
            response = this.get(name);       
        else
            response = false;

        return response;
    }
   
}
