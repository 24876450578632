import { Injectable } from '@angular/core';
import { BaseService } from '../../../_services/base.service';

@Injectable()
export class OtherUserRoutesService {

    constructor(private baseService: BaseService) { }

    getSoloRoutesEdit(id) {
        return this.baseService.getRequest(`routes-history/${id}`).toPromise();
    }
    
    getCountries() {
        return this.baseService.getRequest(`countries`).toPromise();
    }

    getStates(id) {
        return this.baseService.getRequest(`states/`+id).toPromise();
    }

    getCities(id) {
        return this.baseService.getRequest(`cities/`+id).toPromise();
    }

}
