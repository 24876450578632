import {Component, OnInit} from '@angular/core';
import {soloTravelHistoryService} from '../soloTravelHistory.service';
import {FormBuilder, FormGroup, Validators, FormArray} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {ConfirmationService} from 'primeng/api';
import {Loader} from "@googlemaps/js-api-loader";
import Swal from "sweetalert2";
import {generalHelper} from "../../../../helpers/generalHelper";
import {HttpEventType} from '@angular/common/http';
import {ValidationsService} from "../../../../services/validations.service";
import { KeyValue } from '@angular/common';
import { AuthService } from 'app/services/auth.service';
import { environment } from 'enviornments/environment';

@Component({
    selector: 'app-createsolotravelhistory',
    templateUrl: './create.component.html',
    styleUrls: ['./create.component.scss']
})

export class CreateTravelHistoryComponent implements OnInit {
    env=environment;
    steps: any[];
    activeIndex: number = 0;
    generalHelper = generalHelper;
    user: any = {};
    loading: boolean;
    errors: boolean;
    AddHistoryForm: FormGroup;
    previewData: any;
    kms: string = '';
    startLatLng:{ lng: number, lat: number };
    endLatLng:{ lat: number, lng: number };
    routeData: any;
    images: any = [];
    uploadStatus: number;
    token: any;
    historyId:any;
    mapHeight = '580px';
    slides : any = [];
    countries: any = [];
    states: any = [];
    cities: any = [];
    selectedCountry: any;
    selectedState: any;
    selectedCity: any;
    contentLoader:boolean=false;
    totalDays: number = 0;
    itinerariesArr = [];
    checkListArr = [];
    dayStartLocation: google.maps.places.PlaceResult;
    showItineraryMap = false;
    errorsMessage:any = '';
    routeStartdate:any;
    showItineraryMap2=false;
    StartLocation:any='';
    EndLocation:any='';
    MainEndPointName:any;
    MainStartPointName:any;
    date1: Date;
    date2: Date;
    uploadeFileEnabled=false;
    Viewimages:any=[];
    minimumDate:any = new Date(Date.now());
    displayPreview: boolean = false;
    imgUrl=''
    constructor(private soloTravelHistoryService: soloTravelHistoryService, private confirmationService: ConfirmationService, private messageService: MessageService, private fb: FormBuilder, private router: Router, private route: ActivatedRoute,private customValidate: ValidationsService,private authService: AuthService) {
        this.steps = [
            { label: 'Travel Plan' },
            { label: 'Itenaries' },
            { label: 'Checklist & Share Memories' }
            
          ];
     }

    ngOnInit() {
        this.token = this.route.snapshot.params.id;
        
        this.AddHistoryForm = this.fb.group({
            trip_name: ['', [Validators.required]],
            startDate: ['', [Validators.required]],
            endDate: ['', [Validators.required]],
            start_point: ['', [Validators.required]],
            end_point: ['', [Validators.required]],
            description: ['', [Validators.required]],
            plan_status: ['Upcoming', [Validators.required]],
            travel_mode: ['Bus', [Validators.required]],
            privacy_status: ['Public', [Validators.required]],
            clothes: [''],
            gadgets: [''],
            tools: [''],
            documents: [''],
            toiletries: [''],
            itineraries : this.fb.array([]),
        });
        this.initGMap();
        this.user = this.authService.getLoggedInUser();
        this.countriesList();
        if(this.token){
            this.getTravelHistoryPatch();
            this.imgUrl=this.env.imageURL+'/images/'+this.user.id+'/travel_histories/'+this.route.snapshot.params.id+'/';
        }

        this.CalendarDate();
        
    }

    CalendarDate(){        
        var now = new Date();
        var isoDate = new Date(now).toISOString();
        let newDate = (isoDate.split('T')[0])
        let finaldate =this.customValidate.AddMinusInDate(newDate , 0 , 'minus');
        this.date1 = new Date(finaldate);
        this.date2 = new Date(finaldate);        
    }
    addItineraries(itineraries,data): void {
        itineraries.push(this.fb.group({
            start : [data ? data.start.location : ''],
            end : [data ? data.end.location : ''],
            totalKms : [data ? data.totalKMS : 0],
            selected: [data ? data.selected : false]
        }));
    }
    onCheckboxChange(index: number): void {
        const formArray = this.AddHistoryForm.get('itineraries') as FormArray;
        const itineraryGroup = formArray.at(index) as FormGroup;
        
        this.itinerariesArr[index+1].start.placeData = this.itinerariesArr[index].start.placeData;
        this.itinerariesArr[index+1].start.latLng = this.itinerariesArr[index].start.latLng;
        this.itinerariesArr[index+1].start.location = this.itinerariesArr[index].start.location;
        formArray.at(index+1).get('start').setValue(this.itinerariesArr[index].start.location);

        if (itineraryGroup.get('selected').value) {
            itineraryGroup.get('start').setValue('');
            itineraryGroup.get('end').setValue('');
            itineraryGroup.get('totalKms').setValue(0);
            itineraryGroup.get('selected').setValue(true);
        }
    }

    patchItineraries(itineraries,itinerary,index) {
        let formArray = this.AddHistoryForm.get('itineraries') as FormArray;
        itineraries[index+1].start.placeData = itinerary.end.placeData;
        itineraries[index+1].start.latLng = itinerary.end.latLng;
        itineraries[index+1].start.location = itinerary.end.location;
        formArray.at(index+1).get('start').setValue(itinerary.end.location);
    }

    initItineraries(): FormGroup {
        return this.fb.group({
            start : [''],
            end: [''],
            totalKms : [0],
            selected : false
        });
    }

    initCheckList(): FormGroup {
        return this.fb.group({
            clothes: [''],
            gadgets : [''],
            tools: [''],
            documents: [''],
            toiletries : ['']
        });
    }

    ShowHistoryList(){
        this.router.navigate(['solo/travel-history'])
    }

    get trip_name() {
        return this.AddHistoryForm.controls.trip_name;
    }
    get startDate() {
        return this.AddHistoryForm.controls.startDate;
    }
    get endDate() {
        return this.AddHistoryForm.controls.endDate;
    }
    get start_point() {
        return this.AddHistoryForm.controls.start_point;
    }
    get end_point() {
        return this.AddHistoryForm.controls.end_point;
    }
    get description() {
        return this.AddHistoryForm.controls.description;
    }
    get privacy_status() {
        return this.AddHistoryForm.controls.privacy_status;
    }
    get plan_status() {
        return this.AddHistoryForm.controls.plan_status;
    }
    get travel_mode() {
        return this.AddHistoryForm.controls.travel_mode;
    }
    get clothes() {
        return this.AddHistoryForm.controls.clothes;
    }
    get gadgets() {
        return this.AddHistoryForm.controls.gadgets;
    }
    get tools() {
        return this.AddHistoryForm.controls.tools;
    }
    get documents() {
        return this.AddHistoryForm.controls.documents;
    }
    get toiletries() {
        return this.AddHistoryForm.controls.toiletries;
    }
    get itineraries() {
        return this.AddHistoryForm.get('itineraries')['controls'];
    }

    async addHistory(data) {
        
        this.errorsMessage = '';
        if (!this.selectedCountry){
            Swal.fire('Validation Error','Please select country from list','error');
            return false;
        }
        if (!this.selectedState){
            Swal.fire('Validation Error','Please select state from list','error');
            return false;
        }
        if (!this.selectedCity){
            Swal.fire('Validation Error','Please select city from list','error');
            return false;
        }
        if(this.token){
            this.historyId=this.token;
        }else{
            this.historyId=0;
        }
       
        if(data.clothes.length > 0){
            for (let i = 0; i < data.clothes.length; i++) {
              if (!this.customValidate.restrictComa(data.clothes[i])) {
                this.errorsMessage= "Please do not add , (coma) and any special character in clothes.";
                return false;
              }
            }
          }

          if(data.gadgets.length > 0){
            for (let i = 0; i < data.gadgets.length; i++) {
              if (!this.customValidate.restrictComa(data.gadgets[i])) {
                this.errorsMessage="Please do not add , (coma) and any special character in gadgets.";
                return false;
              }
               
            }
            
          }

          if(data.tools.length > 0){
            for (let i = 0; i < data.tools.length; i++) {
              if (!this.customValidate.restrictComa(data.tools[i])) {
                this.errorsMessage="Please do not add , (coma) and any special character in tools.";
                return false;
              }
              
            }
            
          }

          if(data.documents.length > 0){
            for (let i = 0; i < data.documents.length; i++) {
              if (!this.customValidate.restrictComa(data.documents[i])) {
                this.errorsMessage = "Please do not add , (coma) and any special character in documents.";
                return false;
              }
              
            }
            
          }

          if(data.toiletries.length > 0){
            for (let i = 0; i < data.toiletries.length; i++) {
              if (!this.customValidate.restrictComa(data.toiletries[i])) {
                this.errorsMessage= "Please do not add , (coma) and any special character in toiletries.";
                return false;
              }
            }
            
          }
        
        let itinerariesArr = [];
        if(this.itinerariesArr.length){
            for(let itinerary of this.itinerariesArr){
                delete itinerary.routeData;
                if (!itinerary.end.location) {
                    itinerary.start.placeData = "";
                    itinerary.start.location = "";
                    itinerary.start.latLng = { lat: 0, lng: 0 }
                    itinerary.selected = true;
                } else {
                    itinerary.selected = false;
                }
                itinerariesArr.push(itinerary)
            }
        }

        console.log('itinerariesArr',itinerariesArr);

        data.checkList = {
            clothes : data.clothes.toString(),
            gadgets : data.gadgets.toString(),
            tools : data.tools.toString(),
            documents : data.documents.toString(),
            toiletries : data.toiletries.toString(),
        }

        data.start_point = this.MainStartPointName
        data.end_point = this.MainEndPointName
        this.contentLoader=true;
        const params = new FormData();
        params.append('id', this.historyId);
        params.append('trip_name', data.trip_name);
        params.append('start_date', this.customValidate.formatDate(data.startDate));
        params.append('end_date', this.customValidate.formatDate(data.endDate));
        params.append('country', this.selectedCountry.id);
        params.append('state', this.selectedState.id);
        params.append('city', this.selectedCity.id);
        params.append('start_point', data.start_point);
        params.append('start_latLng', JSON.stringify(this.startLatLng));
        params.append('end_point', data.end_point);
        params.append('end_latLng', JSON.stringify(this.endLatLng));
        params.append('total_km', this.kms);
        params.append('description', data.description);
        params.append('privacy_status', data.privacy_status);
        params.append('plan_status', data.plan_status);
        params.append('travel_mode', data.travel_mode);
        params.append('route_data', JSON.stringify(this.routeData));
        params.append('checklist', JSON.stringify(data.checkList));
        params.append('itinerary', JSON.stringify(itinerariesArr));
        for (var i = 0; i < this.images.length; i++) {
            params.append("attachments[]", this.images[i], this.images[i].name);
        }
       

        this.soloTravelHistoryService.AddHistory(params).subscribe(event => {
            if (event.type === HttpEventType.UploadProgress){
                this.uploadStatus = Math.round(event.loaded / event.total * 100)
            } else if (event.type === HttpEventType.Response){
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: event.body.message
                });
                this.router.navigate(['solo/travel-history']);
            }
        }, error => {
            this.contentLoader=false;
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message
            })
        });
    }

    getTravelHistoryPatch() {
        this.contentLoader=true;
        const ID  = this.token;
         this.soloTravelHistoryService.getSoloTravelHistoryEdit(ID).then(async (res) => {
         let soloHistory = res.data;
         
         let itineraries = soloHistory.itinerary ? JSON.parse(soloHistory.itinerary) : null;
         if (res.success) {
            this.MainStartPointName = soloHistory.start_point;
            this.MainEndPointName = soloHistory.end_point;
             await this.statesList(soloHistory.country);
             await this.citiesList(soloHistory.state);
             this.AddHistoryForm.patchValue({
                 trip_name: soloHistory.trip_name,
                 startDate: new Date(soloHistory.start_date),
                 endDate: new Date(soloHistory.end_date),
                 country: soloHistory.country,
                 state: soloHistory.state,
                 city: soloHistory.city,
                 start_point: soloHistory.start_point,
                 end_point: soloHistory.end_point,
                 total_km: soloHistory.total_km,
                 description: soloHistory.description,
                 privacy_status: soloHistory.privacy_status,
                 plan_status: soloHistory.plan_status,
                 travel_mode: soloHistory.travel_mode,
             });
             this.routeStartdate=soloHistory.start_date;
             this.checkDates(itineraries);
            if(soloHistory.checklist){
                let checklist = JSON.parse(soloHistory.checklist);
                this.AddHistoryForm.controls['clothes'].setValue(checklist.clothes.split(','), {onlySelf: true});
                this.AddHistoryForm.controls['gadgets'].setValue(checklist.gadgets.split(','), {onlySelf: true});
                this.AddHistoryForm.controls['tools'].setValue(checklist.tools.split(','), {onlySelf: true});
                this.AddHistoryForm.controls['documents'].setValue(checklist.documents.split(','), {onlySelf: true});
                this.AddHistoryForm.controls['toiletries'].setValue(checklist.toiletries.split(','), {onlySelf: true});
            }
            if(res.data.files){
                let self=this;
               // this.slides = JSON.parse(res.data.files);
               let slides = JSON.parse(res.data.files);
                self.Viewimages = [];
                for(let file of slides){
                    self.Viewimages.push({previewImageSrc:file.attachmentName.replace('public',self.env.imageURL), thumbnailImageSrc:file.attachmentName.replace('public',self.env.imageURL), alt:file.attachmentName, title:file.attachmentName});
                }
            }  
             this.startLatLng = JSON.parse(soloHistory.start_latLng);
             this.endLatLng = JSON.parse(soloHistory.end_latLng);
             this.selectedCountry = this.countries.find(el => {
                 return el.id == soloHistory.country
             });
             this.selectedState = this.states.find(el => {
                 return el.id == soloHistory.state
             });
             this.selectedCity = this.cities.find(el => {
                 return el.id == soloHistory.city
             });
             if (this.endLatLng) {
                 let map = new google.maps.Map(document.getElementById("gmap") as HTMLElement, {
                     center: {lat: 28.7041, lng: 77.1025},
                     zoom: 6,
                 });
                 this.calcRoute(map, new google.maps.DirectionsService(), new google.maps.DirectionsRenderer(),this.startLatLng,this.endLatLng);
             }
             this.contentLoader=false;
         }
     });

    }

    getConfirmation(header: string, message: string) {
        return new Promise((resolve, reject) => {
            this.confirmationService.confirm({
                message: message,
                header: header,
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    resolve(true);
                },
                reject: () => {
                    resolve(false);
                }
            });
        });
    }

    initGMap() {
        const loader = new Loader({
            apiKey: environment.gMapKey,
            version: "weekly",
            libraries: ["places","drawing","geometry"]
        });

        loader.load().then(() => {
            let center = {lat: 28.7041, lng: 77.1025}, map: google.maps.Map, infoWindow: google.maps.InfoWindow, startAutocomplete, endAutoComplete, marker, service = new google.maps.DirectionsService(), display = new google.maps.DirectionsRenderer(), defaultBounds = {
                north: center.lat + 0.1,
                south: center.lat - 0.1,
                east: center.lng + 0.1,
                west: center.lng - 0.1,
            };
            const startInput = document.getElementById("start_point") as HTMLInputElement;
            const endInput = document.getElementById("end_point") as HTMLInputElement;
            
            const options = {
                bounds: defaultBounds,
                componentRestrictions: { country: "in" },
                fields: ["address_components", "geometry", "icon", "name"],
                strictBounds: false,
                types: ["establishment"],
            };

            map = new google.maps.Map(document.getElementById("gmap") as HTMLElement, {
                center: center,
                zoom: 6,
            });

            startAutocomplete = new google.maps.places.Autocomplete(startInput, options);
            endAutoComplete = new google.maps.places.Autocomplete(endInput, options);

            infoWindow = new google.maps.InfoWindow();
            const locationButton = document.createElement("button");
            locationButton.innerHTML = "<i class='fa fa-location-arrow'></i> Locate me";
            locationButton.classList.add("custom-map-control-button");
            map.controls[google.maps.ControlPosition.TOP_CENTER].push(locationButton);

            startAutocomplete.addListener("place_changed", () => {
                marker = new google.maps.Marker({
                    map,
                    anchorPoint: new google.maps.Point(0, -29),
                });

                const place = startAutocomplete.getPlace();
                
                if (!place.geometry || !place.geometry.location) {
                    Swal.fire('Not available',"No details available for input: '" + place.name + "'",'error');
                    return false;
                }
                if (place.geometry || place.geometry.location) {
                    this.MainStartPointName= place.name;
                }

                if (place.geometry.viewport) {
                    map.fitBounds(place.geometry.viewport);
                } else {
                    map.setCenter(place.geometry.location);
                    map.setZoom(12);
                }
                /*if (!this.startLatLng) {
                    this.startLatLng = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}
                    marker.setPosition(place.geometry.location);
                    marker.setVisible(true);
                }
                else {
                    this.endLatLng = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}
                }*/
                this.startLatLng = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}
                marker.setPosition(place.geometry.location);
                marker.setVisible(true);
                if (this.endLatLng) this.calcRoute(map,service,display,this.startLatLng,this.endLatLng);
            });

            endAutoComplete.addListener("place_changed", () => {
                marker.setVisible(false);
                const place = endAutoComplete.getPlace();
                
                if (!place.geometry || !place.geometry.location) {
                    Swal.fire('Not available',"No details available for input: '" + place.name + "'",'error');
                    return false;
                }
                if (place.geometry || place.geometry.location) {
                    this.MainEndPointName= place.name;
                }

                if (place.geometry.viewport) {
                    map.fitBounds(place.geometry.viewport);
                } else {
                    map.setCenter(place.geometry.location);
                    map.setZoom(12);
                }
                if (!this.startLatLng) {
                    this.startLatLng = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}
                }
                else {
                    this.endLatLng = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}
                    this.calcRoute(map,service,display,this.startLatLng,this.endLatLng);
                }
            });

            locationButton.addEventListener("click", () => {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        (position: any) => {
                            const pos = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            };
                            if (!this.startLatLng) {
                                this.startLatLng = pos;
                            }
                            else {
                                this.endLatLng = pos;
                            }
                            marker = new google.maps.Marker({
                                position: pos,
                                map,
                                title: "Location found.",
                            });
                            infoWindow.setPosition(pos);
                            infoWindow.setContent("Location found.");
                            infoWindow.open(map);
                            map.setCenter(pos);
                        },
                        () => {
                            //console.log('Google map error occurred);
                        }
                    );
                }
            });
        });
    }

    getAddress (lat, lng) {
        return new Promise(function (resolve, reject) {
            var request = new XMLHttpRequest();

            var method = 'GET';
            var url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&sensor=true&key='+environment.gMapKey;
            var async = true;

            request.open(method, url, async);
            request.onreadystatechange = function () {
                if (request.readyState == 4) {
                    if (request.status == 200) {
                        var data = JSON.parse(request.responseText);
                        var address = data.results[0];
                        resolve(address);
                    }
                    else {
                        reject(request.status);
                    }
                }
            };
            request.send();
        });
    }

    calcRoute(map, directionsService, directionsDisplay,startLatLng,endLatLng) {
        const self = this;
        var start = new google.maps.LatLng(startLatLng.lat, startLatLng.lng);
        var end = new google.maps.LatLng(endLatLng.lat, endLatLng.lng);
        var request = {
            origin: start,
            destination: end,
            travelMode: google.maps.TravelMode.DRIVING
        };
        directionsService.route(request, function(response, status) {
            if (status == google.maps.DirectionsStatus.OK) {
                self.routeData = response.routes[0];
                self.kms = response.routes[0].legs[0].distance.text
                directionsDisplay.setDirections(response);
                directionsDisplay.setMap(map);
            } else {
                Swal.fire('Directions Request',"Directions Request from " + start.toUrlValue(6) + " to " + end.toUrlValue(6) + " failed: " + status,'error');
                return false;
            }
        });
    }

    async calcItineraryRoute(index, map = null, directionsService, directionsDisplay, itinerary) {
        if (!itinerary.start || !itinerary.start.latLng) {
            console.error("Error: Start location is undefined at index", index, itinerary.start);
            return;
        }
        if (!itinerary.end || !itinerary.end.latLng) {
            console.error("Error: End location is undefined at index", index, itinerary.end);
            return;
        }
    
        var start = new google.maps.LatLng(itinerary.start.latLng.lat, itinerary.start.latLng.lng);
        var end = new google.maps.LatLng(itinerary.end.latLng.lat, itinerary.end.latLng.lng);
    
        var request = {
            origin: start,
            destination: end,
            travelMode: google.maps.TravelMode.DRIVING
        };
    
        directionsService.route(request, async function(response, status) {
            if (status == google.maps.DirectionsStatus.OK) {
                itinerary.routeData = response.routes[0];
                itinerary.totalKMS = response.routes[0].legs[0].distance.text;
                if (map) {
                    directionsDisplay.setDirections(response);
                    directionsDisplay.setMap(map);
                }
            } else {
                Swal.fire(
                    'Directions Request',
                    "Directions Request from " + start.toUrlValue(6) + " to " + end.toUrlValue(6) + " failed: " + status,
                    'error'
                );
                return false;
            }
        });
    }
    

    onFileInput(e) {
        this.images = [];
        this.Viewimages = [];
        if (e.target.files && e.target.files.length) {
            let slides = [];
            for (var i = 0; i < e.target.files.length; i++) {
                if (this.images.length >= 5) {
                    Swal.fire('Limit Exceeded','You are not eligible to upload more then 5 Images','error');
                    return false;
                }
                this.images.push(e.target.files[i]);
                let reader = new FileReader();
                reader.onload = function(el) {
                    slides.push(el.target.result);
                }
                reader.readAsDataURL(e.target.files[i]);
            }
            this.mapHeight = '1000px';
            this.slides = slides;
        }
    }
    onFileInputMore(e) {
        // this.images = [];
        let self =this;
        if (e.target.files && e.target.files.length) {
            // let slides = [];
            for (var i = 0; i < e.target.files.length; i++) {
                if (this.images.length >= 5) {
                    Swal.fire('Limit Exceeded','You are not eligible to upload more then 5 Images','error');
                    return false;
                }
                this.images.push(e.target.files[i]);
                let reader = new FileReader();
                reader.onload = function(el) {
                    self.slides.push(el.target.result);
                }
                reader.readAsDataURL(e.target.files[i]);
            }
            this.mapHeight = '1000px';
            // this.slides = slides;
        }
    }

    removeImage(index: number) {
        this.slides.splice(index, 1);
        this.images.splice(index, 1);
      }

    countriesList() {
        this.soloTravelHistoryService.getCountries().then(res => {
            if (res.success) this.countries = res.data;
            else this.countries = [];
        });
    }

    async statesList(id = null) {
        let countryId = id ? id : this.selectedCountry.id;
        return this.soloTravelHistoryService.getStates(countryId).then(res => {
            if (res.success) this.states = res.data;
            else this.states = [];
        });
    }

    async citiesList(id = null) {
        let stateId = id ? id : this.selectedState.id;
        return this.soloTravelHistoryService.getCities(stateId).then(res => {
            if (res.success) this.cities = res.data;
            else this.cities = [];
        });
    }

    async findDiffBetween(date1, date2) {
        date1 = this.customValidate.formatDate(date1);
        date2 = this.customValidate.formatDate(date2);
        let fromDate = new Date(date1);
        let toDate = new Date(date2);

        let Difference_In_Time = toDate.getTime() - fromDate.getTime();

        return (Difference_In_Time / (1000 * 3600 * 24)) + 1;
    }

    makeAutoComplete(type,index) {
        const loader = new Loader({
            apiKey: environment.gMapKey,
            version: "weekly",
            libraries: ["places","drawing","geometry"]
        });
        loader.load().then(() => {
            let center = this.startLatLng,
                map = new google.maps.Map(document.getElementById("itineraryMap") as HTMLElement, {
                    center: center,
                    zoom: 6,
                }),
                service = new google.maps.DirectionsService(),
                display = new google.maps.DirectionsRenderer(),
                defaultBounds = {
                    north: center.lat + 0.1,
                    south: center.lat - 0.1,
                    east: center.lng + 0.1,
                    west: center.lng - 0.1,
                },
                itinerary = this.itinerariesArr[index];

            const options = {
                bounds: defaultBounds,
                componentRestrictions: { country: "in" },
                fields: ["address_components", "geometry", "icon", "name"],
                strictBounds: false,
                types: ["establishment"],
            };
            if (type == 'start') {
                const startInput = document.getElementById("dayStart" + index) as HTMLInputElement;
                let startLocation = new google.maps.places.Autocomplete(startInput, options);
                startLocation.addListener("place_changed", () => {

                    const place = startLocation.getPlace();

                    if (!place.geometry || !place.geometry.location) {
                        Swal.fire('Not available',"No details available for input: '" + place.name + "'",'error');
                        return false;
                    }

                });
            }
            if (type == 'end') {
                const endInput = document.getElementById("dayEnd" + index) as HTMLInputElement;
                let endLocation = new google.maps.places.Autocomplete(endInput, options);
                
                endLocation.addListener("place_changed", async () => {
                    const place = endLocation.getPlace();
                    if (!place.geometry || !place.geometry.location) {
                        Swal.fire('Not available', "No details available for input: '" + place.name + "'", 'error');
                        return false;
                    }
                    
                    itinerary.end.placeData = place;
                    itinerary.end.latLng = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
                    itinerary.end.location = place.name;
            
                    // ✅ Update the next day's start location
                    if (index < this.itinerariesArr.length - 1) {
                        let nextDayItinerary = this.itinerariesArr[index + 1];
                        nextDayItinerary.start.placeData = place;
                        nextDayItinerary.start.latLng = itinerary.end.latLng;
                        nextDayItinerary.start.location = itinerary.end.location;
            
                        // ✅ Update FormArray to reflect change in UI
                        const itineraries = this.AddHistoryForm.get('itineraries') as FormArray;
                        itineraries.at(index + 1).get('start').setValue(itinerary.end.location);
                    }
            
                    // ✅ Calculate route after setting start location
                    await this.calcItineraryRoute(index, map, service, display, itinerary);
                });
            }
            
           
            if (type == 'show') {
                this.StartLocation=itinerary.start.location;
                this.EndLocation=itinerary.end.location;
                this.showMapElement().then(async res => {
                    if (res) {
                        await this.calcItineraryRoute(index, map, service, display, itinerary);
                    }
                });
               
            }
        });
        
    }

    checkStatusOnDate(){
        let startDate = new Date(this.AddHistoryForm.get('startDate').value); // Modify with your start date
        let endDate = new Date(this.AddHistoryForm.get('endDate').value);
        const today = new Date();
    
        if (startDate > today && endDate > today) {
        this.AddHistoryForm.controls['plan_status'].setValue('Upcoming')
        this.uploadeFileEnabled = false;
        } else if (startDate <= today && endDate >= today) {
        this.AddHistoryForm.controls['plan_status'].setValue('Ongoing')
        this.uploadeFileEnabled = true;
        } else {
        this.AddHistoryForm.controls['plan_status'].setValue('Completed')
        this.uploadeFileEnabled = true;
        }
    }

    async checkDates(routeData = null) {
        this.checkStatusOnDate();
        this.totalDays = await this.findDiffBetween(this.AddHistoryForm.get('startDate').value, this.AddHistoryForm.get('endDate').value);
        
        const itineraries = this.AddHistoryForm.get('itineraries') as FormArray;
        itineraries.clear();
        this.itinerariesArr = [];
        for (let i = 0; i < this.totalDays; i++){
            await this.addItineraries(itineraries,(routeData ? routeData[i] : null));
            this.itinerariesArr.push({
                day: i+1,
                start: {
                    placeData: routeData ? routeData[i].start.placeData : '',
                    location: routeData ? routeData[i].start.location : ( i === 0 ? this.MainStartPointName : ""),
                    latLng: routeData ? routeData[i].start.latLng : ( i === 0 ? this.startLatLng : { lat: 0, lng: 0 }) // ✅ Ensure latLng is not undefined
                },
                end: {
                    placeData:  routeData ? routeData[i].end.placeData : '',
                    location:  routeData ? routeData[i].end.location : '',
                    latLng:  routeData ? routeData[i].end.latLng : { lat: 0, lng: 0 } // ✅ Default latLng
                },
                routeData : '',
                totalKMS :  routeData ? routeData[i].totalKMS : 0,
                selected : routeData ? routeData[i].selected:false
            });
        }
        console.log('this.MainStartPointName',this.MainStartPointName);
        itineraries.at(0).get('start').setValue(this.MainStartPointName);
    }

    async showMap(idx: number) {
        this.showItineraryMap = true;
        this.showItineraryMap2 = true;
        this.makeAutoComplete('show',idx);
    }

    async showMapElement() {
        return this.showItineraryMap = true;
    }

    async HideMap(){
        this.showItineraryMap2 = false;
        this.showItineraryMap = false;
    }

    statusValue(value){
        this.uploadeFileEnabled = (value=='Completed' || value=='Ongoing');
    }

    isCurrentStepValid(): boolean {
        switch (this.activeIndex) {
          case 0:
            return this.AddHistoryForm.get('trip_name').valid && 
                   this.AddHistoryForm.get('privacy_status').valid;
          case 1:
            return true; // Optional step, can always skip
          case 2:
            return this.AddHistoryForm.get('itineraries').valid;
          case 3:
            return this.AddHistoryForm.get('budgeting').valid;
          default:
            return false;
        }
      }

    next() {
        if (this.isCurrentStepValid()) {
          this.activeIndex++;
        }
       
      }
    
      prev() {
        this.activeIndex--;
        if (this.activeIndex === 0) {
          setTimeout(() => {
            this.initGMap(); // Reinitialize the map
            console.log("startLatLng", this.startLatLng);
            console.log("endLatLng", this.endLatLng);
      
            setTimeout(() => { // Delay restoreRoute to ensure map is initialized
              if (this.startLatLng && this.endLatLng) {
                this.restoreRoute();
              }
            }, 1000); // Adjust delay if necessary
          }, 500);
        }
      }
      
      

      restoreRoute() {
        const map = new google.maps.Map(document.getElementById("gmap") as HTMLElement, {
          center: this.startLatLng, // Center map at start location
          zoom: 6,
        });
      
        const service = new google.maps.DirectionsService();
        const display = new google.maps.DirectionsRenderer();
        display.setMap(map);
      
        this.calcRoute(map, service, display, this.startLatLng, this.endLatLng);
      }
      
    
      isFirstStep(): boolean {
        return this.activeIndex === 0;
      }
    
      isLastStep(): boolean {
        return this.activeIndex === this.steps.length - 1;
      }
    
      skip() {
        this.activeIndex++;
      }

      openPreviewModal() {
        // commented as of now if preview needt then start it 
        /*this.previewData = this.AddHistoryForm.value;
        console.log("this.AddHistoryForm.value",this.AddHistoryForm.value);
        this.displayPreview = true;*/

        // when uper code uncommented then this will be remove
        this.previewData = this.AddHistoryForm.value;
        this.confirmSubmission();
      }
    
      confirmSubmission() {
        this.displayPreview = false;
        this.addHistory(this.previewData);
      }
    
      editForm() {
        this.displayPreview = false;
      }
}
