import { Injectable } from '@angular/core';
import { BaseService } from '../../_services/base.service';

@Injectable()
export class UserProfileService {

    constructor(private baseService: BaseService) { }


    getUserProfile(username) {
        return this.baseService.getRequest(`user/profile/${username}`);
    }

    postDetails(data){
        return this.baseService.post(`user/posts/details`, data);
    }

    doLike(data){
        return this.baseService.post(`user/posts/like`, data);
    }

    doComment(data){
        return this.baseService.postFile(`user/posts/comment`, data);
    }

}
