import { Injectable } from '@angular/core';
import { BaseService } from '../../../_services/base.service';

@Injectable()
// tslint:disable-next-line:class-name
export class TravelPlanService {

    constructor(private baseService: BaseService) { }

    getAllGroupTravelHistoryListing(id) {
        return this.baseService.getRequest(`group/travel-history/${id}`).toPromise();
    }

    AddHistory(params) {
        return this.baseService.postFile(`group/travel-history/addUpdate`, params);
    }

    deleteTravelHistory(id){
        return this.baseService.delete(`group/travel-history/delete/${id}`);
    }

    getGroupTravelHistoryEdit(id) {
        return this.baseService.getRequest(`group/travel-history/edit/${id}`).toPromise();
    }

    getGroupTravelHistoryView(uuid) {
        return this.baseService.getRequest(`group/travel-history/view/${uuid}`).toPromise();
    }

    getCountries() {
        return this.baseService.getRequest(`countries`).toPromise();
    }

    getStates(id) {
        return this.baseService.getRequest(`states/`+id).toPromise();
    }

    getCities(id) {
        return this.baseService.getRequest(`cities/`+id).toPromise();
    }

    joinTrip(expensesData,uuid){
        return this.baseService.postFile(`group/travel-history/join/${uuid}`,expensesData);
    }

    saveExpenses(expensesData,uuid){
        return this.baseService.postFile(`group/travel-history/manage-expense/${uuid}`,expensesData);
    }

    getExpensesList(id) {
        return this.baseService.getRequest(`group/travel-history/expenses/${id}`).toPromise();
    }


}
