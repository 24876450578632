import { Injectable } from '@angular/core';
import { BaseService } from '../../../_services/base.service';

@Injectable()
export class SoloRoutesTravelHistoryService {

    constructor(private baseService: BaseService) { }

    addRoute(params) {
        return this.baseService.post(`routes-history/addUpdate`, params);
    }

    getSoloRoute() {
        return this.baseService.getRequest(`routes-history`).toPromise();
    }

    deleteRoute(id) {
        return this.baseService.delete(`routes-history/delete/${id}`);
    }

    getSoloRoutesEdit(id) {
        return this.baseService.getRequest(`routes-history/${id}`).toPromise();
    }

    getCountries() {
        return this.baseService.getRequest(`countries`).toPromise();
    }

    getStates(id) {
        return this.baseService.getRequest(`states/`+id).toPromise();
    }

    getCities(id) {
        return this.baseService.getRequest(`cities/`+id).toPromise();
    }

}
