import { Injectable } from '@angular/core';
import { BaseService } from '../../_services/base.service';

@Injectable()
export class NewsService {

    constructor(private baseService: BaseService) { }

    categories(){
        return this.baseService.getRequest(`categories/7`);
    }

    addUpdate(formData) {
        return this.baseService.postFile(`user/news/addUpdate`,formData);
    }
    
    adminApproval(formData) {
        return this.baseService.postFile(`user/news/updateStatus`,formData);
    }
    
    Edit(id) {
        return this.baseService.getRequest(`user/news/edit/${id}`).toPromise();
    }

    getNewsList() {
        return this.baseService.getRequest(`user/news/list`).toPromise();
    }

    deleteNews(id){
        return this.baseService.delete(`user/news/delete/${id}`);
    }

    list() {
        return this.baseService.getRequest(`news`);
    }

    details(id) {
        return this.baseService.getRequest(`news/`+id);
    }

}
