import {Component, OnInit} from '@angular/core';
import {UserGroupProfileService} from './user_groupprofile.service';
import {Router, ActivatedRoute} from '@angular/router';
import { environment } from 'enviornments/environment';
import { generalHelper } from 'app/helpers/generalHelper';
@Component({
    selector: 'app-usergroupProfile',
    templateUrl: './user_groupprofile.component.html',
    styleUrls: ['./user_groupprofile.component.scss']
})
export class UserGroupProfileComponent implements OnInit {
    env=environment;
    generalHelper=generalHelper;
    user:any={};
    token: any;
    CoverimagePath:any;
    ProfileimagePath:any;
    Currentuser:any
    UserId:any;
    HeaderImage:any
    constructor(private referralService: UserGroupProfileService) { }
        
    ngOnInit() {
        
    }

    getUserProfile(){
        
    }

    
}
