import { Injectable } from '@angular/core';
import { BaseService } from '../../_services/base.service';

@Injectable()
export class ProfileService {

    constructor(private baseService:BaseService) { }

    profile(){
        return this.baseService.get(`user/profile`);
    }

    uploadProfileImage(image) {
        return this.baseService.postFile(`user/update/profile-image`, image);
    }

    uploadProfileImageBase64(image) {
        return this.baseService.post(`user/update/profile-image`, image);
    }
    
    uploadProfileCoverImage(image) {
        return this.baseService.postFile(`user/update/profile-cover-image`, image);
    }
    
    uploadProfileCoverImageBase64(image) {
        return this.baseService.post(`user/update/profile-cover-image`, image);
    }

    getFollowersList() {
        return this.baseService.getRequest(`user/follow/list`).toPromise();
    }
    


}
