import { Injectable } from '@angular/core';
import { BaseService } from '../../../_services/base.service';

@Injectable()
export class EditProfileService {

    constructor(private baseService: BaseService) { }

    signUp(data){
        return this.baseService.post(`auth/register`, data);
    }

    logIn(data){
        return this.baseService.post(`auth/login`, data);
    }

    editProfile(params) {
        return this.baseService.post(`user/update/basic-profile`, params);
    }

}
