import { Component, OnInit } from '@angular/core';
import { soloTravelHistoryService } from '../soloTravelHistory.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {ConfirmationService} from 'primeng/api';
import {} from 'googlemaps';
import {Loader} from "@googlemaps/js-api-loader";
import {environment} from "enviornments/environment"
import Swal from 'sweetalert2';
@Component({
    selector: 'app-addsolotravelhistory',
    templateUrl: './add_solo_travelhistory.component.html',
    styleUrls: ['./add_solo_travelhistory.component.scss']
})

export class AddSoloTravelHistoryComponent implements OnInit {
    user: any = {};
    loading: boolean;
    errors: boolean;
    AddHistoryForm: FormGroup;
    options: any;

    overlays: any[];
    display: boolean = false;
    markerTitle: any;
    constructor(private soloTravelHistoryService: soloTravelHistoryService, private confirmationService: ConfirmationService, private messageService: MessageService, private fb: FormBuilder, private router: Router) { }

    ngOnInit() {
        this.AddHistoryForm = this.fb.group({
            trip_name: ['', [Validators.required]],
            date: ['', [Validators.required]],
            country: ['', [Validators.required]],
            city: ['', [Validators.required]],
            state: ['', [Validators.required]],
            start_latLng: ['', [Validators.required]],
            end_latLng: ['', [Validators.required]],
            total_km: ['', [Validators.required]],
            description: ['', [Validators.required]],
            privacy_status: ['', [Validators.required]],
        });
        this.options = {
            center: {lat: 36.890257, lng: 30.707417},
            zoom: 12

        };
    }
    showDialog() {
        this.display = true;
    }
    ShowHistoryList(){
        this.router.navigate(['solo/travel-history'])
    }

    get trip_name() {
        return this.AddHistoryForm.controls.trip_name
    }
    get date() {
        return this.AddHistoryForm.controls.date
    }
    get country() {
        return this.AddHistoryForm.controls.country
    }
    get city() {
        return this.AddHistoryForm.controls.city
    }
    get state() {
        return this.AddHistoryForm.controls.state
    }
    get start_latLng() {
        return this.AddHistoryForm.controls.start_latLng
    }
    get end_latLng() {
        return this.AddHistoryForm.controls.end_latLng
    }
    get total_km() {
        return this.AddHistoryForm.controls.total_km
    }
    get description() {
        return this.AddHistoryForm.controls.description
    }
    get privacy_status() {
        return this.AddHistoryForm.controls.privacy_status
    }

    async addHistory(formData) {
        const params = {
            'id': 0,
            'trip_name': formData.trip_name,
            'date': formData.date,
            'country': formData.country,
            'state': formData.state,
            'city': formData.city,
            'start_latLng': formData.start_latLng,
            'end_latLng': formData.end_latLng,
            'total_km': formData.total_km,
            'description': formData.description,
            'privacy_status': formData.privacy_status
        }
        Swal.fire({
            icon: 'info',
            title: 'Travel History',
            showCancelButton: true,
            html: 'Do you want to add this travel history ?',
            confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Yes',
              confirmButtonAriaLabel: 'Yes',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i> No',
              cancelButtonAriaLabel: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                this.soloTravelHistoryService.AddHistory(params)
                        .subscribe((res: any) => {
                            this.router.navigate(['/solo/travel-history']);
                            this.messageService.add({severity: 'success', summary: 'Success', detail: res.message ? res.message : ""});
                        }, (err: any) => {
                            this.errors = true;
                            this.messageService.add({severity: 'error', summary: 'Error', detail: err.error ? err.error.error : ""})
                        });
            }
    });

    }

    getConfirmation(header: string, message: string) {
        return new Promise((resolve, reject) => {
            this.confirmationService.confirm({
                message: message,
                header: header,
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    resolve(true);
                },
                reject: () => {
                    resolve(false);
                }
            });
        });
    }

    handleMapClick(event) {

    }

    initGMap() {
        const loader = new Loader({
            apiKey: environment.gMapKey,
            version: "weekly",
            libraries: ["places"]
        });

        loader.load().then(() => {
            let center = {lat: 28.7041, lng: 77.1025}, map: google.maps.Map, infoWindow: google.maps.InfoWindow, autocomplete, marker, addressData = '',defaultBounds = {
                north: center.lat + 0.1,
                south: center.lat - 0.1,
                east: center.lng + 0.1,
                west: center.lng - 0.1,
            };
            const input = document.getElementById("custom-map-searchbar") as HTMLInputElement;
            const options = {
                bounds: defaultBounds,
                componentRestrictions: { country: "us" },
                fields: ["address_components", "geometry", "icon", "name"],
                strictBounds: false,
                types: ["establishment"],
            };

            map = new google.maps.Map(document.getElementById("gmap") as HTMLElement, {
                center: center,
                zoom: 6,
            });

            map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
            autocomplete = new google.maps.places.Autocomplete(input, options);

            infoWindow = new google.maps.InfoWindow();
            const locationButton = document.createElement("button");
            locationButton.textContent = "Pan to Current Location";
            locationButton.classList.add("custom-map-control-button");
            map.controls[google.maps.ControlPosition.TOP_CENTER].push(locationButton);

            marker = new google.maps.Marker({
                map,
                anchorPoint: new google.maps.Point(0, -29),
            });
            autocomplete.addListener("place_changed", () => {
                infoWindow.close();
                marker.setVisible(false);

                const place = autocomplete.getPlace();
                if (!place.geometry || !place.geometry.location) {
                    window.alert("No details available for input: '" + place.name + "'");
                    return;
                }

                if (place.geometry.viewport) {
                    map.fitBounds(place.geometry.viewport);
                } else {
                    map.setCenter(place.geometry.location);
                    map.setZoom(17);
                }

                marker.setPosition(place.geometry.location);
                marker.setVisible(true);
                infoWindow.setContent(place.formatted_address);
                infoWindow.open(map, marker);
            });

            locationButton.addEventListener("click", () => {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        (position: any) => {
                            const pos = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            };
                            marker = new google.maps.Marker({
                                position: pos,
                                map,
                                title: "Location found.",
                            });
                            infoWindow.setPosition(pos);
                            infoWindow.setContent("Location found.");
                            infoWindow.open(map);
                            map.setCenter(pos);
                        },
                        () => {
                            
                        }
                    );
                }
            });
            // Configure the click listener.
            map.addListener("click", (mapsMouseEvent) => {
                let latLng = mapsMouseEvent.latLng.toJSON();
                infoWindow.close();
                this.getAddress(latLng.lat,latLng.lng).then((address: any) => {
                    this.markerTitle = address.formatted_address;
                    addressData = address.formatted_address;
                });
                if (marker) marker.setMap(null);
                marker = new google.maps.Marker({
                    position: mapsMouseEvent.latLng.toJSON(),
                    map,
                    title: this.markerTitle,
                });
                google.maps.event.addListener(marker, 'click', function() {
                    infoWindow = new google.maps.InfoWindow({
                        position: mapsMouseEvent.latLng,
                    });
                    infoWindow.setContent(
                        addressData
                    );
                    infoWindow.open(map);
                });
            });
        });
    }

    getAddress (lat, lng) {
        return new Promise(function (resolve, reject) {
            var request = new XMLHttpRequest();

            var method = 'GET';
            var url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&sensor=true&key='+environment.gMapKey;
            var async = true;

            request.open(method, url, async);
            request.onreadystatechange = function () {
                if (request.readyState == 4) {
                    if (request.status == 200) {
                        var data = JSON.parse(request.responseText);
                        var address = data.results[0];
                        resolve(address);
                    }
                    else {
                        reject(request.status);
                    }
                }
            };
            request.send();
        });
    }

    getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
        var R = 6371; // Radius of the earth in km
        var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
        var dLon = this.deg2rad(lon2-lon1);
        var a =
            Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
            Math.sin(dLon/2) * Math.sin(dLon/2)
        ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        // Distance in km
        return R * c;
    }

    deg2rad(deg) {
        return deg * (Math.PI/180)
    }
}
