import { Component, OnInit } from '@angular/core';
import { PageLeftSidebarService } from './leftsidebar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { imagePathHelper } from 'app/helpers/imagePathHelper';

@Component({
  selector: 'app-Pageleftsidebar',
  templateUrl: './leftsidebar.component.html',
  styleUrls: ['./leftsidebar.component.css']
})
export class PageLeftsidebarComponent implements OnInit {

  AllAds:any=[];
  PageVar:any;
  imageHelper = imagePathHelper;
  PageSelected:boolean;
  constructor(private PageLeftSidebarService: PageLeftSidebarService,private route: ActivatedRoute,private router : Router) { }

  ngOnInit(): void {
    this.getAds();
    
  }


  getAds() {
    this.PageLeftSidebarService.list('Users').subscribe(res => {
        if (res.success){
          this.AllAds = res.data;
        } else{
          this.AllAds = [];
        }

    },error => {
        this.AllAds = [];
    });
}

}
