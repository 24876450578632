import { Component, OnInit } from '@angular/core';
import { NewsService } from '../news.service';
import { generalHelper } from '../../../helpers/generalHelper';

@Component({
    selector: 'app-newshome',
    templateUrl: './newshome.component.html',
    
    styleUrls: ['./newshome.component.scss']
})


export class NewsHomeComponent implements OnInit {
    generalHelper = generalHelper;
    constructor(private NewsService: NewsService) { }

    ngOnInit() {
        
    }
    // addNewsModal() {
    //     this.addNews = true;
    // }
    
    
    
}
