import { Injectable } from '@angular/core';
import { BaseService } from '../../../_services/base.service';

@Injectable()
// tslint:disable-next-line:class-name
export class soloTravelHistoryService {

    constructor(private baseService: BaseService) { }

    AddHistory(params) {
        return this.baseService.postFile(`travel-history/addUpdate`, params);
    }

    getSoloTravelHistory() {
        return this.baseService.getRequest(`travel-history`).toPromise();
    }

    deleteTravelSoloHistory(id){
        return this.baseService.delete(`travel-history/delete/${id}`);
    }

    // getSoloTravelHistoryEdit(data: any){
    //     return this.baseService.getRequest('travel-history', data).toPromise();
    // }

    getSoloTravelHistoryEdit(id) {
        return this.baseService.getRequest(`travel-history/${id}`).toPromise();
    }

    getSoloTravelHistoryView(id) {
        return this.baseService.getRequest(`travel-history/view/${id}`).toPromise();
    }

    getCountries() {
        return this.baseService.getRequest(`countries`).toPromise();
    }

    getStates(id) {
        return this.baseService.getRequest(`states/`+id).toPromise();
    }

    getCities(id) {
        return this.baseService.getRequest(`cities/`+id).toPromise();
    }


}
