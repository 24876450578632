import { Component, OnInit, ElementRef } from '@angular/core';
import {ActivatedRoute,	Router} from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import {BaseService} from "../../_services/base.service";
import { ProfileService } from '../../pages/profile/profile.service';
import { AuthService } from 'app/services/auth.service';
import { Globals } from 'app/globals';
import { CookiesService } from '../services/cookies.services';
import { EncryptionService } from '../services/encryption.services';
@Component({
    selector: 'app-navbar',
    templateUrl: './app-navbar.component.html',
    styleUrls: ['./app-navbar.component.scss']
})
export class AppNavbarComponent implements OnInit {
    private toggleButton: any;
    private sidebarVisible: boolean;
    Currentuser:any={};
    loginUser=false;
    exist = ((window.location.href).split('/')).includes('setup');
    constructor(public location: Location, private authService: AuthService, private cookieService: CookiesService, private encryptionServices: EncryptionService, private globals: Globals, private router: Router, private element: ElementRef, private baseService: BaseService,private profileService: ProfileService,) {
        this.sidebarVisible = false;
    }

    ngOnInit() {
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
       // console.log('this.authService.getLoggedInUser', this.authService.getLoggedInUser());
        if(this.authService.getLoggedInUser()){
            this.loginUser=true;
            this.getUserProfile();
        }
        
    }

    getUserProfile() {
        this.profileService.profile().subscribe(res => {
            this.Currentuser = res;
            if(res.is_profile_setup=='No'){
                if(this.exist==false){
                    window.location.href =  '/solo/profile/setup/'+res.username;
                }
            }
            this.cookieService.set(this.globals.tw_user_cookie, this.encryptionServices.encrypt(JSON.stringify(res)));
        },error => {});
    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];

        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };

    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };
    isHome() {
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
      }
        if( titlee === '/home' ) {
            return true;
        }
        else {
            return false;
        }
    }
    isDocumentation() {
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
      }
        if( titlee === '/documentation' ) {
            return true;
        }
        else {
            return false;
        }
    }
    GoTravelogues(){
        this.router.navigate(['travelogues'])
    }
    GoCommunity(){
        this.router.navigate(['community'])
    }
    GoNewsHome(){
        this.router.navigate(['all-news'])
    }
    GoForum(){
        this.router.navigate(['forum'])
    }
    GoTravBazaar(){
        this.router.navigate(['TravBazaar'])
    }

    GoWebsite(){
        this.router.navigate(['./'])
    }

    goBack() {
        this.location.back();
    }
    
      
    GoEditProfilePage(){
        this.router.navigate(['solo/profile/edit'])
    }

    GoSoloProfile(){
        this.router.navigate(['solo/profile'])
    }

    GoSoloProfileDashboard(){
        this.router.navigate(['solo/dashboard'])
    }

    GoSoloSettings(){
        this.router.navigate(['solo/profile/settings'])
    }
    
    loginPage() {
        this.router.navigate(['login'])
    }
    
    GoCreateGroup() {
        this.router.navigate(['group/creation'])
    }

    logoutUser() {
        this.authService.activeSessions(0,'');
    }
}
