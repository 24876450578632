import { Component, OnInit } from '@angular/core';
import {OtherUserRoutesService} from '../OtherUserRoutes.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router,ActivatedRoute} from '@angular/router';
import {MessageService} from 'primeng/api';
import {ConfirmationService} from 'primeng/api';
import {} from 'googlemaps';
import {Loader} from "@googlemaps/js-api-loader";
import {environment} from "enviornments/environment"
import { generalHelper } from "../../../../helpers/generalHelper";


@Component({
    selector: 'app-OtherUserRoutes',
    templateUrl: './view.component.html',
    styleUrls: ['./view.component.scss']
})

export class ViewOtherUserRoutesComponent implements OnInit {
    
    generalHelper = generalHelper;
    user: any = {};
    loading: boolean;
    errors: boolean;
    AddHistoryForm: FormGroup;
    soloRoutes:any;
    kms: string = '';
    startLatLng: { lng: number; lat: number };
    endLatLng: { lat:number,lng:number };
    routeData: any;
    token: any;
    countries: any = [];
    states: any = [];
    cities: any = [];
    constructor(private OtherUserRoutesService: OtherUserRoutesService, private confirmationService: ConfirmationService, private messageService: MessageService, private fb: FormBuilder, private router: Router, private route: ActivatedRoute) { }

    ngOnInit() {
        this.token = this.route.snapshot.params.id;        
        this.initGMap();
        this.getRoutesPatch();
    }

    ShowHistoryList(){
        this.router.navigate(['solo/travel-history'])
    }


    initGMap() {
        const loader = new Loader({
            apiKey: environment.gMapKey,
            version: "weekly",
            libraries: ["places","drawing","geometry"]
        });

        loader.load().then(() => {
            let center = {lat: 28.7041, lng: 77.1025}, map: google.maps.Map, infoWindow: google.maps.InfoWindow, startAutocomplete, endAutoComplete, marker, service = new google.maps.DirectionsService(), display = new google.maps.DirectionsRenderer(), defaultBounds = {
                north: center.lat + 0.1,
                south: center.lat - 0.1,
                east: center.lng + 0.1,
                west: center.lng - 0.1,
            };
            const startInput = document.getElementById("start_point") as HTMLInputElement;
            const endInput = document.getElementById("end_point") as HTMLInputElement;
            const options = {
                bounds: defaultBounds,
                componentRestrictions: { country: "in" },
                fields: ["address_components", "geometry", "icon", "name"],
                strictBounds: false,
                types: ["establishment"],
            };

            map = new google.maps.Map(document.getElementById("gmap") as HTMLElement, {
                center: center,
                zoom: 6,
            });

            startAutocomplete = new google.maps.places.Autocomplete(startInput, options);
            endAutoComplete = new google.maps.places.Autocomplete(endInput, options);

            infoWindow = new google.maps.InfoWindow();
            const locationButton = document.createElement("button");
            locationButton.textContent = "Pan to Current Location";
            locationButton.classList.add("custom-map-control-button");
            map.controls[google.maps.ControlPosition.TOP_CENTER].push(locationButton);

            startAutocomplete.addListener("place_changed", () => {
                marker = new google.maps.Marker({
                    map,
                    anchorPoint: new google.maps.Point(0, -29),
                });

                const place = startAutocomplete.getPlace();
                if (!place.geometry || !place.geometry.location) {
                    window.alert("No details available for input: '" + place.name + "'");
                    return;
                }

                if (place.geometry.viewport) {
                    map.fitBounds(place.geometry.viewport);
                } else {
                    map.setCenter(place.geometry.location);
                    map.setZoom(12);
                }
                if (!this.startLatLng) {
                    this.startLatLng = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}
                    marker.setPosition(place.geometry.location);
                    marker.setVisible(true);
                }
                else {
                    this.endLatLng = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}
                }
            });

            endAutoComplete.addListener("place_changed", () => {
                marker.setVisible(false);
                const place = endAutoComplete.getPlace();
                if (!place.geometry || !place.geometry.location) {
                    window.alert("No details available for input: '" + place.name + "'");
                    return;
                }

                if (place.geometry.viewport) {
                    map.fitBounds(place.geometry.viewport);
                } else {
                    map.setCenter(place.geometry.location);
                    map.setZoom(12);
                }
                if (!this.startLatLng) {
                    this.startLatLng = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}
                }
                else {
                    this.endLatLng = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}
                    this.calcRoute(map,service,display);
                }
            });

            locationButton.addEventListener("click", () => {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        (position: any) => {
                            const pos = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            };
                            if (!this.startLatLng) {
                                this.startLatLng = pos;
                            }
                            else {
                                this.endLatLng = pos;
                            }
                            marker = new google.maps.Marker({
                                position: pos,
                                map,
                                title: "Location found.",
                            });
                            infoWindow.setPosition(pos);
                            infoWindow.setContent("Location found.");
                            infoWindow.open(map);
                            map.setCenter(pos);
                        },
                        () => {
                            //console.log('Google map error occurred);
                        }
                    );
                }
            });
        });
    }

    getAddress (lat, lng) {
        return new Promise(function (resolve, reject) {
            var request = new XMLHttpRequest();

            var method = 'GET';
            var url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&sensor=true&key='+environment.gMapKey;
            var async = true;

            request.open(method, url, async);
            request.onreadystatechange = function () {
                if (request.readyState == 4) {
                    if (request.status == 200) {
                        var data = JSON.parse(request.responseText);
                        var address = data.results[0];
                        resolve(address);
                    }
                    else {
                        reject(request.status);
                    }
                }
            };
            request.send();
        });
    }

    calcRoute(map, directionsService, directionsDisplay) {
        const self = this;
        var start = new google.maps.LatLng(this.startLatLng.lat, this.startLatLng.lng);
        var end = new google.maps.LatLng(this.endLatLng.lat, this.endLatLng.lng);
        var request = {
            origin: start,
            destination: end,
            travelMode: google.maps.TravelMode.DRIVING
        };
        directionsService.route(request, function(response, status) {
            if (status == google.maps.DirectionsStatus.OK) {
                self.routeData = response.routes[0];
                self.kms = response.routes[0].legs[0].distance.text
                directionsDisplay.setDirections(response);
                directionsDisplay.setMap(map);
            } else {
                alert("Directions Request from " + start.toUrlValue(6) + " to " + end.toUrlValue(6) + " failed: " + status);
            }
        });
    }

    getRoutesPatch() {
        const ID  = this.token;
        this.OtherUserRoutesService.getSoloRoutesEdit(ID).then(async (res) => {
            await this.countriesList();
            await this.statesList(res.data.country);
            await this.citiesList(res.data.state);
            this.soloRoutes = res.data;
            this.soloRoutes.country = (this.countries.find(el => { return el.id == res.data.country })).name;
            this.soloRoutes.state = (this.states.find(el => { return el.id == res.data.state })).name;
            this.soloRoutes.city = (this.cities.find(el => { return el.id == res.data.city })).name;
            this.startLatLng = JSON.parse(res.data.start_latLng);
            this.endLatLng = JSON.parse(res.data.end_latLng);
            if (this.endLatLng){
                let map = new google.maps.Map(document.getElementById("gmap") as HTMLElement, {
                    center: {lat: 28.7041, lng: 77.1025},
                    zoom: 6,
                });
                this.calcRoute(map, new google.maps.DirectionsService(), new google.maps.DirectionsRenderer());
            }
        });
    }

    async countriesList() {
        return this.OtherUserRoutesService.getCountries().then(res => {
            if (res.success) this.countries = res.data;
            else this.countries = [];
        });
    }

    async statesList(countryId) {
        return this.OtherUserRoutesService.getStates(countryId).then(res => {
            if (res.success) this.states = res.data;
            else this.states = [];
        });
    }

    async citiesList(stateId) {
        return this.OtherUserRoutesService.getCities(stateId).then(res => {
            if (res.success) this.cities = res.data;
            else this.cities = [];
        });
    }
}