import { Injectable } from '@angular/core';
import { BaseService } from '../../_services/base.service';

@Injectable()
export class UserGroupProfileService {

    constructor(private baseService: BaseService) { }


    getUserProfile(username) {
        return this.baseService.getRequest(`user/profile/${username}`);
    }

}
