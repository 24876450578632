import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
@Component({
    selector: 'app-footer',
    templateUrl: './app-footer.component.html',
    styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent implements OnInit {
    test = new Date();
    constructor(private router: Router) { }

    ngOnInit() {
    }
    GoTravelogues(){
        this.router.navigate(['travelogues'])
    }
    GoCommunity(){
        this.router.navigate(['community'])
    }
    GoNewsHome(){
        this.router.navigate(['all-news'])
    }
    GoForum(){
        this.router.navigate(['forum'])
    }
    GoTravBazaar(){
        this.router.navigate(['TravBazaar'])
    }
    
      }






