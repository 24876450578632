import {Component, OnInit, Renderer2} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Router, ActivatedRoute} from '@angular/router';
import {GroupsDashboardService} from './group_dashboard.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {HttpClient, HttpEventType} from '@angular/common/http';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from '@ngx-gallery/core';
import { AuthService } from 'app/services/auth.service';
import { NewPostService } from 'app/pages/new_post/new_post.service';
import { environment } from 'enviornments/environment';
@Component({
    selector: 'app-group-dashboard',
    templateUrl: './group_dashboard.component.html',
    styleUrls: ['./group_dashboard.component.scss']
})
export class GroupDashboardComponent implements OnInit {
    env=environment;
    user :any;
    loading: boolean;
    errors: boolean;
    display: boolean;
    display1: boolean;
    AddRouteForm: FormGroup;
    options: any;
    datetime: Date;
    detail = false;
    readmorebtn = true;
    timeline:any;
    mypostsList: any = [];
    userpostsList: any = [];
    attachment: File = null;
    comment: string = '';
    uploadStatus: number;
    items: GalleryItem[];
    showMore = false;
    commetIndex:number;
    commetIndex2:number;
    zoomimage:boolean=false;
    PostInfoImage:any=[];
    Taguser: any;
    contentLoader=false;
    postIndex:any;
    ShowPostindex:any;
    constructor(private dashboardService: GroupsDashboardService,private router: Router,public gallery: Gallery, public authService: AuthService,private postService: NewPostService,private route: ActivatedRoute,) { }

    ngOnInit() {
        this.user = this.authService.getLoggedInUser();
        this.getPosts();
        
    }

    showMoreOption(post,value){
        return post.showMore=value;
    }

    showDialog(type) {
        this.display = true;
        if(type=='profileimage'){
            this.timeline='profile image'
        }
        else{this.timeline='read more'}
    }

    handleAttachment(e) {
        if (e.target.files && e.target.files.length) {
            this.attachment = <File>e.target.files[0];
        }
    }

    deletePost(id) {
        this.contentLoader=true;
        Swal.fire({
            icon: 'warning',
            title: 'Delete Post',
            showCancelButton: true,
            html: 'Do you want to delete this post ?',
            confirmButtonText:
            '<i class="fa fa-thumbs-up"></i> Yes',
          confirmButtonAriaLabel: 'Yes',
          cancelButtonText:
            '<i class="fa fa-thumbs-down"></i> No',
          cancelButtonAriaLabel: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                this.postService.deletePost(id).subscribe((res: any) => {
                    if (res.success){
                        this.contentLoader=false;
                        
                        Swal.fire('Delete Post', res.message, 'success'); 
                        this.getPosts();
                    }else {
                        Swal.fire('Delete Post',res.message, 'error');this.contentLoader=false;
                        this.getPosts();
                    }
                        
                    },error => {
                        Swal.fire('Delete Blog',error.message, 'error');
                        this.contentLoader=false;
                        this.getPosts();
                    });
            }
    });
    }

    editPost(id){
        this.router.navigateByUrl(`solo/post/edit/${id}`);
    }

    getPosts(){
        this.mypostsList=[];
        this.dashboardService.getAllPosts(this.route.snapshot.params.uuid).toPromise().then(res => {
            if(res){
                if(res.myPosts.length){
                    for (let post of res.myPosts) {
                        if(post.attachments.length){
                            post.taggingUser=[];
                            post.imageData = [];
                            post.docData = [];
                            post.videoData = [];

                            for (let attachment of post.attachments){
                                if(attachment.type=='Image'){
                                    let thumb = attachment.attachment.replace('public',this.env.imageURL);
                                    post.imageData.push({image:thumb, thumbImage:thumb});
                                    console.log('imagedata', post.imageData)
                                }
                                if(attachment.type=='Video'){
                                    let thumb = attachment.attachment.replace('public',this.env.imageURL);
                                    post.videoData.push({image:thumb, thumbImage:thumb});
                                    console.log('video', post.videoData)
                                }
                                if(attachment.type=='Document'){
                                    let thumb = attachment.attachment.replace('public',this.env.imageURL);
                                    post.docData.push({image:thumb, thumbImage:thumb});
                                    console.log('imagedata', post.docData)
                                }
                                
                            }
                            
                            if(post.tagged_users){
                                if(post.tagged_users.length > 1){
                                    for (const userId of post.tagged_users.split(',')) {
                                        let selected = this.Taguser.find(el => { return el.user_id == userId});
                                        {
                                            if (selected){
                                                post.taggingUser.push({name:selected.name,username:selected.username});
                                            }
                                        }                                
                                    }
                                }else{
                                    let selected = this.Taguser.find(el => { return el.user_id == post.tagged_users});
                                    {
                                        if (selected){
                                            post.taggingUser.push({name:selected.name,username:selected.username});
                                        }
                                    }
                                }
                            }else{
                                post.taggingUser=[];
                            }
                            
                        }
                        this.mypostsList.push(post);
                    }
                    if(this.zoomimage){ 
                        console.log('his.mypostsList[this.postIndex]',this.mypostsList[this.postIndex])
                        this.PostInfoImage=[];
                        this.PostInfoImage.push(this.mypostsList[this.postIndex]);
                    }

                }
            }
            
        });
    }

    commentShow(number){
        this.commetIndex=number;
    }
    commentShow2(number){
        this.commetIndex2=number;
    }

   async postLike(postIdx,Liketype){
        let post = this.mypostsList[postIdx];
         let likeData = (post.likes.length > 0) ? post.likes.find(el => { return el.user_id == this.user.id }) : post.likes;
        //  let type = (!likeData) ? 'Like' : 'Dislike';
        let type = (!likeData) ? Liketype : Liketype
        //let type = Liketype;
        const params = {post_id: post.id, type: type};
        this.dashboardService.doLike(params).subscribe(res => {
            if (res.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: res.message
                });
                this.mypostsList=[];
                this.userpostsList=[];
                this.getPosts();
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: res.message
                });
                this.mypostsList=[];
                this.userpostsList=[];
                this.getPosts();
            }
        }, error => {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message
            })
        });
    }

    postComment(postIdx, commentId = null){
        if(this.comment==''){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please type some text in comment box'
            })
            return false;
        }else{
            let post = this.mypostsList[postIdx];
            const formData = new FormData();
            formData.append('id',(!commentId) ? 0 : commentId);
            formData.append('post_id',post.id);
            formData.append('comment',this.comment);
            formData.append('attachment',this.attachment);
            this.dashboardService.doComment(formData).subscribe(event => {
                if (event.type === HttpEventType.UploadProgress){
                    this.uploadStatus = Math.round(event.loaded / event.total * 100)
                } else if (event.type === HttpEventType.Response){
                    this.comment = '';
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: event.body.message
                    });
                    this.mypostsList=[];
                    this.userpostsList=[];
                    this.getPosts();
                }
            }, error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error.message
                })
            });
        }
        
    }

    UserpostLike(postIdx,Liketype){
        let post = this.userpostsList[postIdx];
         let likeData = (post.likes.length > 0) ? post.likes.find(el => { return el.user_id == this.user.id }) : post.likes;
        //  let type = (!likeData) ? 'Like' : 'Dislike';
        let type = (!likeData) ? Liketype : Liketype
        //let type = Liketype;
        const params = {post_id: post.id, type: type};
        this.dashboardService.doLike(params).subscribe(res => {
            if (res.success) {
                
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: res.message
                });
                this.mypostsList=[];
                this.userpostsList=[];
                this.getPosts();
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: res.message
                });
                this.mypostsList=[];
                this.userpostsList=[];
                this.getPosts();
            }
        }, error => {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message
            })
        });
    }

    getLikes(post,type){
        if(type=='Like'){
           return (post.likes.filter(el=> {return  el.type=='Like'})).length
        }
        if(type=='Dislike'){
            return (post.likes.filter(el=> {return  el.type=='Dislike'})).length
        }
    }

    UserpostComment(postIdx, commentId = null){
        if(this.comment==''){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please type some text in comment box'
            })
            return false;
        }else{
            let post = this.userpostsList[postIdx];
            const formData = new FormData();
            formData.append('id',(!commentId) ? 0 : commentId);
            formData.append('post_id',post.id);
            formData.append('comment',this.comment);
            formData.append('attachment',this.attachment);
            this.dashboardService.doComment(formData).subscribe(event => {
                if (event.type === HttpEventType.UploadProgress){
                    this.uploadStatus = Math.round(event.loaded / event.total * 100)
                } else if (event.type === HttpEventType.Response){
                    this.comment = '';                
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: event.body.message
                    });
                    this.mypostsList=[];
                    this.userpostsList=[];
                    this.getPosts();
                }
            }, error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error.message
                })
            });
        }
        
    }

    showReadMore() {
        this.display1 = true;
    }

    switchToUserProfile(username){
        this.router.navigateByUrl(`userprofile/${username}`);
    }

    imageZomming(post,index){
        this.postIndex=index;
        this.PostInfoImage=[];
        this.zoomimage=true;
        this.PostInfoImage.push(post);
    }


}
