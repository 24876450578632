import { Component, OnInit } from '@angular/core';
import { BlogService } from '../blog.service';
import { Router } from '@angular/router';
import {MessageService} from 'primeng/api';
import {ConfirmationService} from 'primeng/api';
import { imagePathHelper } from "../../../helpers/imagePathHelper";
import Swal from "sweetalert2";
import { AuthService } from 'app/services/auth.service';
import { environment } from 'enviornments/environment';
@Component({
    // tslint:disable-next-line:component-selector
    selector: 'app-blog_listing',
    templateUrl: './blog_listing.component.html',
    styleUrls: ['./blog_listing.component.scss']
})

export class BlogListingComponent implements OnInit {
    env=environment;
    user : any;
    thumbHelper = imagePathHelper;
    soloHistory: any = [];
    loading: boolean;
    errors: boolean;
    options: any;
    city:any;
    state:any;
    country:any;
    totalkm:any;
    description:any;
    tripname:any;
    overlays: any[];
    display: boolean = false;
    blogs: any = [];
    contentLoader: boolean = false;
    constructor(private blogService: BlogService, private router: Router,private authService: AuthService, private confirmationService: ConfirmationService, private messageService: MessageService) { }

    ngOnInit() {
        this.user = this.authService.getLoggedInUser();
        this.getBlogs();
    }

    blogDetails(id){
        this.router.navigateByUrl(`solo/blog/detail/${id}`);
    }

    addBlog(){
        this.router.navigate(['solo/blog/add'])
    }

    blogEdit(id){
        this.router.navigateByUrl(`solo/blog/edit/${id}`);
    }
    

    showDialog(i) {
        this.display = true;
        this.city=this.soloHistory[i].city;
        this.country=this.soloHistory[i].country;
        this.totalkm=this.soloHistory[i].total_km;
        this.state=this.soloHistory[i].state;
        this.description=this.soloHistory[i].description;
        this.tripname=this.soloHistory[i].trip_name;
    }

    getConfirmation(header: string, message: string) {
        return new Promise((resolve, reject) => {
            this.confirmationService.confirm({
                message: message,
                header: header,
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    resolve(true);
                },
                reject: () => {
                    resolve(false);
                }
            });
        });
    }

    getBlogs() {
        this.contentLoader=true;
        this.blogService.list().subscribe(res => {
            if (res.success) {
                this.contentLoader=false;
                this.blogs = res.data;
            }
        },error => {
            console.log('error9999999999')
            this.blogs = [];
            this.contentLoader=true;
        });
    }

    deleteBlog(id) {
        this.contentLoader=true;
        this.blogService.delete(id).subscribe(res => {
            
            if (res.success){
                this.contentLoader=false;
                this.getBlogs();
                Swal.fire('Delete Blog', res.message, 'success'); 
            }else {Swal.fire('Delete Blog',res.message, 'error');this.contentLoader=false;}
        },error => {
            Swal.fire('Delete Blog',error.message, 'error');
            this.contentLoader=false;
        });
    }

    // getLikes(post,type){
    //     if(type=='Like'){
    //        return (post.likes.filter(el=> {return  el.type=='Like'})).length
    //     }
    //     if(type=='Dislike'){
    //         return (post.likes.filter(el=> {return  el.type=='Dislike'})).length
    //     }
    // }

    switchToUserProfile(username){
        this.router.navigateByUrl(`userprofile/${username}`);
    }
}
