import { Component, OnInit } from '@angular/core';
import { SoloRoutesTravelHistoryService } from '../solo_routes_travelhistory.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router,ActivatedRoute} from '@angular/router';
import {MessageService} from 'primeng/api';
import {ConfirmationService} from 'primeng/api';
import {} from 'googlemaps';
import {Loader} from "@googlemaps/js-api-loader";
import {environment} from "enviornments/environment"
import { generalHelper } from "../../../../helpers/generalHelper";
import Swal from "sweetalert2";
import {ValidationsService} from "../../../../services/validations.service";

@Component({
    selector: 'app-createroutes',
    templateUrl: './create.component.html',
    styleUrls: ['./create.component.scss']
})

export class CreateRoutesComponent implements OnInit {
    generalHelper = generalHelper;
    user: any = {};
    loading: boolean;
    errors: boolean;
    AddRouteForm: FormGroup;
    kms: string = '';
    startLatLng: { lng: number; lat: number };
    endLatLng: { lat:number,lng:number };
    routeData: any;
    soloRoutes: any = [];
    countries: any = [];
    states: any = [];
    cities: any = [];
    token: any;
    contentLoader:boolean=false;
    historyId: any;
    selectedCountry: any;
    selectedState: any;
    selectedCity: any;
    MainEndPointName:any;
    MainStartPointName:any;
    date1: Date;
    constructor(private SoloRoutesTravelHistoryService: SoloRoutesTravelHistoryService ,private customValidate: ValidationsService, private confirmationService: ConfirmationService, private messageService: MessageService, private fb: FormBuilder, private router: Router,private route: ActivatedRoute) { }

    ngOnInit() {
        this.token = this.route.snapshot.params.id;
        this.AddRouteForm = this.fb.group({
            date_time: ['', [Validators.required]],
            start_point: ['', [Validators.required]],
            end_point: ['', [Validators.required]],
            description: ['', [Validators.required]],
            privacy_status: ['Public', [Validators.required]],
        });
        this.initGMap();
        this.countriesList();
        if(this.token){this.getRoutesPatch();}
        this.CalendarDate();
    }

    CalendarDate(){
        
        var now = new Date();
        var isoDate = new Date(now).toISOString();
        let newDate = (isoDate.split('T')[0])
        let finaldate =this.customValidate.AddMinusInDate(newDate , 0 , 'minus');
        this.date1 = new Date(finaldate);
        
    }

    ShowHistoryList(){
        this.router.navigate(['solo/travel-history'])
    }

    get date_time() {
        return this.AddRouteForm.controls.date_time
    }
    get start_point() {
        return this.AddRouteForm.controls.start_point
    }
    get end_point() {
        return this.AddRouteForm.controls.end_point
    }
    get description() {
        return this.AddRouteForm.controls.description
    }
    get privacy_status() {
        return this.AddRouteForm.controls.privacy_status
    }

    formRouteReset(){
        this.AddRouteForm.reset();
        this.selectedCountry='';
        this.selectedState='';
        this.selectedCity='';
        this.kms = '';
        this.AddRouteForm.get('start_point').setValue('');
        this.AddRouteForm.get('end_point').setValue('');
        this.initGMap();
    }

    async addRoute(formData) {
        if (!this.selectedCountry){
            Swal.fire('Validation Error','Please select country from list','error');
            return false;
        }
        if (!this.selectedState){
            Swal.fire('Validation Error','Please select state from list','error');
            return false;
        }
        if (!this.selectedCity){
            Swal.fire('Validation Error','Please select city from list','error');
            return false;
        }
        if(this.token){
            this.historyId=this.token;
        }else{
            this.historyId=0;
        }
        formData.start_point = this.MainStartPointName;
        formData.end_point = this.MainEndPointName;
        const params = {
            'id': this.historyId,
            'date_time': formData.date_time.toLocaleString(),
            'country': this.selectedCountry.id,
            'state': this.selectedState.id,
            'city': this.selectedCity.id,
            'start_latLng': JSON.stringify(this.startLatLng),
            'start_point': formData.start_point,
            'end_latLng': JSON.stringify(this.endLatLng),
            'end_point': formData.end_point,
            'total_km': this.kms,
            'description': formData.description,
            'route_data': JSON.stringify(this.routeData),
            'privacy_status': formData.privacy_status
        }

        Swal.fire({
            icon: 'info',
            title: 'Add Route',
            showCancelButton: true,
            html: 'Do you want to add this route ?',
            confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Yes',
              confirmButtonAriaLabel: 'Yes',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i> No',
              cancelButtonAriaLabel: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                this.contentLoader=true;
            this.SoloRoutesTravelHistoryService.addRoute(params)
                .subscribe((res: any) => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: res.message
                    });
                    this.router.navigate(['solo/routes']);
                }, (err: any) => {
                    this.errors = true;
                    this.contentLoader=false;
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: err.message
                    })
                });
            }
    });

    }

    getRoutesPatch() {
        const ID  = this.token;
        this.SoloRoutesTravelHistoryService.getSoloRoutesEdit(ID).then(async (res) => {
            if (res.success) {
                this.soloRoutes = res.data;
                this.MainStartPointName = this.soloRoutes.start_point;
                this.MainEndPointName = this.soloRoutes.end_point;
                await this.statesList(this.soloRoutes.country);
                await this.citiesList(this.soloRoutes.state);
                this.AddRouteForm.patchValue({
                    date_time: new Date(this.soloRoutes.date_time),
                    country: this.soloRoutes.country,
                    state: this.soloRoutes.state,
                    city: this.soloRoutes.city,
                    total_km: this.soloRoutes.total_km,
                    start_point: this.soloRoutes.start_point,
                    end_point: this.soloRoutes.end_point,
                    description: this.soloRoutes.description,
                    privacy_status: this.soloRoutes.privacy_status,
                });
                this.startLatLng = JSON.parse(this.soloRoutes.start_latLng);
                this.endLatLng = JSON.parse(this.soloRoutes.end_latLng);
                this.selectedCountry = this.countries.find(el => {
                    return el.id == this.soloRoutes.country
                });
                this.selectedState = this.states.find(el => {
                    return el.id == this.soloRoutes.state
                });
                this.selectedCity = this.cities.find(el => {
                    return el.id == this.soloRoutes.city
                });
                if (this.endLatLng) {
                    let map = new google.maps.Map(document.getElementById("gmap") as HTMLElement, {
                        center: {lat: 28.7041, lng: 77.1025},
                        zoom: 6,
                    });
                    this.calcRoute(map, new google.maps.DirectionsService(), new google.maps.DirectionsRenderer(),this.startLatLng,this.endLatLng);
                }
            }
        });
    }

    getConfirmation(header: string, message: string) {
        return new Promise((resolve, reject) => {
            this.confirmationService.confirm({
                message: message,
                header: header,
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    resolve(true);
                },
                reject: () => {
                    resolve(false);
                }
            });
        });
    }

    initGMap() {
            const loader = new Loader({
                apiKey: environment.gMapKey,
                version: "weekly",
                libraries: ["places","drawing","geometry"]
            });
    
            loader.load().then(() => {
                let center = {lat: 28.7041, lng: 77.1025}, map: google.maps.Map, infoWindow: google.maps.InfoWindow, startAutocomplete, endAutoComplete, marker, service = new google.maps.DirectionsService(), display = new google.maps.DirectionsRenderer(), defaultBounds = {
                    north: center.lat + 0.1,
                    south: center.lat - 0.1,
                    east: center.lng + 0.1,
                    west: center.lng - 0.1,
                };
                const startInput = document.getElementById("start_point") as HTMLInputElement;
                const endInput = document.getElementById("end_point") as HTMLInputElement;
                
                const options = {
                    bounds: defaultBounds,
                    componentRestrictions: { country: "in" },
                    fields: ["address_components", "geometry", "icon", "name"],
                    strictBounds: false,
                    types: ["establishment"],
                };
    
                map = new google.maps.Map(document.getElementById("gmap") as HTMLElement, {
                    center: center,
                    zoom: 6,
                });
    
                startAutocomplete = new google.maps.places.Autocomplete(startInput, options);
                endAutoComplete = new google.maps.places.Autocomplete(endInput, options);
    
                infoWindow = new google.maps.InfoWindow();
                const locationButton = document.createElement("button");
                locationButton.innerHTML = "<i class='fa fa-location-arrow'></i> Locate me";
                locationButton.classList.add("custom-map-control-button");
                map.controls[google.maps.ControlPosition.TOP_CENTER].push(locationButton);
    
                startAutocomplete.addListener("place_changed", () => {
                    marker = new google.maps.Marker({
                        map,
                        anchorPoint: new google.maps.Point(0, -29),
                    });
    
                    const place = startAutocomplete.getPlace();
                    
                    if (!place.geometry || !place.geometry.location) {
                        Swal.fire('Not available',"No details available for input: '" + place.name + "'",'error');
                        return false;
                    }
                    if (place.geometry || place.geometry.location) {
                        this.MainStartPointName= place.name;
                    }
    
                    if (place.geometry.viewport) {
                        map.fitBounds(place.geometry.viewport);
                    } else {
                        map.setCenter(place.geometry.location);
                        map.setZoom(12);
                    }
                    /*if (!this.startLatLng) {
                        this.startLatLng = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}
                        marker.setPosition(place.geometry.location);
                        marker.setVisible(true);
                    }
                    else {
                        this.endLatLng = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}
                    }*/
                    this.startLatLng = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}
                    marker.setPosition(place.geometry.location);
                    marker.setVisible(true);
                    if (this.endLatLng) this.calcRoute(map,service,display,this.startLatLng,this.endLatLng);
                });
    
                endAutoComplete.addListener("place_changed", () => {
                    marker.setVisible(false);
                    const place = endAutoComplete.getPlace();
                    
                    if (!place.geometry || !place.geometry.location) {
                        Swal.fire('Not available',"No details available for input: '" + place.name + "'",'error');
                        return false;
                    }
                    if (place.geometry || place.geometry.location) {
                        this.MainEndPointName= place.name;
                    }
    
                    if (place.geometry.viewport) {
                        map.fitBounds(place.geometry.viewport);
                    } else {
                        map.setCenter(place.geometry.location);
                        map.setZoom(12);
                    }
                    if (!this.startLatLng) {
                        this.startLatLng = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}
                    }
                    else {
                        this.endLatLng = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}
                        this.calcRoute(map,service,display,this.startLatLng,this.endLatLng);
                    }
                });
    
                locationButton.addEventListener("click", () => {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(
                            (position: any) => {
                                const pos = {
                                    lat: position.coords.latitude,
                                    lng: position.coords.longitude,
                                };
                                if (!this.startLatLng) {
                                    this.startLatLng = pos;
                                }
                                else {
                                    this.endLatLng = pos;
                                }
                                marker = new google.maps.Marker({
                                    position: pos,
                                    map,
                                    title: "Location found.",
                                });
                                infoWindow.setPosition(pos);
                                infoWindow.setContent("Location found.");
                                infoWindow.open(map);
                                map.setCenter(pos);
                            },
                            () => {
                                //console.log('Google map error occurred);
                            }
                        );
                    }
                });
            });
        }
    
        getAddress (lat, lng) {
            return new Promise(function (resolve, reject) {
                var request = new XMLHttpRequest();
    
                var method = 'GET';
                var url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&sensor=true&key='+environment.gMapKey;
                var async = true;
    
                request.open(method, url, async);
                request.onreadystatechange = function () {
                    if (request.readyState == 4) {
                        if (request.status == 200) {
                            var data = JSON.parse(request.responseText);
                            var address = data.results[0];
                            resolve(address);
                        }
                        else {
                            reject(request.status);
                        }
                    }
                };
                request.send();
            });
        }
    
        calcRoute(map, directionsService, directionsDisplay,startLatLng,endLatLng) {
            const self = this;
            var start = new google.maps.LatLng(startLatLng.lat, startLatLng.lng);
            var end = new google.maps.LatLng(endLatLng.lat, endLatLng.lng);
            var request = {
                origin: start,
                destination: end,
                travelMode: google.maps.TravelMode.DRIVING
            };
            directionsService.route(request, function(response, status) {
                if (status == google.maps.DirectionsStatus.OK) {
                    self.routeData = response.routes[0];
                    self.kms = response.routes[0].legs[0].distance.text
                    directionsDisplay.setDirections(response);
                    directionsDisplay.setMap(map);
                } else {
                    Swal.fire('Directions Request',"Directions Request from " + start.toUrlValue(6) + " to " + end.toUrlValue(6) + " failed: " + status,'error');
                    return false;
                }
            });
        }

    onFileInput($event: Event) {
        console.log('event',$event);
    }

    countriesList() {
        this.SoloRoutesTravelHistoryService.getCountries().then(res => {
            if (res.success) this.countries = res.data;
            else this.countries = [];
        });
    }

    async statesList(id = null) {
        let countryId = id ? id : this.selectedCountry.id;
        return this.SoloRoutesTravelHistoryService.getStates(countryId).then(res => {
            if (res.success) this.states = res.data;
            else this.states = [];
        });
    }

    async citiesList(id = null) {
        let stateId = id ? id : this.selectedState.id;
        return this.SoloRoutesTravelHistoryService.getCities(stateId).then(res => {
            if (res.success) this.cities = res.data;
            else this.cities = [];
        });
    }
}
