import {Component, OnInit} from '@angular/core';
import {TravelPlanService} from '../groupTravelHistory.service';
import {FormBuilder, FormGroup, Validators, FormArray} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {ConfirmationService} from 'primeng/api';
import {Loader} from '@googlemaps/js-api-loader';
import Swal from 'sweetalert2';
import {generalHelper} from '../../../../helpers/generalHelper';
import {HttpEventType} from '@angular/common/http';
import {ValidationsService} from '../../../../services/validations.service';
import { KeyValue } from '@angular/common';
import { AuthService } from 'app/services/auth.service';
import { environment } from 'enviornments/environment';

@Component({
    selector: 'app-group-travelPlan',
    templateUrl: './create.component.html',
    styleUrls: ['./create.component.scss']
})

export class CreateTravelPlanComponent implements OnInit {
    env=environment
    steps: any[];
    activeIndex: number = 0;
    generalHelper = generalHelper;
    user: any = {};
    loading: boolean;
    errors: boolean;
    AddHistoryForm: FormGroup;
    kms = '';
    startLatLng: { lng: number, lat: number };
    endLatLng: { lat: number, lng: number };
    meetingLatLng: { lat: number, lng: number };
    routeData: any;
    images: any = [];
    uploadStatus: number;
    token: any;
    historyId: any;
    mapHeight = '580px';
    slides: any = [];
    countries: any = [];
    states: any = [];
    cities: any = [];
    selectedCountry: any;
    selectedState: any;
    selectedCity: any;
    contentLoader = false;
    totalDays = 0;
    itinerariesArr = [];
   // stayPlansArr = [];
    checkListArr = [];
    dayStartLocation: google.maps.places.PlaceResult;
    showItineraryMap = false;
    errorsMessage: any = '';
    routeStartdate: any;
    showItineraryMap2 = false;
    StartLocation: any = '';
    EndLocation: any = '';
    MainEndPointName: any;
    MainStartPointName: any;
    MainMeetingPointName: any;
    date1: Date;
    date2: Date;
    uploadeFileEnabled = false;
    Viewimages: any = [];
    minimumDate: any = new Date(Date.now());
    groupID :string = ((this.router.url.split("/"))[3]);
    groupSerialID:any;
    qrImages:any=[];
    paymentSetupOption='';
    selectedPaymentOption: string = 'cash';
    payment: any = {
        cash: '',
        upi: '',
        qrCode: '',
        bank: {
        holder: '',
        account: '',
        ifsc: ''
        }
    };

    hotels:any=[
        {
            name:'Rupsagar',
            loation:'phase 35 delhi'
        },
        {
            name:'Shiva sagar',
            loation:'phase 35 Haridwar'
        },
        {
            name:'Brajwasi',
            loation:'phase 35 Mathura'
        },
        {
            name:'Bansori wala',
            loation:'phase 35 Vrindavan'
        },
    ]
    
    expenses: any[] = [];
    totalAmount:any;    
    totalCost: number = 0;
    displayPreview: boolean = false;
    previewData: any;
    constructor(private soloTravelHistoryService: TravelPlanService, private confirmationService: ConfirmationService, private messageService: MessageService, private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private customValidate: ValidationsService, private authService: AuthService) { 
        this.steps = [
            { label: 'Travel Plan' },
            { label: 'Checklist & Itenaries' },
            { label: 'Payment & Budgeting' }
          ];
    }

    ngOnInit() {
        this.token = this.route.snapshot.params.editId;
        this.groupSerialID = this.route.snapshot.params.groupId;

        this.AddHistoryForm = this.fb.group({
            trip_name: ['', Validators.required],
            privacy_status: ['', Validators.required],
            startDate: ['', Validators.required],
            endDate: ['', Validators.required],
            start_point: ['', Validators.required],
            end_point: ['', Validators.required],
            meeting_point: ['', Validators.required],
            meeting_time: ['', Validators.required],
            isVehicleRegistrationNumberNeeded: [false],
            vehicle_registration_number: [''],
            accomodation_capacity: ['', Validators.required],
            registration_expiration: ['', Validators.required],
            description: ['', Validators.required],
            term_condition: ['', Validators.required],
            clothes: [''],
            gadgets: [''],
            tools: [''],
            documents: [''],
            budgeting: this.fb.array([]),
            child_inclusion: [false],
            child_age: [{ value: '', disabled: true }],
            cost_per_head_child: [{ value: '', disabled: true }],
            cost_per_head_adult: [''],
            paymentSetupOptionVal: [''],
            itineraries: this.fb.array([]),
          });


          this.AddHistoryForm.get('child_inclusion').valueChanges.subscribe(value => {
            if (value) {
              this.AddHistoryForm.get('child_age').enable();
              this.AddHistoryForm.get('cost_per_head_child').enable();
            } else {
              this.AddHistoryForm.get('child_age').disable();
              this.AddHistoryForm.get('cost_per_head_child').disable();
            }
          });
        this.initGMap();
        this.countriesList();
        this.addBudgeting();
        if (this.token) {this.getTravelHistoryPatch(); }

        this.CalendarDate();
        this.user = this.authService.getLoggedInUser();

        this.AddHistoryForm.get('budgeting')!.valueChanges.subscribe(values => {
            this.totalAmount = values.reduce((acc: number, curr: any) => acc + (parseFloat(curr.amount) || 0), 0);
          });
    }

    CalendarDate() {
        const now = new Date();
        const isoDate = new Date(now).toISOString();
        const newDate = (isoDate.split('T')[0])
        const finaldate = this.customValidate.AddMinusInDate(newDate , 0 , 'minus');
        this.date1 = new Date(finaldate);
        this.date2 = new Date(finaldate);
    }

    addItineraries(itineraries, data): void {
        itineraries.push(this.fb.group({
            start : [data ? data.start.location : ''],
            end : [data ? data.end.location : ''],
            totalKms : [data ? data.totalKMS : 0],
            stayPlace: [data ? data.stayPlace : ''],
            travelMode: [data ? data.travelMode : '']
        }));
    }
 
    addBudgeting(): void {
        const formArray = this.AddHistoryForm.get('budgeting') as FormArray;
        formArray.push(this.fb.group({
            item : [''],
            amount : []
        }));
    }

    removeBudgeting(index): void {
        const formArray = this.AddHistoryForm.get('budgeting') as FormArray;
        formArray.removeAt(index);
    }

    patchItineraries(itineraries, itinerary, index) {
        const formArray = this.AddHistoryForm.get('itineraries') as FormArray;
        itineraries[index + 1].start.placeData = itinerary.end.placeData;
        itineraries[index + 1].start.latLng = itinerary.end.latLng;
        itineraries[index + 1].start.location = itinerary.end.location;
        formArray.at(index + 1).get('start').setValue(itinerary.end.location);
    }

    initItineraries(): FormGroup {
        return this.fb.group({
            start : [''],
            end: [''],
            totalKms : [0],
            stayPlace : [''],
            travelMode : [''],
        });
    }

    initStayPlan(): FormGroup {
        return this.fb.group({
            propertyName : [''],
            location: [''],
            relatedPick : ['']
        });
    }

    initCheckList(): FormGroup {
        return this.fb.group({
            clothes: [''],
            gadgets : [''],
            tools: [''],
            documents: [''],
        });
    }

    ShowHistoryList() {
        this.router.navigate(['group/travelplan'])
    }

    get trip_name() {
        return this.AddHistoryForm.controls.trip_name;
    }
    get startDate() {
        return this.AddHistoryForm.controls.startDate;
    }
    get endDate() {
        return this.AddHistoryForm.controls.endDate;
    }
    get start_point() {
        return this.AddHistoryForm.controls.start_point;
    }
    get end_point() {
        return this.AddHistoryForm.controls.end_point;
    }
    get meeting_point() {
        return this.AddHistoryForm.controls.meeting_point;
    }
    get meeting_time() {
        return this.AddHistoryForm.controls.meeting_time;
    }
    get description() {
        return this.AddHistoryForm.controls.description;
    }
    
    
    get privacy_status() {
        return this.AddHistoryForm.controls.privacy_status;
    }
    get plan_status() {
        return this.AddHistoryForm.controls.plan_status;
    }

    get clothes() {
        return this.AddHistoryForm.controls.clothes;
    }
    get gadgets() {
        return this.AddHistoryForm.controls.gadgets;
    }
    get tools() {
        return this.AddHistoryForm.controls.tools;
    }
    get documents() {
        return this.AddHistoryForm.controls.documents;
    }
    get paymentSetupOptionVal() {
        return this.AddHistoryForm.controls.paymentSetupOptionVal;
    }
    get itineraries() {
        return this.AddHistoryForm.get('itineraries')['controls'];
    }
    get budgeting() {
        return this.AddHistoryForm.get('budgeting')['controls'];
    }

    openPreviewModal() {
        this.previewData = this.AddHistoryForm.value;
        this.displayPreview = true;
      }
    
      confirmSubmission() {
        this.displayPreview = false;
        this.submitForm(this.previewData);
      }
    
      editForm() {
        this.displayPreview = false;
      }

      submitForm(data: any) {
        this.errorsMessage = '';
    
        if (this.token) {
          this.historyId = this.token;
        } else {
          this.historyId = 0;
        }
    
        const customValidate = {
          restrictComa: (input: string) => !/[,.!@#$%^&*()_+=-]/.test(input),
          formatDate: (date: string) => new Date(date).toISOString()
        };
    
        if (data.clothes.length > 0) {
          for (let i = 0; i < data.clothes.length; i++) {
            if (!customValidate.restrictComa(data.clothes[i])) {
              this.errorsMessage = 'Please do not add , (coma) and any special character in clothes.';
              return false;
            }
          }
        }
    
        if (data.gadgets.length > 0) {
          for (let i = 0; i < data.gadgets.length; i++) {
            if (!customValidate.restrictComa(data.gadgets[i])) {
              this.errorsMessage = 'Please do not add , (coma) and any special character in gadgets.';
              return false;
            }
          }
        }
    
        if (data.tools.length > 0) {
          for (let i = 0; i < data.tools.length; i++) {
            if (!customValidate.restrictComa(data.tools[i])) {
              this.errorsMessage = 'Please do not add , (coma) and any special character in tools.';
              return false;
            }
          }
        }
    
        if (data.documents.length > 0) {
          for (let i = 0; i < data.documents.length; i++) {
            if (!customValidate.restrictComa(data.documents[i])) {
              this.errorsMessage = 'Please do not add , (coma) and any special character in documents.';
              return false;
            }
          }
        }
    
        const itinerariesArr = [];
        if (this.itinerariesArr.length) {
          for (const itinerary of this.itinerariesArr) {
            delete itinerary.routeData;
            itinerariesArr.push(itinerary);
          }
        }
    
        data.checkList = {
          clothes: data.clothes.toString(),
          gadgets: data.gadgets.toString(),
          tools: data.tools.toString(),
          documents: data.documents.toString()
        };

        let payment_information = {
            payment_setup_option_val: this.paymentSetupOption,
            child_inclusion:data.child_inclusion,
            child_age:data.child_age,
            cost_per_head_child:data.cost_per_head_child,
            cost_per_head_adult:data.cost_per_head_adult,
            payment:this.payment
        }
    
        data.start_point = this.MainStartPointName;
        data.end_point = this.MainEndPointName;
        data.meeting_point = this.MainMeetingPointName;
    
        let params = new FormData();
        console.log('datata', data);
    
        params.append('id', this.historyId.toString());
        params.append('group_id', this.groupSerialID.toString());
        params.append('trip_name', data.trip_name);
        params.append('start_date', customValidate.formatDate(data.startDate));
        params.append('end_date', customValidate.formatDate(data.endDate));
        params.append('about', data.term_condition);
        params.append('vehicle_number', data.vehicle_registration_number);
        params.append('vehicle_capacity', data.accomodation_capacity);
        params.append('registration_expiration', data.registration_expiration);
        // params.append('plan_status', data.plan_status);
        params.append('start_point', data.start_point);
        params.append('start_latLng', JSON.stringify(this.startLatLng));
        params.append('end_point', data.end_point);
        params.append('end_latLng', JSON.stringify(this.endLatLng));
        params.append('meeting_point', data.meeting_point);
        params.append('meeting_latLng', JSON.stringify(this.meetingLatLng));
        params.append('meeting_time', data.meeting_time);
        params.append('total_km', this.kms.toString());
        params.append('description', data.description);
        params.append('privacy_status', data.privacy_status);
        params.append('payment_information', JSON.stringify(payment_information));
        params.append('route_data', JSON.stringify(this.routeData));
        params.append('checklist', JSON.stringify(data.checkList));
        params.append('itinerary', JSON.stringify(itinerariesArr));
        params.append('budgeting', JSON.stringify(data.budgeting));
    
        for (let i = 0; i < this.images.length; i++) {
          params.append('attachments[]', this.images[i], this.images[i].name);
        }
    
        this.contentLoader = true;
        console.log('params11111111111111111',params);
        this.soloTravelHistoryService.AddHistory(params).subscribe(event => {
          if (event.type === HttpEventType.UploadProgress) {
            this.uploadStatus = Math.round(event.loaded / event.total * 100);
          } else if (event.type === HttpEventType.Response) {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: event.body.message
            });
            this.router.navigate(['group/travelplan/' + this.groupSerialID]);
          }
        }, error => {
          this.contentLoader = false;
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.message
          });
        });
    }
    paymentInfo:any;

    getTravelHistoryPatch() {
        this.contentLoader = true;
        const ID  = this.token;
         this.soloTravelHistoryService.getGroupTravelHistoryEdit(ID).then(async (res) => {
         const soloHistory = res.data;
         console.log('res.data',res.data);
        
         const itineraries = soloHistory.itinerary ? JSON.parse(soloHistory.itinerary) : null;
      //   const stayPlans = soloHistory.stayPlanarray ? JSON.parse(soloHistory.stayPlanarray) : null;
         if (res.success) {
            this.MainStartPointName = soloHistory.start_point;
            this.MainEndPointName = soloHistory.end_point;
             await this.statesList(soloHistory.country);
             await this.citiesList(soloHistory.state);
            // Assuming 'budgeting' is an array received from the API response
                const budgetingArray = JSON.parse('[{"item":"Petrol","amount":20000},{"item":"Food","amount":10000},{"item":"Exrra","amount":6000}]');

                // Clear the existing form array
                const budgetingFormArray = this.AddHistoryForm.get('budgeting') as FormArray;
                budgetingFormArray.clear();

                // Update the existing 'budgeting' array in your component
                budgetingArray.forEach(budget => {
                budgetingFormArray.push(this.fb.group({
                    item: [budget.item, Validators.required],
                    amount: [budget.amount, Validators.required],
                }));
                });


             this.AddHistoryForm.patchValue({
                 trip_name: soloHistory.trip_name,
                 startDate: new Date(soloHistory.start_date),
                 endDate: new Date(soloHistory.end_date),
                 country: soloHistory.country,
                 state: soloHistory.state,
                 city: soloHistory.city,
                 start_point: soloHistory.start_point,
                 end_point: soloHistory.end_point,
                 total_km: soloHistory.total_km,
                 description: soloHistory.description,
                 privacy_status: soloHistory.privacy_status,
                 plan_status: soloHistory.plan_status,
                 meeting_point: soloHistory.meeting_point,  // Add this line
                 meeting_time: soloHistory.meeting_time,    // Add this line
             });
             this.routeStartdate = soloHistory.start_date;
             //this.checkDates(itineraries,stayPlans);
            if (soloHistory.checklist) {
                const checklist = JSON.parse(soloHistory.checklist);
                this.AddHistoryForm.controls['clothes'].setValue(checklist.clothes.split(','), {onlySelf: true});
                this.AddHistoryForm.controls['gadgets'].setValue(checklist.gadgets.split(','), {onlySelf: true});
                this.AddHistoryForm.controls['tools'].setValue(checklist.tools.split(','), {onlySelf: true});
                this.AddHistoryForm.controls['documents'].setValue(checklist.documents.split(','), {onlySelf: true});
            }
           
            if (res.data.files) {
                const self = this;
               // this.slides = JSON.parse(res.data.files);
               const slides = JSON.parse(res.data.files);
                self.Viewimages = [];
                for (const file of slides) {
                    self.Viewimages.push({previewImageSrc:file.attachmentName.replace('public',this.env.imageURL), thumbnailImageSrc:file.attachmentName.replace('public',this.env.imageURL), alt: file.attachmentName, title: file.attachmentName});
                }
            }
             this.startLatLng = JSON.parse(soloHistory.start_latLng);
             this.endLatLng = JSON.parse(soloHistory.end_latLng);
             this.selectedCountry = this.countries.find(el => {
                 return el.id == soloHistory.country
             });
             this.selectedState = this.states.find(el => {
                 return el.id == soloHistory.state
             });
             this.selectedCity = this.cities.find(el => {
                 return el.id == soloHistory.city
             });
             if (this.endLatLng) {
                 const map = new google.maps.Map(document.getElementById('gmap') as HTMLElement, {
                     center: {lat: 28.7041, lng: 77.1025},
                     zoom: 6,
                 });
                 this.calcRoute(map, new google.maps.DirectionsService(), new google.maps.DirectionsRenderer(), this.startLatLng, this.endLatLng);
             }
             this.contentLoader = false;
         }
     });

    }

    getConfirmation(header: string, message: string) {
        return new Promise((resolve, reject) => {
            this.confirmationService.confirm({
                message: message,
                header: header,
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    resolve(true);
                },
                reject: () => {
                    resolve(false);
                }
            });
        });
    }

    initGMap() {
        const loader = new Loader({
            apiKey: environment.gMapKey,
            version: 'weekly',
            libraries: ['places', 'drawing', 'geometry']
        });

        loader.load().then(() => {
            let center = {lat: 28.7041, lng: 77.1025}, map: google.maps.Map, infoWindow: google.maps.InfoWindow, startAutocomplete, endAutoComplete,  meetingAutoComplete, marker, service = new google.maps.DirectionsService(), display = new google.maps.DirectionsRenderer(), defaultBounds = {
                north: center.lat + 0.1,
                south: center.lat - 0.1,
                east: center.lng + 0.1,
                west: center.lng - 0.1,
            };
            const startInput = document.getElementById('start_point') as HTMLInputElement;
            const endInput = document.getElementById('end_point') as HTMLInputElement;
            const meetingInput = document.getElementById('meeting_point') as HTMLInputElement;

            const options = {
                bounds: defaultBounds,
                componentRestrictions: { country: 'in' },
                fields: ['address_components', 'geometry', 'icon', 'name'],
                strictBounds: false,
                types: ['establishment'],
            };

            map = new google.maps.Map(document.getElementById('gmap') as HTMLElement, {
                center: center,
                zoom: 6,
            });

            startAutocomplete = new google.maps.places.Autocomplete(startInput, options);
            endAutoComplete = new google.maps.places.Autocomplete(endInput, options);
            meetingAutoComplete = new google.maps.places.Autocomplete(meetingInput, options);

            infoWindow = new google.maps.InfoWindow();
            const locationButton = document.createElement('button');
            locationButton.innerHTML = '<i class=\'fa fa-location-arrow\'></i> Locate me';
            locationButton.classList.add('custom-map-control-button');
            map.controls[google.maps.ControlPosition.TOP_CENTER].push(locationButton);

            startAutocomplete.addListener('place_changed', () => {
                marker = new google.maps.Marker({
                    map,
                    anchorPoint: new google.maps.Point(0, -29),
                });

                const place = startAutocomplete.getPlace();

                if (!place.geometry || !place.geometry.location) {
                    Swal.fire('Not available', 'No details available for input: \'' + place.name + '\'', 'error');
                    return false;
                }
                console.log('place place',place.name);
                if (place.geometry || place.geometry.location) {
                    this.MainStartPointName = place.name;
                }

                if (place.geometry.viewport) {
                    map.fitBounds(place.geometry.viewport);
                } else {
                    map.setCenter(place.geometry.location);
                    map.setZoom(12);
                }
                /*if (!this.startLatLng) {
                    this.startLatLng = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}
                    marker.setPosition(place.geometry.location);
                    marker.setVisible(true);
                }
                else {
                    this.endLatLng = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}
                }*/
                this.startLatLng = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}
                marker.setPosition(place.geometry.location);
                marker.setVisible(true);
                if (this.endLatLng) { this.calcRoute(map, service, display, this.startLatLng, this.endLatLng); }
            });

            endAutoComplete.addListener('place_changed', () => {
                marker.setVisible(false);
                const place = endAutoComplete.getPlace();

                if (!place.geometry || !place.geometry.location) {
                    Swal.fire('Not available', 'No details available for input: \'' + place.name + '\'', 'error');
                    return false;
                }
                console.log('end place',place.name)
                if (place.geometry || place.geometry.location) {
                    this.MainEndPointName = place.name;
                }

                if (place.geometry.viewport) {
                    map.fitBounds(place.geometry.viewport);
                } else {
                    map.setCenter(place.geometry.location);
                    map.setZoom(12);
                }
                if (!this.startLatLng) {
                    this.startLatLng = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}
                } else {
                    this.endLatLng = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}
                    this.calcRoute(map, service, display, this.startLatLng, this.endLatLng);
                }
            });

            meetingAutoComplete.addListener('place_changed', () => {
                marker.setVisible(false);
                const place = meetingAutoComplete.getPlace();

                if (!place.geometry || !place.geometry.location) {
                    Swal.fire('Not available', 'No details available for input: \'' + place.name + '\'', 'error');
                    return false;
                }
                if (place.geometry || place.geometry.location) {
                    this.MainMeetingPointName = place.name;
                }
                this.meetingLatLng = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}
            });

            locationButton.addEventListener('click', () => {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                        (position: any) => {
                            const pos = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            };
                            if (!this.startLatLng) {
                                this.startLatLng = pos;
                            } else {
                                this.endLatLng = pos;
                            }
                            marker = new google.maps.Marker({
                                position: pos,
                                map,
                                title: 'Location found.',
                            });
                            infoWindow.setPosition(pos);
                            infoWindow.setContent('Location found.');
                            infoWindow.open(map);
                            map.setCenter(pos);
                        },
                        () => {
                            // console.log('Google map error occurred);
                        }
                    );
                }
            });
        });
    }

    getAddress (lat, lng) {
        return new Promise(function (resolve, reject) {
            const request = new XMLHttpRequest();

            const method = 'GET';
            const url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&sensor=true&key=' + environment.gMapKey;
            const async = true;

            request.open(method, url, async);
            request.onreadystatechange = function () {
                if (request.readyState == 4) {
                    if (request.status == 200) {
                        const data = JSON.parse(request.responseText);
                        const address = data.results[0];
                        resolve(address);
                    } else {
                        reject(request.status);
                    }
                }
            };
            request.send();
        });
    }

    calcRoute(map, directionsService, directionsDisplay, startLatLng, endLatLng) {
        const self = this;
        const start = new google.maps.LatLng(startLatLng.lat, startLatLng.lng);
        const end = new google.maps.LatLng(endLatLng.lat, endLatLng.lng);
        const request = {
            origin: start,
            destination: end,
            travelMode: google.maps.TravelMode.DRIVING
        };
        directionsService.route(request, function(response, status) {
            if (status == google.maps.DirectionsStatus.OK) {
                self.routeData = response.routes[0];
                self.kms = response.routes[0].legs[0].distance.text
                directionsDisplay.setDirections(response);
                directionsDisplay.setMap(map);
            } else {
                Swal.fire('Directions Request', 'Directions Request from ' + start.toUrlValue(6) + ' to ' + end.toUrlValue(6) + ' failed: ' + status, 'error');
                return false;
            }
        });
    }

    async calcItineraryRoute(index, map = null, directionsService, directionsDisplay, itinerary) {
        const start = new google.maps.LatLng(itinerary.start.latLng.lat, itinerary.start.latLng.lng);
        const end = new google.maps.LatLng(itinerary.end.latLng.lat, itinerary.end.latLng.lng);
        const request = {
            origin: start,
            destination: end,
            travelMode: google.maps.TravelMode.DRIVING
        };
        directionsService.route(request, async function(response, status) {
            if (status == google.maps.DirectionsStatus.OK) {
                itinerary.routeData = response.routes[0];
                itinerary.totalKMS = response.routes[0].legs[0].distance.text;
                if (map) {
                    directionsDisplay.setDirections(response);
                    directionsDisplay.setMap(map);
                }
            } else {
                Swal.fire('Directions Request', 'Directions Request from ' + start.toUrlValue(6) + ' to ' + end.toUrlValue(6) + ' failed: ' + status, 'error');
                return false;
            }
        });
    }

    onFileInput(e) {
        this.images = [];
        this.Viewimages = [];
        if (e.target.files && e.target.files.length) {
            const slides = [];
            for (let i = 0; i < e.target.files.length; i++) {
                if (this.images.length >= 5) {
                    Swal.fire('Limit Exceeded', 'You are not eligible to upload more then 5 Images', 'error');
                    return false;
                }
                this.images.push(e.target.files[i]);
                const reader = new FileReader();
                reader.onload = function(el) {
                    slides.push(el.target.result);
                }
                reader.readAsDataURL(e.target.files[i]);
            }
            this.mapHeight = '1000px';
            this.slides = slides;
        }
    }
   
    QrCodeFileInput(e) {
        this.qrImages = [];
        if (e.target.files && e.target.files.length) {
            const slides = [];
            for (let i = 0; i < e.target.files.length; i++) {
                this.images.push(e.target.files[i]);
                const reader = new FileReader();
                reader.onload = function(el) {
                    slides.push(el.target.result);
                }
                reader.readAsDataURL(e.target.files[i]);
            }
            this.mapHeight = '1000px';
            this.qrImages = slides;
            this.payment.qrCode = e.target.files[0];
        }
    }

    selectPaymentOption(val){
        console.log('uudue',val);
        this.paymentSetupOption=val;
    }

    onFileInputMore(e) {
        // this.images = [];
        const self = this;
        if (e.target.files && e.target.files.length) {
            // let slides = [];
            for (let i = 0; i < e.target.files.length; i++) {
                if (this.images.length >= 5) {
                    Swal.fire('Limit Exceeded', 'You are not eligible to upload more then 5 Images', 'error');
                    return false;
                }
                this.images.push(e.target.files[i]);
                const reader = new FileReader();
                reader.onload = function(el) {
                    self.slides.push(el.target.result);
                }
                reader.readAsDataURL(e.target.files[i]);
            }
            this.mapHeight = '1000px';
            // this.slides = slides;
        }
    }

    removeQr(index: number) {
        this.qrImages.splice(index, 1);
      }
    removeImage(index: number) {
        this.slides.splice(index, 1);
        this.images.splice(index, 1);
      }

    countriesList() {
        this.soloTravelHistoryService.getCountries().then(res => {
            if (res.success) { this.countries = res.data; } else { this.countries = []; }
        });
    }

    async statesList(id = null) {
        const countryId = id ? id : this.selectedCountry.id;
        return this.soloTravelHistoryService.getStates(countryId).then(res => {
            if (res.success) { this.states = res.data; } else { this.states = []; }
        });
    }

    async citiesList(id = null) {
        const stateId = id ? id : this.selectedState.id;
        return this.soloTravelHistoryService.getCities(stateId).then(res => {
            if (res.success) { this.cities = res.data; } else { this.cities = []; }
        });
    }

    async findDiffBetween(date1, date2) {
        date1 = this.customValidate.formatDate(date1);
        date2 = this.customValidate.formatDate(date2);
        const fromDate = new Date(date1);
        const toDate = new Date(date2);

        const Difference_In_Time = toDate.getTime() - fromDate.getTime();

        return (Difference_In_Time / (1000 * 3600 * 24)) + 1;
    }

    makeAutoComplete(type, index) {
        const loader = new Loader({
            apiKey: environment.gMapKey,
            version: 'weekly',
            libraries: ['places', 'drawing', 'geometry']
        });
        loader.load().then(() => {
            const center = this.startLatLng,
                map = new google.maps.Map(document.getElementById('itineraryMap') as HTMLElement, {
                    center: center,
                    zoom: 6,
                }),
                service = new google.maps.DirectionsService(),
                display = new google.maps.DirectionsRenderer(),
                defaultBounds = {
                    north: center.lat + 0.1,
                    south: center.lat - 0.1,
                    east: center.lng + 0.1,
                    west: center.lng - 0.1,
                },
                itinerary = this.itinerariesArr[index];

            const options = {
                bounds: defaultBounds,
                componentRestrictions: { country: 'in' },
                fields: ['address_components', 'geometry', 'icon', 'name'],
                strictBounds: false,
                types: ['establishment'],
            };
            if (type == 'start') {
                const startInput = document.getElementById('dayStart' + index) as HTMLInputElement;
                const startLocation = new google.maps.places.Autocomplete(startInput, options);
                startLocation.addListener('place_changed', () => {

                    const place = startLocation.getPlace();

                    if (!place.geometry || !place.geometry.location) {
                        Swal.fire('Not available', 'No details available for input: \'' + place.name + '\'', 'error');
                        return false;
                    }

                });
            }
            if (type == 'end') {

                // this.showMapElement();
                const endInput = document.getElementById('dayEnd' + index) as HTMLInputElement;
                const endLocation = new google.maps.places.Autocomplete(endInput, options);

                endLocation.addListener('place_changed', async () => {
                    const place = endLocation.getPlace();
                    if (!place.geometry || !place.geometry.location) {
                        Swal.fire('Not available', 'No details available for input: \'' + place.name + '\'', 'error');
                        return false;
                    }

                    itinerary.end.placeData = place;
                    itinerary.end.latLng = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()};
                    itinerary.end.location = place.name;
                    if (index < (this.itinerariesArr.length - 1)) {
                        this.patchItineraries(this.itinerariesArr, this.itinerariesArr[index], index)
                    }
                    await this.calcItineraryRoute(index, map, service, display, itinerary);

                });

            }

            if (type == 'show') {
                this.StartLocation = itinerary.start.location;
                this.EndLocation = itinerary.end.location;
                this.showMapElement().then(async res => {
                    if (res) {
                        await this.calcItineraryRoute(index, map, service, display, itinerary);
                    }
                });

            }
        });

    }


    makeLocationMap(type, index) {
        const loader = new Loader({
            apiKey: environment.gMapKey,
            version: 'weekly',
            libraries: ['places', 'drawing', 'geometry']
        });
        loader.load().then(() => {
            const center = this.startLatLng,
                defaultBounds = {
                    north: center.lat + 0.1,
                    south: center.lat - 0.1,
                    east: center.lng + 0.1,
                    west: center.lng - 0.1,
                }

            const options = {
                bounds: defaultBounds,
                componentRestrictions: { country: 'in' },
                fields: ['address_components', 'geometry', 'icon', 'name'],
                strictBounds: false,
                types: ['establishment'],
            };
                // this.showMapElement();
                const endInput = document.getElementById('location' + index) as HTMLInputElement;
                const endLocation = new google.maps.places.Autocomplete(endInput, options);

                endLocation.addListener('place_changed', async () => {
                    const place = endLocation.getPlace();
                    if (!place.geometry || !place.geometry.location) {
                        Swal.fire('Not available', 'No details available for input: \'' + place.name + '\'', 'error');
                        return false;
                    }


                });
        });

    }

  

    checkStatusOnDate() {
        const startDate = new Date(this.AddHistoryForm.get('startDate').value); // Modify with your start date
        const endDate = new Date(this.AddHistoryForm.get('endDate').value);
        const today = new Date();
        this.uploadeFileEnabled = true;
        // if (startDate > today && endDate > today) {
        // this.AddHistoryForm.controls['plan_status'].setValue('Upcoming')
        // this.uploadeFileEnabled = false;
        // } else if (startDate <= today && endDate >= today) {
        // this.AddHistoryForm.controls['plan_status'].setValue('Ongoing')
        // this.uploadeFileEnabled = true;
        // } else {
        // this.AddHistoryForm.controls['plan_status'].setValue('Completed')
        // this.uploadeFileEnabled = true;
        // }
    }

    async checkDates(routeData = null, plans= null) {
        this.checkStatusOnDate();
        this.totalDays = await this.findDiffBetween(this.AddHistoryForm.get('startDate').value, this.AddHistoryForm.get('endDate').value);

        const itineraries = this.AddHistoryForm.get('itineraries') as FormArray;
        //stayPlans = this.AddHistoryForm.get('stayPlans') as FormArray;
        itineraries.clear();
       // stayPlans.clear();
        this.itinerariesArr = [];
       // this.stayPlansArr = [];

        for (let i = 0; i < this.totalDays; i++) {
           // await this.addStayPlans(stayPlans, (plans ? plans[i] : null));
            await this.addItineraries(itineraries, (routeData ? routeData[i] : null));
            this.itinerariesArr.push({
                day: i + 1,
                start : {
                    placeData: routeData ? routeData[i].start.placeData : '',
                    location: routeData ? routeData[i].start.location : ( i === 0 ? this.MainStartPointName : ''),
                    latLng:  routeData ? routeData[i].start.latLng : ( i === 0 ? this.startLatLng : 0 )
                },
                end : {
                    placeData:  routeData ? routeData[i].end.placeData : '',
                    location:  routeData ? routeData[i].end.location : '',
                    latLng:  routeData ? routeData[i].end.latLng : 0
                },
                routeData : '',
                totalKMS :  routeData ? routeData[i].totalKMS : 0
            });
           
        }
        itineraries.at(0).get('start').setValue(this.MainStartPointName);
        console.log('this main start point',this.MainStartPointName);
    }
    

    async showMap(idx: number) {
        this.showItineraryMap = true;
        this.showItineraryMap2 = true;
        this.makeAutoComplete('show', idx);
    }

    async showMapElement() {
        return this.showItineraryMap = true;
    }

    async HideMap() {
        this.showItineraryMap2 = false;
        this.showItineraryMap = false;
    }

    isCurrentStepValid(): boolean {
        switch (this.activeIndex) {
          case 0:
            return this.AddHistoryForm.get('trip_name').valid && 
                   this.AddHistoryForm.get('privacy_status').valid;
          case 1:
            return true; // Optional step, can always skip
          case 2:
            return this.AddHistoryForm.get('itineraries').valid;
          case 3:
            return this.AddHistoryForm.get('budgeting').valid;
          default:
            return false;
        }
      }

      values1: string[];

    next() {
        if (this.isCurrentStepValid()) {
          this.activeIndex++;
        }
       
      }
    
      prev() {
        this.activeIndex--;
      }
    
      isFirstStep(): boolean {
        return this.activeIndex === 0;
      }
    
      isLastStep(): boolean {
        return this.activeIndex === this.steps.length - 1;
      }
    
      skip() {
        this.activeIndex++;
      }

      
      
}
