import {environment} from "enviornments/environment"

export const imagePathHelper = {
    profileImage : environment.imageURL+'images/__USERID__/profile/',
    coverImage : environment.imageURL+'images/__USERID__/cover/',
    postAttachment : environment.imageURL+'images/__USERID__/posts/__POST_ID__/',
    blogAttachment : environment.imageURL+'images/__USERID__/blogs/__BLOG_ID__/attachments/__TYPE__/',
    blogThumb : environment.imageURL+'images/__USERID__/blogs/__BLOG_ID__/thumbnails/',
    postCommentAttachment : environment.imageURL+'images/__POST_USERID__/posts/__POST_ID__/comments/__USER_ID__/',
    travelHistoryAttachment : environment.imageURL+'images/__USERID__/travel_histories/__ID__/',
    groupTravelHistoryAttachment : environment.imageURL+'images/groups/__USERID__/travel_histories/__ID__/',
    news : environment.imageURL+'images/__USERID__/news/attachments/',
    newsThumb : environment.imageURL+'images/__USERID__/news/thumbnails/',
    addSattachement : environment.AddimageURL+'__USERID__/',
};