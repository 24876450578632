import { Component, OnInit } from '@angular/core';
import { OtherUserTravelHistoryService } from '../OtherUserTravelHistory.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router,ActivatedRoute} from '@angular/router';
import {MessageService} from 'primeng/api';
import {ConfirmationService} from 'primeng/api';
import {} from 'googlemaps';
import {Loader} from "@googlemaps/js-api-loader";
import {environment} from "enviornments/environment"
import { generalHelper } from "../../../../helpers/generalHelper";
import { AuthService } from 'app/services/auth.service';

@Component({
    selector: 'app-viewOtherUsertravelhistory',
    templateUrl: './view.component.html',
    styleUrls: ['./view.component.scss']
})

export class ViewOtherUserTravelHistoryComponent implements OnInit {
    env=environment;
    generalHelper = generalHelper;
    user :any;
    loading: boolean;
    errors: boolean;
    startLatLng: any;
    endLatLng: any;
    getStartAddress:any;
    getEndAddress:any;
    soloHistory: any = {
        files : null
    };
    slides: any = [];
    constructor(private OtherUserTravelHistoryService: OtherUserTravelHistoryService, private confirmationService: ConfirmationService, private messageService: MessageService, private fb: FormBuilder, private router: Router, private route: ActivatedRoute,public authService: AuthService) { }

    ngOnInit() {
        this.user = this.authService.getLoggedInUser();
        this.getTravelHistoryPatch();
        this.initGMap();
    }

    getTravelHistoryPatch() {
        this.OtherUserTravelHistoryService.getSoloTravelHistoryEdit(this.route.snapshot.params.id).then((res) => {
            if(res.success){
                this.soloHistory = res.data;
                this.startLatLng = JSON.parse(res.data.start_latLng);
                this.endLatLng = JSON.parse(res.data.end_latLng);
                this.slides = JSON.parse(res.data.files);
                if (this.endLatLng) {
                    let map = new google.maps.Map(document.getElementById("gmap") as HTMLElement, {
                        center: {lat: 28.7041, lng: 77.1025},
                        zoom: 6,
                    });
                    this.calcRoute(map, new google.maps.DirectionsService(), new google.maps.DirectionsRenderer());
                }
            }
        });
    }

    ShowHistoryList(){
        this.router.navigate(['solo/travel-history'])
    }


    initGMap() {
        const loader = new Loader({
            apiKey: environment.gMapKey,
            version: "weekly",
            libraries: ["places","drawing","geometry"]
        });

        loader.load().then(() => {
            let center = {lat: 28.7041, lng: 77.1025}, map: google.maps.Map;

            map = new google.maps.Map(document.getElementById("gmap") as HTMLElement, {
                center: center,
                zoom: 6,
            });
        });
    }

    calcRoute(map, directionsService, directionsDisplay) {
        var start = new google.maps.LatLng(this.startLatLng.lat, this.startLatLng.lng);
        var end = new google.maps.LatLng(this.endLatLng.lat, this.endLatLng.lng);
        var request = {
            origin: start,
            destination: end,
            travelMode: google.maps.TravelMode.DRIVING
        };
        directionsService.route(request, function(response, status) {
            if (status == google.maps.DirectionsStatus.OK) {
                directionsDisplay.setDirections(response);
                directionsDisplay.setMap(map);
            } else {
                alert("Directions Request from " + start.toUrlValue(6) + " to " + end.toUrlValue(6) + " failed: " + status);
            }
        });
    }
}