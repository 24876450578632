import { Component, OnInit } from '@angular/core';
import { soloTravelHistoryService } from '../soloTravelHistory.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router,ActivatedRoute} from '@angular/router';
import {MessageService} from 'primeng/api';
import {ConfirmationService} from 'primeng/api';
import {} from 'googlemaps';
import {Loader} from "@googlemaps/js-api-loader";
import {environment} from "enviornments/environment"
import { generalHelper } from "../../../../helpers/generalHelper";
import { AuthService } from 'app/services/auth.service';


@Component({
    selector: 'app-viewsolotravelhistory',
    templateUrl: './view.component.html',
    styleUrls: ['./view.component.scss']
})

export class ViewTravelHistoryComponent implements OnInit {
    env=environment;
    generalHelper = generalHelper;
    user:any;
    loading: boolean;
    errors: boolean;
    startLatLng: any;
    endLatLng: any;
    getStartAddress:any;
    getEndAddress:any;
    soloHistory: any = {
        files : null
    };
    itinerariesArr = [];
    StartLocation:any='';
    EndLocation:any='';
    showItineraryMap2=false;
    slides: any = [];
    images: any=[];
    itineraryList:any=[];
    contentLoader=false;
    checklist:any={};
    imgUrl='';
    constructor(private soloTravelHistoryService: soloTravelHistoryService, private confirmationService: ConfirmationService, private messageService: MessageService, private fb: FormBuilder, private router: Router, private route: ActivatedRoute,private authService: AuthService) { }

    ngOnInit() {
        this.getTravelHistoryPatch();
        this.initGMap();
        this.user = this.authService.getLoggedInUser();
        this.imgUrl=this.env.imageURL+'/images/'+this.user.id+'/travel_histories/'+this.route.snapshot.params.id+'/';
    }

    getTravelHistoryPatch() {
        this.contentLoader=true;
        let self=this;
        this.soloTravelHistoryService.getSoloTravelHistoryView(this.route.snapshot.params.id).then((res) => {
            if(res.success){
                this.soloHistory = res.data;
                this.itineraryList= this.soloHistory.itinerary ? JSON.parse(this.soloHistory.itinerary) : [];
                this.checklist = this.soloHistory.checklist ? JSON.parse(this.soloHistory.checklist) : {};
                this.startLatLng = JSON.parse(res.data.start_latLng);
                this.endLatLng = JSON.parse(res.data.end_latLng);
                if(res.data.files){
                    this.slides = JSON.parse(res.data.files);
                    self.images = [];
                    for(let file of self.slides){
                        self.images.push({previewImageSrc:file.attachmentName.replace('public',self.env.imageURL), thumbnailImageSrc:file.attachmentName, alt:file.attachmentName.replace('public',self.env.imageURL), title:file.attachmentName});
                    }
                   
                }                
                if (this.endLatLng) {
                    let map = new google.maps.Map(document.getElementById("gmap") as HTMLElement, {
                        center: {lat: 28.7041, lng: 77.1025},
                        zoom: 6,
                    });
                    this.calcRoute(map, new google.maps.DirectionsService(), new google.maps.DirectionsRenderer());
                }
                this.contentLoader=false;
            }
        });
    }

    ShowHistoryList(){
        this.router.navigate(['solo/travel-history'])
    }


    initGMap() {
        const loader = new Loader({
            apiKey: environment.gMapKey,
            version: "weekly",
            libraries: ["places","drawing","geometry"]
        });

        loader.load().then(() => {
            let center = {lat: 28.7041, lng: 77.1025}, map: google.maps.Map;

            map = new google.maps.Map(document.getElementById("gmap") as HTMLElement, {
                center: center,
                zoom: 6,
            });
        });
    }

    calcRoute(map, directionsService, directionsDisplay) {
        var start = new google.maps.LatLng(this.startLatLng.lat, this.startLatLng.lng);
        var end = new google.maps.LatLng(this.endLatLng.lat, this.endLatLng.lng);
        var request = {
            origin: start,
            destination: end,
            travelMode: google.maps.TravelMode.DRIVING
        };
        directionsService.route(request, function(response, status) {
            if (status == google.maps.DirectionsStatus.OK) {
                directionsDisplay.setDirections(response);
                directionsDisplay.setMap(map);
            } else {
                alert("Directions Request from " + start.toUrlValue(6) + " to " + end.toUrlValue(6) + " failed: " + status);
            }
        });
    }

    makeAutoComplete(type,index) {                                                            
        this.itinerariesArr.push({
            day: index+1,
            start : {
                placeData:  this.itineraryList[index].start.placeData,
                location: this.itineraryList[index].start.location,
                latLng:  this.itineraryList[index].start.latLng
            },
            end : {
                placeData:  this.itineraryList[index].end.placeData,
                location: this.itineraryList[index].end.location,
                latLng:  this.itineraryList[index].end.latLng
            },
            routeData : '',
            totalKMS :  this.itineraryList[index].totalKMS
        });
        
        const loader = new Loader({
            apiKey: environment.gMapKey,
            version: "weekly",
            libraries: ["places","drawing","geometry"]
        });
        loader.load().then(() => {
            let center = {lat: 28.7041, lng: 77.1025}, map: google.maps.Map, service = new google.maps.DirectionsService(), display = new google.maps.DirectionsRenderer(), defaultBounds = {
                north: center.lat + 0.1,
                south: center.lat - 0.1,
                east: center.lng + 0.1,
                west: center.lng - 0.1,
            },itinerary = this.itinerariesArr[0];
            const options = {
                bounds: defaultBounds,
                componentRestrictions: { country: "in" },
                fields: ["address_components", "geometry", "icon", "name"],
                strictBounds: false,
                types: ["establishment"],
            };
           
            if (type == 'show') {
                this.StartLocation=itinerary.start.location;
                this.EndLocation=itinerary.end.location;
                this.showMapElement().then(res => {
                    if (res) {
                        map = new google.maps.Map(document.getElementById("itineraryMap") as HTMLElement, {
                            center: center,
                            zoom: 6,
                        });
                        this.calcItineraryRoute(index, map, service, display, itinerary);
                    }
                });
               
            }
        });
        
    }

    calcItineraryRoute(index, map = null, directionsService, directionsDisplay, itinerary) {
        var start = new google.maps.LatLng(itinerary.start.latLng.lat, itinerary.start.latLng.lng);
        var end = new google.maps.LatLng(itinerary.end.latLng.lat, itinerary.end.latLng.lng);
        var request = {
            origin: start,
            destination: end,
            travelMode: google.maps.TravelMode.DRIVING
        };
        directionsService.route(request, function(response, status) {
            if (status == google.maps.DirectionsStatus.OK) {
                itinerary.routeData = response.routes[0];
                itinerary.totalKMS = response.routes[0].legs[0].distance.text;
                if (map){
                    directionsDisplay.setDirections(response);
                    directionsDisplay.setMap(map);
                }
            } else {
                alert("Directions Request from " + start.toUrlValue(6) + " to " + end.toUrlValue(6) + " failed: " + status);
            }
        });
    }

    async showMap(idx: number) {
        this.itinerariesArr=[];
        this.showItineraryMap2 = true;
        this.makeAutoComplete('show',idx);
    }


    async showMapElement() {
        return this.showItineraryMap2 = true;
    }

    async HideMap(){
        this.showItineraryMap2 = false;
    }
}