import { Component, OnInit } from '@angular/core';
import { NewsService } from '../news.service';
import { generalHelper } from '../../../helpers/generalHelper';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import Swal from "sweetalert2";
import {HttpEventType} from "@angular/common/http";
import {Router,ActivatedRoute} from '@angular/router';
import { jsonpFactory } from '@angular/http/src/http_module';
import { AuthService } from 'app/services/auth.service';
import { environment } from 'enviornments/environment';
@Component({
    selector: 'app-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.scss']
})


export class UploadComponent implements OnInit {
    env=environment;
    user : any;
    generalHelper = generalHelper;
    imageHelper :any;
    imageThumb : any;
    AddNewsForm: FormGroup;
    getcategory: any=[];
    loading: boolean;
    errors: boolean;
    uploadStatus: number;
    schedulepost:any=false;
    display:any;
    thumbnail: File = null;
    thumbnailURL:any;
    image1: File = null;
    image1URL:any;
    image2: File = null;
    image2URL:any;
    image3: File = null;
    image3URL:any;
    image4: File = null;
    image4URL:any;
    image5: File = null;
    image5URL:any;
    contentLoader:boolean=false;
    token: any;
    newsPatch:any=[];
    PatchThumbnail:any;
    PatchImage1:any;
    PatchImage2:any;
    PatchImage3:any;
    PatchImage4:any;
    PatchImage5:any;
    newsID:any;
    minimumDate: any = new Date(Date.now());
    constructor(private uploadService: NewsService,private fb: FormBuilder,private router: Router,private route: ActivatedRoute, private authService: AuthService) { }

    ngOnInit() {
        this.user = this.authService.getLoggedInUser();
        this.categories();
        this.token = this.route.snapshot.params.id;
        if(this.token){this.patchNews();}
        
        this.AddNewsForm = this.fb.group({
            id: [0],
            title: [''],
            subtitle: ['', [Validators.required]],
            brand: ['', [Validators.required]],
            category: ['', [Validators.required]],
            description: ['', [Validators.required]],
            publish_date: ['', [Validators.required]],
        });
    }

    get id() {
        return this.AddNewsForm.controls.id
    }
    get title() {
        return this.AddNewsForm.controls.title
    }
    get subtitle() {
        return this.AddNewsForm.controls.subtitle
    }
    get brand() {
        return this.AddNewsForm.controls.brand
    }
    get category() {
        return this.AddNewsForm.controls.category
    }
    get description() {
        return this.AddNewsForm.controls.description
    }
    get publish_date() {
        return this.AddNewsForm.controls.publish_date
    }
    categories() {
        this.uploadService.categories().toPromise().then(res => {
            this.getcategory = res.data;
        }).catch(e => {});
    }
    handleThumbnailInput(e) {
        if (e.target.files && e.target.files.length) {
            this.thumbnail = <File>e.target.files[0];
        }
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]); 
        reader.onload = (_event) => { 
        this.thumbnailURL = reader.result;
        }
    }

    handleImage1Input(e) {
        if (e.target.files && e.target.files.length) {
            this.image1 = <File>e.target.files[0];
        }
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]); 
        reader.onload = (_event) => { 
        this.image1URL = reader.result; 
        }
    }

    handleImage2Input(e) {
        if (e.target.files && e.target.files.length) {
            this.image2 = <File>e.target.files[0];
        }
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]); 
        reader.onload = (_event) => { 
        this.image2URL = reader.result;
        }
    }

    handleImage3Input(e) {
        if (e.target.files && e.target.files.length) {
            this.image3 = <File>e.target.files[0];
        }
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]); 
        reader.onload = (_event) => { 
        this.image3URL = reader.result;
        }
    }

    handleImage4Input(e) {
        if (e.target.files && e.target.files.length) {
            this.image4 = <File>e.target.files[0];
        }
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]); 
        reader.onload = (_event) => { 
        this.image4URL = reader.result;
        }
    }

    handleImage5Input(e) {
        if (e.target.files && e.target.files.length) {
            this.image5 = <File>e.target.files[0];
        }
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]); 
        reader.onload = (_event) => { 
        this.image5URL = reader.result;
        }
    }

    submitNews(data) {
        this.contentLoader=true;
        const params = new FormData();
        params.append('id', data.id);
        params.append('title', data.title);
        params.append('subtitle', data.subtitle);
        params.append('brand', data.brand);
        params.append('category', data.category);
        params.append('thumbnail', this.thumbnail);
        params.append('image1', this.image1);
        params.append('image2', this.image2);
        params.append('image3', this.image3);
        params.append('image4', this.image4);
        params.append('image5', this.image5);
        params.append('description', data.description);
        params.append('publish_date', data.publish_date.toLocaleDateString());
        params.append('status', 'Saved');
        this.uploadService.addUpdate(params).subscribe(event => {
            if (event.type === HttpEventType.UploadProgress){
                this.uploadStatus = Math.round(event.loaded / event.total * 100)
            } else if (event.type === HttpEventType.Response){
                //this.resetPostForm();
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: event.body.message
                });
                // console.log(event.body.data);
                this.contentLoader=false;
               this.router.navigate(['solo/news/view/'+ event.body.data.id]);
               
            }
        }, error => {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message
            });
            this.contentLoader=false;
        });
    }

    patchNews() {
        const ID  = this.token;
         this.uploadService.Edit(ID).then((res) => {
         this.newsPatch = res.data;
         this.newsID = res.data.id
         this.PatchThumbnail= res.data.thumbnail.replace('public',this.env.imageURL);
         this.PatchImage1 = res.data.image1.replace('public',this.env.imageURL)
         this.PatchImage2 = res.data.image2.replace('public',this.env.imageURL);
         this.PatchImage3 = res.data.image3.replace('public',this.env.imageURL);
         this.PatchImage4 = res.data.image4.replace('public',this.env.imageURL);
         this.PatchImage5 = res.data.image5.replace('public',this.env.imageURL);
         
         if (res) {
            this.AddNewsForm.patchValue({
                title: this.newsPatch.title,
                subtitle: this.newsPatch.subtitle,
                brand: this.newsPatch.brand,
                category: this.newsPatch.category,
                description: this.newsPatch.description,
                publish_date: new Date(this.newsPatch.publish_date),
                
            });
        }
     });
    }

    editNews(data) {
        this.contentLoader=true;
        const params = new FormData();
        params.append('id', this.newsID);
        params.append('title', data.title);
        params.append('subtitle', data.subtitle);
        params.append('brand', data.brand);
        params.append('category', data.category);
        params.append('thumbnail', this.thumbnail);
        params.append('image1', this.image1);
        params.append('image2', this.image2);
        params.append('image3', this.image3);
        params.append('image4', this.image4);
        params.append('image5', this.image5);
        params.append('description', data.description);
        params.append('publish_date', data.publish_date.toLocaleDateString());
        params.append('status', 'Saved');
        this.uploadService.addUpdate(params).subscribe(event => {
            if (event.type === HttpEventType.UploadProgress){
                this.uploadStatus = Math.round(event.loaded / event.total * 100)
            } else if (event.type === HttpEventType.Response){
                //this.resetPostForm();
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: event.body.message
                });
                // console.log(event.body.data);
                this.contentLoader=false;
               this.router.navigate(['solo/news/view/'+ event.body.data.id]);
               
            }
        }, error => {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message
            });
            this.contentLoader=false;
        });
    }
  
}
