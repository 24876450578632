import { Component, OnInit } from '@angular/core';
import {SoloRoutesTravelHistoryService} from '../solo_routes_travelhistory.service';
import {Router} from '@angular/router';
import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/api';

@Component({
    selector: 'app-solorouteslisting',
    templateUrl: './solo_routes_listing.component.html',
    styleUrls: ['./solo_routes_listing.component.scss']
})

export class SoloRoutesListingComponent implements OnInit {
    soloRoute: any = [];
    loading: boolean;
    errors: boolean;
    options: any;
    city:any;
    state:any;
    country:any;
    totalkm:any;
    description:any;
    overlays: any[];
    display: boolean = false;
    // tslint:disable-next-line:no-shadowed-variable
    constructor(private SoloRoutesTravelHistoryService: SoloRoutesTravelHistoryService,private router: Router, private confirmationService: ConfirmationService, private messageService: MessageService) { }

    ngOnInit() {
        this.getRoute();
    }
    

    getRoute() {
        this.SoloRoutesTravelHistoryService.getSoloRoute().then((res) => {
            if (res.success) {
                this.soloRoute = res.data;
            }
        }).catch((error) => {});
    }

    showDialog(i) {
        this.display = true;
        this.city=this.soloRoute[i].city;
        this.country=this.soloRoute[i].country;
        this.totalkm=this.soloRoute[i].total_km;
        this.state=this.soloRoute[i].state;
        this.description=this.soloRoute[i].description;
    }
    
    addRoute(){
        this.router.navigate(['solo/routes/create'])
    }

    switchToRoutesEdit(id){
        this.router.navigateByUrl(`/solo/routes/edit/${id}`);
    }
    
    switchToRoutesView(id){
        this.router.navigateByUrl(`/solo/routes/view/${id}`);
    }

    deleteRoute(id) {

        this.confirmationService.confirm({
            message: 'Do you want to delete this history',
            header: 'Delete Route History Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                return this.SoloRoutesTravelHistoryService.deleteRoute(id).subscribe((res) => {
                    if (res.success) {
                        this.messageService.add({severity: 'success', summary: 'Success', detail: res.message});
                        this.getRoute();
                    } else {
                        this.messageService.add({severity: 'error', summary: 'Error', detail: res.message});
                        this.getRoute();
                    }
                }), err => {
                    this.getRoute();
                };

            },
            reject: () => {}
        });

    }

    getConfirmation(header: string, message: string) {
        return new Promise((resolve, reject) => {
            this.confirmationService.confirm({
                message: message,
                header: header,
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    resolve(true);
                },
                reject: () => {
                    resolve(false);
                }
            });
        });
    }


}
