import { Injectable } from '@angular/core';
import { BaseService } from '../../../_services/base.service';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class PageLeftSidebarService {

    constructor(private baseService: BaseService,private http: HttpClient) { }

    list(pageVar) {
        return this.baseService.getRequest(`ads/`+pageVar);
    }

}
