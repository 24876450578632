import { Injectable } from '@angular/core';
import { BaseService } from '../../../../_services/base.service';

@Injectable()
export class GroupsDashboardService {

    constructor(private baseService: BaseService) { }

    getAllPosts(uuid){
        return this.baseService.get(`group/dashboard/${uuid}`);
    }

    postDetails(data){
        return this.baseService.post(`group/posts/details`, data);
    }

    doLike(data){
        return this.baseService.post(`group/posts/like`, data);
    }

    doComment(data){
        return this.baseService.postFile(`group/posts/comment`, data);
    }

    getFollowersList() {
        return this.baseService.getRequest(`group/follow/list`).toPromise();
    }

}
