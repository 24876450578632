import { Injectable } from '@angular/core';
import { BaseService } from '../../../_services/base.service';

@Injectable()
export class AddBrandService {

    constructor(private baseService: BaseService) { }

    getEndoresd(){
        return this.baseService.get(`user/brands/endorsed`);
    }
    
    getBrand(){
        return this.baseService.get(`user/brands/list`);
    }

    addBrands(params) {
        return this.baseService.post(`user/brands/addUpdate`, params);
    }

    detailsBrand(id) {
        return this.baseService.getRequest(`user/brands/edit/`+id);
    }
    

}
