import { Injectable } from '@angular/core';
import { BaseService } from '../../_services/base.service';

@Injectable()
export class NewPostService {

    constructor(private baseService: BaseService) { }

    profile(){
        return this.baseService.get(`user/profile`);
    }

    addUpdate(formData) {
        return this.baseService.postFile(`user/posts/addUpdate`,formData);
    }
    
    deletePost(id) {
        return this.baseService.delete(`user/posts/delete/`+id);
    }

    details(id) {
        return this.baseService.getRequest(`user/posts/edit/`+id);
    }

    getFollowersList() {
        return this.baseService.getRequest(`user/follow/list`).toPromise();
    }
}
