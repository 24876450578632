import { Component, OnInit } from '@angular/core';
import { NewsService } from '../news.service';
import {ActivatedRoute, Router} from "@angular/router";
import { imagePathHelper } from "../../../helpers/imagePathHelper";
import Swal from "sweetalert2";
import { Gallery, GalleryItem, ImageItem } from '@ngx-gallery/core';
import { AuthService } from 'app/services/auth.service';
import { generalHelper } from '../../../helpers/generalHelper';
import { environment } from 'enviornments/environment';
@Component({
    selector: 'app-newspage-details',
    templateUrl: './newsdetail.component.html',
    styleUrls: ['./newsdetail.component.scss']
})

export class NewsDetailComponent implements OnInit {
    env=environment;
    user :any;
    generalHelper = generalHelper;
    token: any;
    newsPatch:any=[];
    PatchThumbnail:any
    PatchImage1:any
    PatchImage2:any
    PatchImage3:any
    PatchImage4:any
    PatchImage5:any
    loading: boolean;
    errors: boolean;
    NewsList:any;
    loader:any;
    uploadStatus: number;
    getcategory:any;
    constructor(private NewsService: NewsService, private router: Router, private route: ActivatedRoute,public gallery: Gallery,private authService: AuthService) { }

    ngOnInit() {
       // this.user = this.authService.getLoggedInUser();
        
        this.token = this.route.snapshot.params.id;
        this.getView();
        this.getNewsList();
    }
    
    getView() {
        this.loader=true;
        const ID  = this.token;
         this.NewsService.details(ID).subscribe(res => {
            this.loader=false;
            this.newsPatch = res.data;
            this.PatchThumbnail= res.data.thumbnail.replace('public',this.env.imageURL);
            this.PatchImage1 = res.data.image1.replace('public',this.env.imageURL);
            this.PatchImage2 = res.data.image2.replace('public',this.env.imageURL);
            this.PatchImage3 = res.data.image3.replace('public',this.env.imageURL);
            this.PatchImage4 = res.data.image4.replace('public',this.env.imageURL);
            this.PatchImage5 = res.data.image5.replace('public',this.env.imageURL);
     });
}

getNewsList() {
    this.NewsService.list().subscribe(res => {
        if (res.success) {
            this.NewsList = res.data.filter(el=> el.id != this.token);
        }
    },error => {
        this.NewsList = [];
    });
    
}

categories() {
    this.NewsService.categories().toPromise().then(res => {
        this.getcategory = res.data;
    }).catch(e => {});
}

NewsDetails(id){
    this.router.navigateByUrl(`news-detail/${id}`);
    this.token = id;
    this.getView();
    this.getNewsList();
}

}
