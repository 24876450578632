import { Injectable } from '@angular/core';
import { BaseService } from '../../../_services/base.service';

@Injectable()
// tslint:disable-next-line:class-name
export class OtherUserTravelHistoryService {

    constructor(private baseService: BaseService) { }

    getSoloTravelHistoryEdit(id) {
        return this.baseService.getRequest(`travel-history/${id}`).toPromise();
    }

    getCountries() {
        return this.baseService.getRequest(`countries`).toPromise();
    }

    getStates(id) {
        return this.baseService.getRequest(`states/`+id).toPromise();
    }

    getCities(id) {
        return this.baseService.getRequest(`cities/`+id).toPromise();
    }


}
