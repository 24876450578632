import { Injectable } from '@angular/core';
import { BaseService } from '../../_services/base.service';

@Injectable()
export class AddStatusService {

    constructor(private baseService: BaseService) { }

    profile(){
        return this.baseService.get(`user/profile`);
    }

}
