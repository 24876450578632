import {Component, OnInit, Renderer2} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {GalleryService} from './gallery.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import Swal from "sweetalert2";

import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import { generalHelper } from '../../../helpers/generalHelper';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
   
    
    loading: boolean;
    errors: boolean;
    display:boolean;
    
    
    timeline:any=true;
    
    upload:any;
    cover:any;
    
    album:any;
    
    constructor(private GroupSettingsService: GalleryService,private fb: FormBuilder,private confirmationService: ConfirmationService, private messageService: MessageService, private router: Router,) { }

    ngOnInit() {
        
    }

    
    
    
    
    showDialog() {
        
        }
    tabcontent(val) {
        
        
        if(val=='timeline'){
            
            this.timeline=true;
            
            this.upload=false;
            
            
            this.album=false;
            this.cover=false;
        }
        
        if(val=='upload'){
            
            this.timeline=false;
            
            this.upload=true;
            
            
            this.album=false;
            this.cover=false;
        }
        
        
        if(val=='album'){
            
            this.timeline=false;
            
            this.upload=false;
            
            
            this.album=true;
            this.cover=false;
        }
        if(val=='cover'){
            
            this.timeline=false;
            
            this.upload=false;
            this.album=false;
            this.cover=true;
            
            
        }
    }
    showDialog10() {
        this.display = true;
    }

    showDialogAddress(type) {
        
        
            
    }
      
    
    
    

    

   
    
}
