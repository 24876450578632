import { Component, OnInit } from '@angular/core';
import { soloTravelHistoryService } from '../soloTravelHistory.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {MessageService} from 'primeng/api';
import {ConfirmationService} from 'primeng/api';
import Swal from 'sweetalert2';
@Component({
    selector: 'app-editsolotravelhistory',
    templateUrl: './edit.component.html',
    styleUrls: ['./edit.component.scss']
})

export class EditSoloTravelHistoryComponent implements OnInit {
    user: any = {};
    loading: boolean;
    errors: boolean;
    AddHistoryForm: FormGroup;
    options: any;
    soloHistory: any = [];
    overlays: any[];
    display = false;
    token: any;
    // tslint:disable-next-line:no-shadowed-variable
    constructor(private soloTravelHistoryService: soloTravelHistoryService, private confirmationService: ConfirmationService, private messageService: MessageService, private fb: FormBuilder, private router: Router, private route: ActivatedRoute) { }

    ngOnInit() {
        this.token = this.route.snapshot.params.id;
        this.AddHistoryForm = this.fb.group({
            trip_name: ['', [Validators.required]],
            date: ['', [Validators.required]],
            country: ['', [Validators.required]],
            city: ['', [Validators.required]],
            state: ['', [Validators.required]],
            start_latLng: ['', [Validators.required]],
            end_latLng: ['', [Validators.required]],
            total_km: ['', [Validators.required]],
            description: ['', [Validators.required]],
            privacy_status: ['', [Validators.required]],
        });
        this.options = {
            center: {lat: 36.890257, lng: 30.707417},
            zoom: 12

        };
        this.getTravelHistoryPatch();
    }

    showDialog() {
        this.display = true;
    }
    ShowHistoryList() {
        this.router.navigate(['solo/travel-history'])
    }

    get trip_name() {
        return this.AddHistoryForm.controls.trip_name
    }
    get date() {
        return this.AddHistoryForm.controls.date
    }
    get country() {
        return this.AddHistoryForm.controls.country
    }
    get city() {
        return this.AddHistoryForm.controls.city
    }
    get state() {
        return this.AddHistoryForm.controls.state
    }
    get start_latLng() {
        return this.AddHistoryForm.controls.start_latLng
    }
    get end_latLng() {
        return this.AddHistoryForm.controls.end_latLng
    }
    get total_km() {
        return this.AddHistoryForm.controls.total_km
    }
    get description() {
        return this.AddHistoryForm.controls.description
    }
    get privacy_status() {
        return this.AddHistoryForm.controls.privacy_status
    }

    // getSoloTravelHistory() {
    //     this.soloTravelHistoryService.getSoloTravelHistory().then((res) => {
    //         if (res.success) {
    //             this.soloHistory = res.data;
    //         }
    //     }).catch((error) => {});
    // }

    getTravelHistoryPatch() {
               const ID  = this.token;
                this.soloTravelHistoryService.getSoloTravelHistoryEdit(ID).then((res) => {
                this.soloHistory = res.data;
                if (res) {
                    this.AddHistoryForm.patchValue({
                        trip_name: this.soloHistory.trip_name,
                        date: this.soloHistory.date,
                        country: this.soloHistory.country,
                        state: this.soloHistory.state,
                        city: this.soloHistory.city,
                        start_latLng: this.soloHistory.start_latLng,
                        end_latLng: this.soloHistory.end_latLng,
                        total_km: this.soloHistory.total_km,
                        description: this.soloHistory.description,
                        privacy_status: this.soloHistory.privacy_status,
                    });
                }
            });

    }
    async addHistory(formData) {
        const params = {
            'id': this.token,
            'trip_name': formData.trip_name,
            'date': formData.date,
            'country': formData.country,
            'state': formData.state,
            'city': formData.city,
            'start_latLng': formData.start_latLng,
            'end_latLng': formData.end_latLng,
            'total_km': formData.total_km,
            'description': formData.description,
            'privacy_status': formData.privacy_status
        }

        Swal.fire({
            icon: 'info',
            title: 'Travel History',
            showCancelButton: true,
            html: 'Do you want to add this travel history ?',
            confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Yes',
              confirmButtonAriaLabel: 'Yes',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i> No',
              cancelButtonAriaLabel: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                this.soloTravelHistoryService.AddHistory(params)
                        .subscribe((res: any) => {
                            this.router.navigate(['/solo/travel-history']);
                            this.messageService.add({severity: 'success', summary: 'Success', detail: res.message ? res.message : ''});
                        }, (err: any) => {
                            this.errors = true;
                            this.messageService.add({severity: 'error', summary: 'Error', detail: err.error ? err.error.error : ''})
                        });
            }
    });

    }

    getConfirmation(header: string, message: string) {
        return new Promise((resolve, reject) => {
            this.confirmationService.confirm({
                message: message,
                header: header,
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    resolve(true);
                },
                reject: () => {
                    resolve(false);
                }
            });
        });
    }
}
