import {Component, OnInit, Renderer2} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
// import {SignUpService} from './signup.service';
import {vendorRegistrationService} from './vendor_registration.service';
import {FormBuilder, Validators, FormGroup} from '@angular/forms';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {SelectItem} from 'primeng/api';
import { ValidationsService } from '../services/validations.service';

@Component({
    selector: 'app-vendor_registration',
    templateUrl: './vendor_registration.component.html',
    styleUrls: ['./vendor_registration.component.scss']
})
export class vendorRegistrationComponent implements OnInit {
    test: Date = new Date();
    dateValue: Date;
    focus;
    focus1;
    date: {year: number, month: number};
    model: NgbDateStruct;
    signUpForm: FormGroup;
    termsChecked=false;
    countryList: any=[];
    phoneCodeVal:any;
    emailPattern = /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,9})$/;
    signupUrl:boolean = ((this.router.url.split("/"))[1] == 'signup');
    constructor(private fb: FormBuilder, private auth: vendorRegistrationService,public http:HttpClient,private router: Router,private customValidate: ValidationsService,) { }

    isWeekend(date: NgbDateStruct) {
        const d = new Date(date.year, date.month - 1, date.day);
        return d.getDay() === 0 || d.getDay() === 6;
    }

    isDisabled(date: NgbDateStruct, current: {month: number}) {
        return date.month !== current.month;
    }

    getDOB(date: NgbDateStruct, current: {month: number}) {
        return date.year + '-' + date.month + '-' + date.day;
    }

    ngOnInit() {
        this.signUpForm = this.fb.group({
            name: ['', [Validators.required,Validators.minLength(5),Validators.maxLength(50)]],
            email: ['', [Validators.required,Validators.minLength(5),Validators.maxLength(50),Validators.pattern(this.emailPattern)]],
            mobile: ['', [Validators.required,Validators.minLength(10),Validators.maxLength(10)]],
            dob: ['', [Validators.required]],
            countryCode:["",[Validators.required]],
            password: ['', [Validators.required,Validators.minLength(8),Validators.maxLength(15)]],
            terms: ['', [Validators.required]],
        });
        this.getCountries();
        // const navbar = document.getElementsByTagName('app-navbar')[0].children[0];
        // navbar.classList.add('navbar-hidden');
        if(this.signupUrl){
        const footer1 = document.getElementsByTagName('app-footer')[0].children[0];
        footer1.classList.add('remove-footer-mobile');
        const footer2 = document.getElementsByTagName('footer')[0].children[0];
        footer2.classList.add('remove-footer-mobile');}

    }


    get name() {
        return this.signUpForm.controls.name;
    }

    get email() {
        return this.signUpForm.controls.email;
    }

    get mobile() {
        return this.signUpForm.controls.mobile;
    }

    get dob() {
        return this.signUpForm.controls.dob;
    }

    get password() {
        return this.signUpForm.controls.password;
    }

    get countryCode(){
        return this.signUpForm.controls.countryCode
    }
    get terms() {
        return this.signUpForm.controls.terms;
    }

    getCode(value){
        this.phoneCodeVal=value;
    }

    signUp(formData) {
        formData.dob=this.customValidate.formatDate(formData.dob);
        formData.mobile= this.phoneCodeVal+' '+formData.mobile;
        this.auth.signUp(formData).subscribe( result => {
            if (result.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    html: result.message
                }).then( function(){
                   window.location.href = '/account-verification/solo/'+result.data.user;
                }
                );
                this.signUpForm.reset();
            } else {
                this.signUpForm.reset();
                Swal.fire('Error occurred', result.message, 'error');
            }
        });
    }

    agreementEvent(event) {
        this.termsChecked= event;
       
      }
    
    getCountries(){
        this.auth.getCountryJSON().subscribe(data => {
          this.countryList = [];
          for (let i = 0; i < data.countries.length; i++) {       
            this.countryList.push({label: data.countries[i].code,value: data.countries[i].code});   
          }   
        });   
        
      }
}
