import {Component, OnInit} from '@angular/core';
import {UserProfileService} from './../user_profile.service';
import {Router, ActivatedRoute} from '@angular/router';
import { generalHelper } from 'app/helpers/generalHelper'; 
import {ConfirmationService} from 'primeng/api';
import {CommunityService} from '../../../website/community/community.service';
import { ProfileService } from '../../../pages/profile/profile.service';
import Swal from "sweetalert2";
import {environment} from "enviornments/environment"
import { imagePathHelper } from "../../../helpers/imagePathHelper";
import {HttpClient, HttpEventType} from '@angular/common/http';
@Component({
    selector: 'app-user-sidebar',
    templateUrl: './user-sidebar.component.html',
    styleUrls: ['./user-sidebar.component.scss']
})
export class UserSidebarComponent implements OnInit {
    env=environment;
    loading: boolean;
    errors: boolean;
    detail = false;
    readmorebtn = true;
    timeline:any;
    generalHelper = generalHelper;
    user:any={};
    token: any;
    CoverimagePath:any;
    ProfileimagePath:any;
    imagePath = imagePathHelper;
    Currentuser:any
    UserId:any;
    HeaderImage:any
    comment: string = '';
    uploadStatus: number;
    postsList: any = [];
    followedUsers: any = [];
    followersList: any = [];
    attachment: File = null;
    constructor(private UserProfileService: UserProfileService,private route: ActivatedRoute,private profileService: ProfileService,private CommunityService: CommunityService,private confirmationService: ConfirmationService,private router: Router) { }

    ngOnInit() {
        this.token = this.route.snapshot.params.username;
        this.getUserProfile();
    }

    switchToUserHistory(username){
        this.router.navigateByUrl(`Users/TravelHistory/${username}`);
    }

    switchToUserBlogs(username){
        this.router.navigateByUrl(`Users/Blogs/${username}`);
    }

    switchToUserRoutes(username){
        this.router.navigateByUrl(`Users/Routes/${username}`);
    }

    switchToUserFollowers(username){
        this.router.navigateByUrl(`Users/followers/${username}`);
    }
    getUserProfile(){
        this.followedUsers=[];
        this.followersList=[];
        this.UserProfileService.getUserProfile(this.token).subscribe(res => {
            this.user = res.data;
            this.followedUsers=this.user.following;
            this.followersList=this.user.following;
        },error => {this.followedUsers=[]; this.followersList=[];});
    }

    switchToUserProfile(username){
        this.router.navigateByUrl(`userprofile/${username}`);
    }
    
    switchToProfile(username){
        this.router.navigateByUrl(`userprofile/${username}`);
    }

}
