import { Injectable } from '@angular/core';
import { BaseService } from '../_services/base.service';

@Injectable()
export class Subscribe1Service {

    constructor(private baseService: BaseService) { }

    subscribeNewsletter(data){
        return this.baseService.post(`newsletter/subscribe`, data);
    }

}
