import {Component, OnInit, Renderer2} from '@angular/core';
import {SettingsService} from './settings.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import Swal from "sweetalert2";
import { AuthService } from 'app/services/auth.service';
import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import { generalHelper } from '../../../helpers/generalHelper';
import { ProfileService } from '../profile.service';
import { CookiesService } from 'app/shared/services/cookies.services';
import { Globals } from 'app/globals';
import { EncryptionService } from 'app/shared/services/encryption.services';
@Component({
    selector: 'app-solo-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
    user :any;
    generalHelper = generalHelper;
    forgotpasswordForm: FormGroup;
    AddressForm: FormGroup;
    alterEmailForm: FormGroup;
    PrivacyForm: FormGroup;
    loading: boolean;
    errors: boolean;
    display:boolean;
    addressModal:boolean;
    profiles:any;
    features:any;
    privacy:any=true;
    visibility:any;
    security:any;
    rolesauthorization:any;
    banking:any;
    addressList:any=[];
    adress:any;
    editAddress:any=[];
    editbutton:any;
    editaddressModal=false;
    isdefault:any;
    logout:any;
    eventchecked=false;
    userprivacy:any;
    emailPattern = /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,9})$/;
    constructor( private globals: Globals,private cookieService: CookiesService,private encryptionServices: EncryptionService,private profileService: ProfileService,private SettingsService: SettingsService,private fb: FormBuilder,private confirmationService: ConfirmationService, private messageService: MessageService, private router: Router,private authService: AuthService) { }

    ngOnInit() {
        this.getAddressList();
        this.user = JSON.parse(sessionStorage.getItem("Currentuser"));
        console.log('user',this.user);
        this.userprivacy = this.user.privacy;    
        this.getAddressForm();      
        this.getPostForm();      
        this.getPasswordForm();     
        this.getEmailForm(); 
        this.getPrivacy();
    }

    getAddressForm(){
        this.AddressForm = this.fb.group({
            id:[0],
            contact_name: ['', [Validators.required]],
            type: ['', [Validators.required]],
            pin_code: ['', [Validators.required]],
            contact_number: ['', [Validators.required]],
            alternate_number: ['', [Validators.required]],
            address: ['', [Validators.required]],
            landmark: ['', [Validators.required]],
            country: ['', [Validators.required]],
            state: ['', [Validators.required]],
            is_default: [false],
            
        });
    }

    getPostForm(){
        this.PrivacyForm = this.fb.group({
            profile: ['', [Validators.required]],
            video_blog: ['', [Validators.required]],
            bloggerType: ['', [Validators.required]],
            podcast: ['', [Validators.required]],
            write_up: ['', [Validators.required]],
            photography: ['', [Validators.required]],
            endorsement: ['', [Validators.required]],
            thought: ['', [Validators.required]],
            travel_history: ['', [Validators.required]],
            routes_history: ['', [Validators.required]],
            referral: ['', [Validators.required]],
            follower: ['', [Validators.required]],
            comment: ['', [Validators.required]],
            messaging: ['', [Validators.required]],
            tagging: ['', [Validators.required]],
            status: ['', [Validators.required]],
            
        });
    }

    getPasswordForm(){
        this.forgotpasswordForm = this.fb.group({
            current_password: ['', [Validators.required]],
            new_password: ['', [Validators.required, Validators.minLength(8),
                Validators.pattern('(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[$@$!%*?&])[A-Za-z0-9\d$@$!%*?&].{8,}')]],
            confirm_password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(50)]],
        },{ validator: this.matchPassword});
    }

    matchPassword(form){
        if(form.controls.new_password.value  !== form.controls.confirm_password.value){
          form.controls.confirm_password.setErrors({notEquivalent: true})
        }
        else{
          if(form.controls.new_password.valid){
            form.controls.confirm_password.setErrors(null)
          }
        }
    }

    getEmailForm(){
        this.alterEmailForm = this.fb.group({
            secondary_email: ['', [Validators.required,Validators.minLength(5),Validators.maxLength(50),Validators.pattern(this.emailPattern)]],
            
        });
    }

    get secondary_email() {
        return this.alterEmailForm.controls.secondary_email
    }
    
    get current_password() {
        return this.forgotpasswordForm.controls.current_password
    }

    get new_password() {
        return this.forgotpasswordForm.controls.new_password
    }

    get confirm_password() {
        return this.forgotpasswordForm.controls.confirm_password
    }

    get id() {
        return this.AddressForm.controls.id
    }

    get type() {
        return this.AddressForm.controls.type
    }
    get contact_name() {
        return this.AddressForm.controls.contact_name
    }
    get pin_code() {
        return this.AddressForm.controls.pin_code
    }
    get contact_number() {
        return this.AddressForm.controls.contact_number
    }
    get alternate_number() {
        return this.AddressForm.controls.alternate_number
    }
    get address() {
        return this.AddressForm.controls.address
    }
    get country() {
        return this.AddressForm.controls.country
    }
    get state() {
        return this.AddressForm.controls.state
    }
    get is_default() {
        return this.AddressForm.controls.is_default
    }
    
    get landmark() {
        return this.AddressForm.controls.landmark
    }
    
    
    get profile() {
        return this.PrivacyForm.controls.profile
    }

    get bloggerType() {
        return this.PrivacyForm.controls.bloggerType
    }
    
    get video_blog() {
        return this.PrivacyForm.controls.video_blog
    }
    
    get podcast() {
        return this.PrivacyForm.controls.podcast
    }
    
    get write_up() {
        return this.PrivacyForm.controls.write_up
    }
    
    get photography() {
        return this.PrivacyForm.controls.photography
    }
    
    get endorsement() {
        return this.PrivacyForm.controls.endorsement
    }
    
    get thought() {
        return this.PrivacyForm.controls.thought
    }
    
    get travel_history() {
        return this.PrivacyForm.controls.travel_history
    }
    
    get referral() {
        return this.PrivacyForm.controls.referral
    }
    
    get follower() {
        return this.PrivacyForm.controls.follower
    }
    
    get comment() {
        return this.PrivacyForm.controls.comment
    }
    
    get messaging() {
        return this.PrivacyForm.controls.messaging
    }
    
    get tagging() {
        return this.PrivacyForm.controls.tagging
    }
    
    get status() {
        return this.PrivacyForm.controls.status
    }

    logoutalldevice(event){
        if(event){
            this.logout="Yes";
        }else{
            this.logout="No";
        }
    }
    
    async forgotpassword(formData) {
        const params = {
            'current_password': formData.current_password,
            'new_password': formData.new_password,
            'confirm_password': formData.confirm_password,
            'logout_from_other': this.logout,
        }
            Swal.fire({
                icon: 'info',
                title: 'Change password',
                showCancelButton: true,
                html: 'Do you want to change password ?',
                confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Yes',
              confirmButtonAriaLabel: 'Yes',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i> No',
              cancelButtonAriaLabel: 'No'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.SettingsService.changePassword(params).subscribe((res: any) => {
                        if(res.success){
                            this.forgotpasswordForm.reset();
                            this.eventchecked=false;
                            Swal.fire('Password Change', res.message, 'success');
                        }else{
                            Swal.fire('error', res.message, 'error');
                            this.eventchecked=false;
                        }
                            
                        }, (err: any) => {
                            this.errors = true;
                            Swal.fire('error', err.error, 'error');
                        });
                }
        });
    }

    async addAlterEMail(formData) {
        const params = {
            'secondary_email': formData.secondary_email,
        }
            Swal.fire({
                icon: 'info',
                title: 'Alternate Email',
                showCancelButton: true,
                html: 'Do you want to add alternate email ?',
                confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Yes',
              confirmButtonAriaLabel: 'Yes',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i> No',
              cancelButtonAriaLabel: 'No'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.SettingsService.alterEmail(params).subscribe((res: any) => {
                        if(res.success){
                            Swal.fire('Alternate email', res.message, 'success');
                        }else{
                            Swal.fire('error', res.message, 'error');
                        }
                            
                        }, (err: any) => {
                            this.errors = true;
                            Swal.fire('error', err.error, 'error');
                        });
                }
        });
    }

    async PrivacyVisibility(formData) {
        const params = {
            'profile': formData.profile,
            'bloggerType': formData.bloggerType,
            'video_blog': formData.video_blog,
            'podcast': formData.podcast,
            'write_up': formData.write_up,
            'photography': formData.photography,
            'endorsement': formData.endorsement,
            'thought': formData.thought,
            'travel_history': formData.travel_history,
            'routes_history': formData.routes_history,
            'referral': formData.referral,
            'follower': formData.follower,
            'comment': formData.comment,
            'messaging': formData.messaging,
            'tagging': formData.tagging,
            'status': formData.status,
        }
        Swal.fire({
            icon: 'warning',
            title: 'Update Privacy',
            showCancelButton: true,
            html: 'Do you want to update privacy ?',
            confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Yes',
              confirmButtonAriaLabel: 'Yes',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i> No',
              cancelButtonAriaLabel: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                this.SettingsService.PrivacyVisibility(params).subscribe((res: any) => {
                    Swal.fire('Success','Privacy Updated','success').then(function() {
                        // this.router.navigate(['/solo/profile/dashboard']);
                       // window.location.reload();
                       
                    });
                    this.getUserProfile();
                    
                }, (err: any) => {
                    this.errors = true;
                    this.messageService.add({severity: 'error', summary: 'Error', detail: err.error ? err.error.error : ""})
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: err.error ? err.error.error : ""
                    }) ;
                });
            }
    });

    }

    getUserProfile() {
        
        this.profileService.profile().subscribe(res => {
            this.cookieService.set(this.globals.tw_user_cookie, this.encryptionServices.encrypt(JSON.stringify(res)));
            localStorage.removeItem('Currentuser');
            localStorage.removeItem('endorsedBrands');
            sessionStorage.setItem("Currentuser", JSON.stringify(res));
            sessionStorage.setItem("endorsedBrands", JSON.stringify(res.endorsedBrands));
           this.user = JSON.parse(sessionStorage.getItem("Currentuser"));
            this.userprivacy = this.user.privacy;
            this.getPrivacy();
            
        },error => {});
        
    }

    getConfirmation(header: string, message: string) {
        return new Promise((resolve, reject) => {
            this.confirmationService.confirm({
                message: message,
                header: header,
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    resolve(true);
                },
                reject: () => {
                    resolve(false);
                }
            });
        });
    }

    showDialog() {
        
        }
    tabcontent(val) {
        if(val=='profiles'){
            this.profiles=true;
            this.features=false;
            this.privacy=false;
            this.visibility=false;
            this.security=false;
            this.rolesauthorization=false;
            this.banking=false;
            this.adress=false;
        }
        if(val=='features'){
            this.profiles=false;
            this.features=true;
            this.privacy=false;
            this.visibility=false;
            this.security=false;
            this.rolesauthorization=false;
            this.banking=false;
            this.adress=false;
        }
        if(val=='privacy'){
            this.profiles=false;
            this.features=false;
            this.privacy=true;
            this.visibility=false;
            this.security=false;
            this.rolesauthorization=false;
            this.banking=false;
            this.adress=false;
        }
        if(val=='visibility'){
            this.profiles=false;
            this.features=false;
            this.privacy=false;
            this.visibility=true;
            this.security=false;
            this.rolesauthorization=false;
            this.banking=false;
            this.adress=false;
        }
        if(val=='security'){
            this.profiles=false;
            this.features=false;
            this.privacy=false;
            this.visibility=false;
            this.security=true;
            this.rolesauthorization=false;
            this.banking=false;
            this.adress=false;
        }
        if(val=='rolesauthorization'){
            this.profiles=false;
            this.features=false;
            this.privacy=false;
            this.visibility=false;
            this.security=false;
            this.rolesauthorization=true;
            this.banking=false;
            this.adress=false;
        }
        if(val=='banking'){
            this.profiles=false;
            this.features=false;
            this.privacy=false;
            this.visibility=false;
            this.security=false;
            this.rolesauthorization=false;
            this.banking=true;
            this.adress=false;
        }
        if(val=='adress'){
            this.profiles=false;
            this.features=false;
            this.privacy=false;
            this.visibility=false;
            this.security=false;
            this.rolesauthorization=false;
            this.banking=false;
            this.adress=true;
        }
    }
    showDialog10() {
        this.display = true;
    }

    showDialogAddress(type) {
        
        if(type=='edit'){
            this.addressModal = true;
        }else{
            this.addressModal = true;
            this.editAddress.id=0;
            this.AddressForm.reset();
        }
            
    }
      
    getAddressList() {
        this.SettingsService.addressList().subscribe(res => {
            this.addressList = res;
        },error => {});
    }
    
    getRoutesPatch(i) {
            this.editAddress = this.addressList[i];
            if(this.editAddress){
                this.AddressForm.patchValue({
                    id: this.editAddress.id,
                    type: this.editAddress.type,
                    contact_name: this.editAddress.contact_name,
                    pin_code: this.editAddress.pin_code,
                    contact_number: this.editAddress.contact_number,
                    alternate_number: this.editAddress.alternate_number,
                    address: this.editAddress.address,
                    landmark: this.editAddress.landmark,
                    country: this.editAddress.country,
                });
                this.isdefault=this.addressList[i].is_default
            }
            this.showDialogAddress('edit');
    }

    async setdefault(i) {
        this.editAddress = this.addressList[i];
        const params = {
            'id':this.addressList[i].id,
            'type': this.addressList[i].type,
            'contact_name': this.addressList[i].contact_name,
            'pin_code': this.addressList[i].pin_code,
            'contact_number': this.addressList[i].contact_number,
            'alternate_number': this.addressList[i].alternate_number,
            'address': this.addressList[i].address,
            'landmark': this.addressList[i].landmark,
            'country': this.addressList[i].country,
            'state': this.addressList[i].state,
            'is_default': 'Yes',
        }
        Swal.fire({
            icon: 'info',
            title: 'Add Address',
            showCancelButton: true,
            html: 'Do you want to add this address ?',
            confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Yes',
              confirmButtonAriaLabel: 'Yes',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i> No',
              cancelButtonAriaLabel: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                this.SettingsService.addAddress(params)
                .subscribe((res: any) => {
                    this.AddressForm.reset();
                    if(res.success){
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: res.message
                        })
                        this.addressModal = false;
                        this.editbutton=false;
                        this.getAddressList();
                    }else{
                        this.errors = true;
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: res.message
                        }) 
                    }
                    
                }, (err: any) => {
                    this.errors = true;
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: err
                    })
                });
            }
    });
}

    async AddressSubmit(formData) {
        const params = {
            'id':formData.id,
            'type': formData.type,
            'contact_name': formData.contact_name,
            'pin_code': formData.pin_code,
            'contact_number': formData.contact_number,
            'alternate_number': formData.alternate_number,
            'address': formData.address,
            'landmark': formData.landmark,
            'country': formData.country,
            'state': formData.state,
            'is_default': formData.is_default?'Yes':'No',
        }

        Swal.fire({
            icon: 'info',
            title: 'Add Address',
            showCancelButton: true,
            html: 'Do you want to add this address ?',
            confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Yes',
              confirmButtonAriaLabel: 'Yes',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i> No',
              cancelButtonAriaLabel: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                this.SettingsService.addAddress(params)
                .subscribe((res: any) => {
                    this.AddressForm.reset();
                    if(res.success){
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: res.message
                        })
                        this.addressModal = false;
                        this.editbutton=false;
                        this.getAddressList();
                    }else{
                        this.errors = true;
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: res.message
                        }) 
                    }
                    
                }, (err: any) => {
                    this.errors = true;
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: err
                    })
                });
            }
    });

    }

    deleteAddress(id) {

        this.confirmationService.confirm({
            message: 'Do you want to delete this history',
            header: 'Delete Route History Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                return this.SettingsService.deleteAddress(id).subscribe((res) => {
                    if (res.success) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: res.message
                        })
                        this.getAddressList();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: res.message
                        }) 
                        this.getAddressList();
                    }
                }), err => {
                    this.getAddressList();
                };

            },
            reject: () => {}
        });

    }

    getPrivacy(){
        this.PrivacyForm.patchValue({
            profile : this.userprivacy.profile,
            bloggerType : this.userprivacy.bloggerType,
            video_blog : this.userprivacy.video_blog,
            write_up : this.userprivacy.write_up,
            podcast : this.userprivacy.podcast,
            photography : this.userprivacy.photography,
            endorsement : this.userprivacy.endorsement,
            thought : this.userprivacy.thought,
            travel_history : this.userprivacy.travel_history,
            routes_history : this.userprivacy.routes_history,
            referral : this.userprivacy.referral,
            follower : this.userprivacy.follower,
            comment : this.userprivacy.comment,
            messaging : this.userprivacy.messaging,
            tagging : this.userprivacy.tagging,
            status : this.userprivacy.status,
        });
    }
    
}
