import { Injectable } from '@angular/core';
import { BaseService } from '../../../_services/base.service';

@Injectable()
export class GroupSidebarService {

    constructor(private baseService:BaseService) { }
    groupData:any;
    profile(){
        return this.baseService.get(`user/profile`);
    }

    uploadProfileImage(image) {
        return this.baseService.post(`user/update/profile-image`, image);
    }

    updateThoughtDay(post) {
        return this.baseService.post(`user/update/daily-thought`, post);
    }

    ownGroupList(){
        return this.baseService.get(`group/type/own`);
    }

    setGroupData(data){
        this.groupData=data;

    }
}
