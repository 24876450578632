import {Component, OnInit, Renderer2} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {NewsService} from './news.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {HttpClient} from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'enviornments/environment';
@Component({
    selector: 'app-websiteNewsPage',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
    env=environment
    user : any;
    imageThumb:any;
    loading: boolean;
    errors: boolean;
    options: any;
    city:any;
    state:any;
    country:any;
    totalkm:any;
    description:any;
    tripname:any;
    overlays: any[];
    display: boolean = false;
    NewsList: any = [];
    contentLoader: boolean = false;
    constructor(private fb: FormBuilder,private router: Router, private NewsService: NewsService,public http:HttpClient) { }

    

    ngOnInit() {
        
        this.getNewsList();
    }

    getNewsList() {
        this.NewsService.list().subscribe(res => {
            if (res.success) {
                this.NewsList = res.data;
            }
        },error => {
            this.NewsList = [];
        });
        
    }

    NewsDetails(id){
        this.router.navigateByUrl(`news-detail/${id}`);
    }
    
}
