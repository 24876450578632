import {Component, OnInit, Renderer2} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {TraveloguesService} from './travelogues.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {HttpClient} from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'enviornments/environment';
@Component({
    selector: 'app-websitetravelogues',
    templateUrl: './travelogues.component.html',
    styleUrls: ['./travelogues.component.scss']
})
export class TraveloguesComponent implements OnInit {
    env=environment;
    user : any;
    soloHistory: any = [];
    loading: boolean;
    errors: boolean;
    options: any;
    city:any;
    state:any;
    country:any;
    totalkm:any;
    description:any;
    tripname:any;
    overlays: any[];
    display: boolean = false;
    blogs: any = [];
    contentLoader: boolean = false;
    constructor(private fb: FormBuilder,private router: Router, private blogService: TraveloguesService,public http:HttpClient) { }

    

    ngOnInit() {
        this.getBlogs();

    }

    getBlogs() {
        this.blogService.list().subscribe(res => {
            if (res.success) this.blogs = res.data;
        },error => {
            this.blogs = [];
        });
    }

    blogDetails(id){
        this.router.navigateByUrl(`travelogues-detail/${id}`);
    }
    
}
