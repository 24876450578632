import { Injectable } from '@angular/core';
import { BaseService } from '../../../_services/base.service';

@Injectable()
export class SettingsService {

    constructor(private baseService: BaseService) { }

    signUp(data){
        return this.baseService.post(`auth/register`, data);
    }

    changePassword(params) {
        return this.baseService.post(`user/update/password`, params);
    }

    alterEmail(params) {
        return this.baseService.post(`user/update/alternate-email`, params);
    }

    addAddress(params) {
        return this.baseService.post(`user/addresses/addUpdate`, params);
    }

    PrivacyVisibility(params) {
        return this.baseService.post(`user/setting/privacy`, params);
    }

    addressList(){
        return this.baseService.get(`user/addresses/list`);
    }

    deleteAddress(id) {
        return this.baseService.delete(`user/addresses/delete/${id}`);
    }

}
