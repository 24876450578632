import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { SignUpService } from '../signup.service';
import {Router} from '@angular/router';
import Swal from "sweetalert2";
import { ValidationsService } from '../../services/validations.service';
import { Globals } from 'app/globals';
import { EncryptionService } from 'app/shared/services/encryption.services';
import { CookiesService } from 'app/shared/services/cookies.services';

@Component({
    selector: 'app-otpscreen',
    templateUrl: './otpscreen.component.html',
    styleUrls: ['./otpscreen.component.scss']
})
export class OtpScreenComponent implements OnInit {
    
    OtpScreenForm: FormGroup;
    mobileOtp=true;
    emailOtp=true;
    constructor(private Service: SignUpService, private fb: FormBuilder, private router: Router,private customValidate: ValidationsService,private cookieService: CookiesService, private encryptionServices: EncryptionService, private globals: Globals) { }

    ngOnInit() {
        this.OtpScreenForm = this.fb.group({
            email_code: ['', [Validators.required,Validators.minLength(6),Validators.maxLength(6)]],
            mobile_code: ['', [Validators.required,Validators.minLength(6),Validators.maxLength(6)]],
        });
        
    }

    get email_code() {
        return this.OtpScreenForm.controls.email_code;
    }

    get mobile_code() {
        return this.OtpScreenForm.controls.mobile_code;
    }

    SubmitOtp(formData) {
        this.Service.SubmitOtp(formData).subscribe( result => {
            console.log('data======',result.data)
            if (result.success) {
                //this.handleCookie(result.data.access_token,result.data);
                
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    html: result.message
                }).then( function(){
                    sessionStorage.removeItem('mobile');
                    sessionStorage.removeItem('email');
                    //window.location.href = '/solo/profile/setup/'+result.data.username;
                    window.location.href = '/login'
                }
                );
            } else {
              //  this.OtpScreenForm.reset();
                Swal.fire('Error occurred', result.message, 'error');
            }
        });
    }

    handleCookie(generateToken,user) {
        if (this.cookieService.check(this.globals.tw_cookie)) {
            this.cookieService.delete(this.globals.tw_cookie, this.globals.hostname);
            this.cookieService.delete(this.globals.tw_user_cookie, this.globals.hostname);
            localStorage.removeItem('currentUserID');
        }
        

        if (this.cookieService.set(this.globals.tw_cookie, this.encryptionServices.encrypt(generateToken))) {
            
            if (this.cookieService.check(this.globals.tw_cookie)) {
                this.cookieService.set(this.globals.tw_user_cookie, this.encryptionServices.encrypt(JSON.stringify(user)))
                
                localStorage.setItem("currentUserID",JSON.stringify(user.uuid));
            }
        }
    }

    resendOtp(type){
        (type == 'mobile') ? this.mobileOtp=false : this.emailOtp=false;
        const params = {
            'attempt' : '0',
            'type' : type,
            'value' : sessionStorage.getItem(type)
        }
        this.Service.resendOtp(params).subscribe((res: any) => {
            if (res.success) {
                Swal.fire('Ok', res.message, 'success');
            }
        })
        setTimeout(() => {
            (type == 'mobile') ? this.mobileOtp=true : this.emailOtp=true;
        }, 30000);
    }
}
