import {Component, OnInit, Renderer2} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {WebsiteService} from './website.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'app-website',
    templateUrl: './website.component.html',
    styleUrls: ['./website.component.scss']
})
export class WebsiteComponent implements OnInit {
    test: Date = new Date();
    focus;
    focus1;
    
    constructor(private fb: FormBuilder, private auth: WebsiteService,public http:HttpClient) { }

    

    ngOnInit() {
        

    }


    
}
