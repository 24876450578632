import { Component, OnInit } from '@angular/core';
import { SoloRoutesTravelHistoryService } from '../solo_routes_travelhistory.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import Swal from "sweetalert2";
@Component({
    selector: 'app-soloaddroutes',
    templateUrl: './solo_routes_add.component.html',
    styleUrls: ['./solo_routes_add.component.scss']
})

export class SoloRoutesAddComponent implements OnInit {
    user: any = {};
    loading: boolean;
    errors: boolean;
    display:boolean;
    display1:boolean;
    AddRouteForm: FormGroup;
    options: any;
    datetime: Date;
    // tslint:disable-next-line:no-shadowed-variable
    constructor(private SoloRoutesTravelHistoryService: SoloRoutesTravelHistoryService, private confirmationService: ConfirmationService, private messageService: MessageService, private fb: FormBuilder, private router: Router) { }

    ngOnInit() {
        this.AddRouteForm = this.fb.group({
            date_time: ['', [Validators.required]],
            country: ['', [Validators.required]],
            city: ['', [Validators.required]],
            state: ['', [Validators.required]],
            start_latLng: ['', [Validators.required]],
            end_latLng: ['', [Validators.required]],
            total_km: ['', [Validators.required]],
            description: ['', [Validators.required]],
            privacy_status: ['', [Validators.required]],
        });
        this.options = {
            center: {lat: 36.890257, lng: 30.707417},
            zoom: 12

        };
    }
    showDialog() {
        this.display = true;
        this.display1 = true;
    }

    addRoutePage(){
        this.router.navigate(['solo/routes/add'])
    }
    async addRoute(formData) {
        const params = {
            'id': 0,
            // 'date_time': formData.date_time,
            'date_time': '2021-09-26 00:00',
            'country': formData.country,
            'state': formData.state,
            'city': formData.city,
            'start_latLng': formData.start_latLng,
            'end_latLng': formData.end_latLng,
            'total_km': formData.total_km,
            'description': formData.description,
            'privacy_status': formData.privacy_status
        }
        Swal.fire({
            icon: 'info',
            title: 'Add Route',
            showCancelButton: true,
            html: 'DDo you want to add this route ?',
            confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Yes',
              confirmButtonAriaLabel: 'Yes',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i> No',
              cancelButtonAriaLabel: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
            this.SoloRoutesTravelHistoryService.addRoute(params)
                .subscribe((res: any) => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: res.message
                    });
                    this.router.navigate(['solo/routes']);
                }, (err: any) => {
                    this.errors = true;
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: err.message
                    })
                });
            }
    });

    }

    getConfirmation(header: string, message: string) {
        return new Promise((resolve, reject) => {
            this.confirmationService.confirm({
                message: message,
                header: header,
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    resolve(true);
                },
                reject: () => {
                    resolve(false);
                }
            });
        });
    }
}
