import { Directive, ElementRef, HostListener } from '@angular/core';
import {MessageService} from 'primeng/api';

@Directive({
 selector: '[AlphabetsOnly]'
})

export class AlphabetsOnlyDirective { 
    // Allow aplhabets values
    private regex: RegExp = new RegExp(/^([a-zA-Z_-]*)$/gm);
    // Allow key codes for special events. Reflect :
    // Backspace, tab, end, home
    private specialKeys: Array<string> = [ 'Backspace', 'Tab', 'End', 'Home' ];
   
   constructor(private el: ElementRef, private messageService:MessageService) {}
    @HostListener('keydown', [ '$event' ])
    onKeyDown(event: KeyboardEvent) {
        // Allow Backspace, tab, end, and home keys
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        let current: string = this.el.nativeElement.value;
        let next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }

    @HostListener('paste', ['$event']) 
    blockPaste(event: ClipboardEvent) {
        let clipboardData = event.clipboardData;
        let pastedText = clipboardData.getData('text');
        if (!String(pastedText).match(this.regex)) {
            this.messageService.add({severity:'error', summary: 'Error', detail:"Only alphabets and special characters: _-  are allowed."});
            event.preventDefault();
        }       
    }
}