import { Injectable } from '@angular/core';
import { BaseService } from '../../_services/base.service';

@Injectable()
export class SidebarService {

    constructor(private baseService:BaseService) { }

    profile(){
        return this.baseService.get(`user/profile`);
    }

    uploadProfileImage(image) {
        return this.baseService.post(`user/update/profile-image`, image);
    }

    updateThoughtDay(post) {
        return this.baseService.post(`user/update/daily-thought`, post);
    }
}
