import { Injectable } from '@angular/core';
import { BaseService } from '../../_services/base.service';

@Injectable()
export class HomeService {

    constructor(private baseService: BaseService) { }


    list() {
        return this.baseService.getRequest(`dashboard`);
    }

    Newslist() {
        return this.baseService.getRequest(`news`);
    }


    sliders() {
        return this.baseService.getRequest(`ads/Home`);
    }
}
