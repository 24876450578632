import { Component, OnInit, ElementRef, ViewChild,Renderer2 } from '@angular/core';
import { ProfileService } from './profile.service';
import {Router} from '@angular/router';
import {HttpEventType, HttpClient, HttpHeaders} from "@angular/common/http";
import Swal from "sweetalert2";
import { AddBrandService } from '../brand/add_brand/add_brand.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { AuthService } from 'app/services/auth.service';
import { Globals } from 'app/globals';
import { CookiesService } from 'app/shared/services/cookies.services';
import { EncryptionService } from 'app/shared/services/encryption.services';
import { generalHelper } from 'app/helpers/generalHelper';
import { environment } from 'enviornments/environment';
@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {
    env=environment;
    generalHelper=generalHelper;
    user :any;
    Currentuser:any={};
    endorsedBrands:any=[];
    loading: boolean;
    errors: boolean;
    imgFile: File = null;
    endoresd:any=[];
    CoverFile: File = null;
    uploadStatus: number = 0;
    page = 4;
    page1 = 5;
    focus;
    focus1;
    focus2;
    date: {year: number, month: number};
    model: NgbDateStruct;
    imageChangedEvent: any = "";
    croppedImage: any = "";
    canvasRotation = 0;
    rotation = 0;
    scale = 1;
    showCropper = false;
    containWithinAspectRatio = false;
    transform: ImageTransform = {};
    ProfileImageCropper=false;

    CoverimageChangedEvent: any = "";
    CovercroppedImage: any = "";
    CovercanvasRotation = 0;
    Coverrotation = 0;
    Coverscale = 1;
    CovershowCropper = false;
    CovercontainWithinAspectRatio = false;
    Covertransform: ImageTransform = {};
    CoverImageCropper=false;
    contentLoader:boolean=false;
    contentLoaderNew:boolean=false;
    zoomimage:boolean=false;
    userInfoImage:any='';
    editProfileUrl:boolean = (((this.router.url.split("/"))[2] == 'profile') && ((this.router.url.split("/"))[3] == 'edit'));
    CoverTextUpload='Upload Cover Image';
    ProfileTextUpload='Upload Cover Image';

    profileStep:boolean=false;
    CoverStep:boolean=false;
    constructor(private cookieService: CookiesService, private encryptionServices: EncryptionService, private globals: Globals,private profileService: ProfileService, private router: Router,public http:HttpClient,private BrandService: AddBrandService,private renderer : Renderer2,private authService: AuthService) { }
    isWeekend(date: NgbDateStruct) {
        const d = new Date(date.year, date.month - 1, date.day);
        return d.getDay() === 0 || d.getDay() === 6;
    }

    isDisabled(date: NgbDateStruct, current: {month: number}) {
        return date.month !== current.month;
    }
    ngOnInit() {
        if(this.editProfileUrl) {
            this.profileStep=true;
            
        }
        this.user = this.authService.getLoggedInUser();
        let input_group_focus = document.getElementsByClassName('form-control');
        let input_group = document.getElementsByClassName('input-group');
        for (let i = 0; i < input_group.length; i++) {
            input_group[i].children[0].addEventListener('focus', function (){
                input_group[i].classList.add('input-group-focus');
            });
            input_group[i].children[0].addEventListener('blur', function (){
                input_group[i].classList.remove('input-group-focus');
            });
        }
        if(((this.router.url.split("/"))[2] == 'profile') || ((this.router.url.split("/"))[3] == 'edit')){
            this.getUserProfile();
        }
        if(!sessionStorage.getItem("Currentuser") || !sessionStorage.getItem("endorsedBrands")){
            this.getUserProfile();
        }
        this.getbrandUser();
        this.scrollTo();
        
    }

    profileSkip(){
        this.profileStep=false;
        this.CoverStep=true;
    }

    coverSkip(){
        this.CoverStep=false;
    }

    imageZomming(user){
        console.log('hhhhhhhhhhhhhh',user)
        this.userInfoImage='';
        this.zoomimage=true;
        this.userInfoImage=user;
    
    }
    

    getUserProfile() {
        this.contentLoaderNew=true;
        this.profileService.profile().subscribe(res => {
            this.cookieService.set(this.globals.tw_user_cookie, this.encryptionServices.encrypt(JSON.stringify(res)));
            sessionStorage.removeItem('Currentuser');
            sessionStorage.removeItem('endorsedBrands');
            sessionStorage.setItem("Currentuser", JSON.stringify(res));
            sessionStorage.setItem("endorsedBrands", JSON.stringify(res.endorsedBrands));
           this.Currentuser = JSON.parse(sessionStorage.getItem("Currentuser"));
           console.log("Currentuser",this.Currentuser);
            this.contentLoaderNew=false;;
        },error => {});
    }
    // getUserProfile() {
    //     this.profileService.profile().subscribe(res => {
    //         this.Currentuser = res;
    //     },error => {});
    // }

    getbrandUser(){
        if(sessionStorage.getItem("Currentuser") || sessionStorage.getItem("endorsedBrands")){
            this.endorsedBrands=[];
            this.Currentuser = JSON.parse(sessionStorage.getItem("Currentuser"));
            this.endorsedBrands = JSON.parse(sessionStorage.getItem("endorsedBrands"));
        }else{
           this.getUserProfile();
        }
    }

    onImageChange(e) {
        const formData = new FormData();
        if (e.target.files && e.target.files.length) {
            this.imgFile = <File>e.target.files[0];
            formData.append('profileImage', this.imgFile, this.imgFile.name);
            this.profileService.uploadProfileImage(formData).subscribe(event => {
               // this.getUserProfile();
                if (event.type === HttpEventType.UploadProgress){
                    this.uploadStatus = Math.round(event.loaded / event.total * 100)
                } else if (event.type === HttpEventType.Response){
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: event.body.message
                    })
                }
                //this.getUserProfile();
            });
        }
    }
    
    onCoverChange(e) {
        const formData = new FormData();
        if (e.target.files && e.target.files.length) {
            this.CoverFile = <File>e.target.files[0];
            formData.append('coverImage', this.CoverFile, this.CoverFile.name);
            this.profileService.uploadProfileCoverImage(formData).subscribe(event => {
               // this.getUserProfile();
                if (event.type === HttpEventType.UploadProgress){
                    this.uploadStatus = Math.round(event.loaded / event.total * 100)
                } else if (event.type === HttpEventType.Response){
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: event.body.message
                    })
                   // this.getUserProfile();
                }
            });
        }
    }
    GoPost(){
        this.router.navigate(['solo/post/create'])
    }
    GoTravelHistory(){
        this.router.navigate(['solo/travel-history/create'])
    }
    GoNews(){
        this.router.navigate(['solo/news/add'])
    }

    getEndoresd() {
        this.BrandService.getEndoresd().toPromise().then(res => {
            this.endoresd = res.data.filter(el=> el.show_on_profile=='yes');
        }).catch(e => {});
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

    imageLoaded() {
        this.showCropper = true;
    }

    cropperReady(sourceImageDimensions: Dimensions) {
    }

    loadImageFailed() {
    }

    rotateLeft() {
        this.canvasRotation--;
        this.flipAfterRotate();
    }

    rotateRight() {
        this.canvasRotation++;
        this.flipAfterRotate();
    }

    private flipAfterRotate() {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = {
            ...this.transform,
            flipH: flippedV,
            flipV: flippedH
        };
    }


    flipHorizontal() {
        this.transform = {
            ...this.transform,
            flipH: !this.transform.flipH
        };
    }

    flipVertical() {
        this.transform = {
            ...this.transform,
            flipV: !this.transform.flipV
        };
    }

    resetImage() {
        this.scale = 1;
        this.rotation = 0;
        this.canvasRotation = 0;
        this.transform = {};
    }

    zoomOut() {
        this.scale -= .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    zoomIn() {
        this.scale += .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    toggleContainWithinAspectRatio() {
        this.containWithinAspectRatio = !this.containWithinAspectRatio;
    }

    updateRotation() {
        this.transform = {
            ...this.transform,
            rotate: this.rotation
        };
    }

    uploadImage(){
        this.contentLoader=true;
        this.profileService.uploadProfileImageBase64({profileImage: this.croppedImage}).subscribe(res => {
            if (res.success){
                this.getUserProfile();
                Swal.fire('Success','Image Uploaded Successfully','success').then(function() {
                    window.location.reload();
                });
                this.ProfileImageCropper=false;
                this.contentLoader=false;
            } else {
                Swal.fire('Error','Unable to Save Image','error');
                this.ProfileImageCropper=false;
                this.contentLoader=false;
            }
        });
    }

    cancelImage(){
        this.croppedImage='';
        this.imageChangedEvent='';
        this.ProfileImageCropper=false;
    }
    
    profileImageCrop(){
        this.ProfileImageCropper=true;
        if(this.Currentuser.profile_picture){
            this.ProfileTextUpload='Change Profile Image';
        }else{
            this.ProfileTextUpload='Upload Profile Image';
        }
    }


    CoverfileChangeEvent(event: any): void {
        this.CoverimageChangedEvent = event;
    }

    CoverimageCropped(event: ImageCroppedEvent) {
        this.CovercroppedImage = event.base64;
    }

    CoverimageLoaded() {
        this.CovershowCropper = true;
    }

    CovercropperReady(sourceImageDimensions: Dimensions) {
    }

    CoverloadImageFailed() {
    }

    CoverrotateLeft() {
        this.CovercanvasRotation--;
        this.CoverflipAfterRotate();
    }

    CoverrotateRight() {
        this.CovercanvasRotation++;
        this.CoverflipAfterRotate();
    }

    private CoverflipAfterRotate() {
        const flippedH = this.Covertransform.flipH;
        const flippedV = this.Covertransform.flipV;
        this.Covertransform = {
            ...this.Covertransform,
            flipH: flippedV,
            flipV: flippedH
        };
    }


    CoverflipHorizontal() {
        this.Covertransform = {
            ...this.Covertransform,
            flipH: !this.Covertransform.flipH
        };
    }

    CoverflipVertical() {
        this.Covertransform = {
            ...this.Covertransform,
            flipV: !this.Covertransform.flipV
        };
    }

    CoverresetImage() {
        this.Coverscale = 1;
        this.Coverrotation = 0;
        this.CovercanvasRotation = 0;
        this.Covertransform = {};
    }

    CoverzoomOut() {
        this.Coverscale -= .1;
        this.Covertransform = {
            ...this.Covertransform,
            scale: this.Coverscale
        };
    }

    CoverzoomIn() {
        this.Coverscale += .1;
        this.Covertransform = {
            ...this.Covertransform,
            scale: this.Coverscale
        };
    }

    CovertoggleContainWithinAspectRatio() {
        this.CovercontainWithinAspectRatio = !this.CovercontainWithinAspectRatio;
    }

    CoverupdateRotation() {
        this.Covertransform = {
            ...this.Covertransform,
            rotate: this.Coverrotation
        };
    }

    CoveruploadImage(){
        this.contentLoader=true;
        // console.log(this.CovercroppedImage);
        this.profileService.uploadProfileCoverImageBase64({coverImage: this.CovercroppedImage}).subscribe(res => {
            if (res.success){
                this.getUserProfile();
                Swal.fire('Success','Image Uploaded Successfully','success').then(function() {
                    window.location.reload();
                });
                
                this.CoverImageCropper=false;
                this.contentLoader=false;
                this.CovercroppedImage='';
            } else {
                Swal.fire('Error','Unable to Save Image','error');
               // this.getUserProfile();
                this.CoverImageCropper=false;
                this.CovercroppedImage="";
                this.contentLoader=false;
            }
        });
    }

    CovercancelImage(){
        this.CovercroppedImage='';
        this.CoverimageChangedEvent='';
        this.CoverImageCropper=false;
        
    }
    
    CoverprofileImageCrop(){
        this.CoverImageCropper=true;
        if(this.Currentuser.header_image){
            this.CoverTextUpload='Change Cover Image';
        }else{
            this.CoverTextUpload='Upload Cover Image';
        }        
    }

    scrollTo():void {
        const elementList = document.querySelectorAll('.profileScroll');
        if(elementList.length){
          const element = elementList[0] as HTMLElement;
          element.scrollIntoView({ behavior: 'smooth' });
        }
    }
}
