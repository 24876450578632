import {Component, OnInit} from '@angular/core';
import {UserProfileService} from './user_profile.service';
import {Router, ActivatedRoute} from '@angular/router';
import { generalHelper } from 'app/helpers/generalHelper'; 
import {ConfirmationService} from 'primeng/api';
import {CommunityService} from '../../website/community/community.service';
import { ProfileService } from '../../pages/profile/profile.service';
import Swal from "sweetalert2";
import {environment} from "enviornments/environment"
import {HttpClient, HttpEventType} from '@angular/common/http';
import { AuthService } from 'app/services/auth.service';
@Component({
    selector: 'app-userProfile',
    templateUrl: './user_profile.component.html',
    styleUrls: ['./user_profile.component.scss']
})
export class UserProfileComponent implements OnInit {
    env=environment;
    loading: boolean;
    errors: boolean;
    detail = false;
    readmorebtn = true;
    timeline:any;
    generalHelper = generalHelper;
    user:any={};
    token: any;
    CoverimagePath:any;
    ProfileimagePath:any;
    Currentuser:any
    UserId:any;
    HeaderImage:any
    comment: string = '';
    uploadStatus: number;
    postsList: any = [];
    attachment: File = null;
    zoomimage:boolean=false;
    PostInfoImage:any=[];
    Taguser: any;
    postIndex:any;
    constructor(private UserProfileService: UserProfileService,private route: ActivatedRoute,private profileService: ProfileService,private CommunityService: CommunityService,private confirmationService: ConfirmationService,private router: Router,private authService: AuthService) { }

    ngOnInit() {
        this.token = this.route.snapshot.params.username;
        this.getUserProfile();
    }

    switchToUserHistory(username){
        this.router.navigateByUrl(`Users/TravelHistory/${username}`);
    }

    switchToUserRoutes(username){
        this.router.navigateByUrl(`Users/Routes/${username}`);
    }

    // switchToUserFollowers(username){
    //     this.router.navigateByUrl(`userprofile/${username}`);
    // }

    showMoreOption(post,value){
        return post.showMore=value;
    }

    getUserProfile(){
        this.UserProfileService.getUserProfile(this.token).subscribe(res => {
            this.user = res.data;
            this.UserId=this.user.id;
            this.HeaderImage=this.user.header_image;
            this.CoverimagePath=environment.imageURL+'images/'+this.UserId+'/cover/'+this.HeaderImage;
            this.ProfileimagePath=environment.imageURL+'images/'+this.UserId+'/profile/'+this.user.profile_picture;
           // this.postsList=this.user.posts;
           if(this.user.posts.length){
            for (let post of this.user.posts) {
                if(post.attachments.length){
                    post.imageData = [];
                    post.docData = [];
                    post.videoData = [];
                   
                    for (let attachment of post.attachments){
                        if(attachment.type=='Image'){
                            let thumb = attachment.attachment.replace('public',this.env.imageURL);
                            post.imageData.push({image:thumb, thumbImage:thumb});
                            console.log('imagedata', post.imageData)
                        }
                        if(attachment.type=='Video'){
                            let thumb = attachment.attachment.replace('public',this.env.imageURL);
                            post.videoData.push({image:thumb, thumbImage:thumb});
                            console.log('video', post.videoData)
                        }
                        if(attachment.type=='Document'){
                            let thumb = attachment.attachment.replace('public',this.env.imageURL);
                            post.docData.push({image:thumb, thumbImage:thumb});
                            console.log('imagedata', post.docData)
                        }
                        
                    }
                }
                this.postsList.push(post);
            }
            if(this.zoomimage){ 
                console.log('his.mypostsList[this.postIndex]',this.postsList[this.postIndex])
                this.PostInfoImage=[];
                this.PostInfoImage.push(this.postsList[this.postIndex]);
            }
        }
        },error => {});
    }

    getUserCurrentProfile() {
        this.profileService.profile().subscribe(res => {
            this.Currentuser = res.id;
        },error => {});
    }
    async requestFollow(ID) {
        const params = {
            'to_user': ID,
            'from_user':this.Currentuser
        }

        Swal.fire({
            icon: 'info',
            title: 'Send Request',
            showCancelButton: true,
            html: 'Do you want to send your request ?',
            confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Yes',
              confirmButtonAriaLabel: 'Yes',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i> No',
              cancelButtonAriaLabel: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                this.CommunityService.requestFollow(params).subscribe((res: any) => {
                    this.getUserProfile();
                    if(res.success){
                        this.getUserProfile();
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: res.message
                        });
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: res.message
                        });
                    }
                }, (err: any) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: err.error
                        
                    });
                });
            }
    });

    } 

    async requestUnFollow(requestId,action) {
        
        const params = {
            requestId: requestId,
            action : action
        }
        Swal.fire({
            icon: 'info',
            title: 'Action Request',
            showCancelButton: true,
            html: 'Do you want to take this action ?',
            confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Yes',
              confirmButtonAriaLabel: 'Yes',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i> No',
              cancelButtonAriaLabel: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                this.CommunityService.requestUnFollow(params).subscribe((res: any) => {
                    this.getUserProfile();
                    if(res.success){
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: res.message
                        });
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: res.message
                            
                        });
                    }
                }, (err: any) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: err.message
                        
                    });
                });
            }
    });

    }

    getConfirmation(header: string, message: string) {
        return new Promise((resolve, reject) => {
            this.confirmationService.confirm({
                message: message,
                header: header,
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    resolve(true);
                },
                reject: () => {
                    resolve(false);
                }
            });
        });
    }

    postLike(postIdx,Liketype){
        let post = this.postsList[postIdx];
         let likeData = (post.likes.length > 0) ? post.likes.find(el => { return el.user_id == this.user.id }) : post.likes;
        //  let type = (!likeData) ? 'Like' : 'Dislike';
        let type = (!likeData) ? Liketype : Liketype
        //let type = Liketype;
        const params = {post_id: post.id, type: type};
        this.UserProfileService.doLike(params).subscribe(res => {
            if (res.success) {
                this.getUserProfile();
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: res.message
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: res.message
                })
            }
        }, error => {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message
            })
        });
    }

    getLikes(post,type){
        if(type=='Like'){
           return (post.likes.filter(el=> {return  el.type=='Like'})).length
        }
        if(type=='Dislike'){
            return (post.likes.filter(el=> {return  el.type=='Dislike'})).length
        }
    }

    postComment(postIdx, commentId = null){
        if(this.comment==''){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please type some text in comment box'
            })
            return false;
        }else{
            let post = this.postsList[postIdx];
            const formData = new FormData();
            formData.append('id',(!commentId) ? 0 : commentId);
            formData.append('post_id',post.id);
            formData.append('comment',this.comment);
            formData.append('attachment',this.attachment);
            this.UserProfileService.doComment(formData).subscribe(event => {
                if (event.type === HttpEventType.UploadProgress){
                    this.uploadStatus = Math.round(event.loaded / event.total * 100)
                } else if (event.type === HttpEventType.Response){
                    this.comment = '';
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: event.body.message
                    })
                    this.postsList=[];
                    this.getUserProfile();
                }
            }, error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error.message
                })
            });
        }
    }

    switchToUserProfile(username){
        this.router.navigateByUrl(`userprofile/${username}`);
    }

    imageZomming(post,index){
        this.postIndex=index;
        this.PostInfoImage=[];
        this.PostInfoImage.push(post);
        this.zoomimage=true;
        
    }

    async getFollowersList() {
        await this.profileService.getFollowersList().then((res) => {
            if (res) {
                this.Taguser = res.data.followingMe;
                this.getUserProfile();
            }

        }).catch((error) => {});
    }
}
