import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse} from '@angular/common/http';
import {environment} from "enviornments/environment"
import { map } from 'rxjs/operators/map';
import 'rxjs/add/operator/catch';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw'
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import {AuthService} from "../services/auth.service";

@Injectable()
export class BaseService {
    options: any;
    postHeaders: any;

    constructor(private http:HttpClient, private router:Router, private authService:AuthService) {
        this.options = {
            headers: new HttpHeaders({
                Accept: 'application/json',
                Authorization : 'Bearer ' + this.authService.getAppToken(),
                'Content-Type': 'application/json'
            })
        };
        this.postHeaders = {
            headers: new HttpHeaders().set('Authorization','Bearer ' + this.authService.getAppToken()),
            mimeType: "multipart/form-data",
            contentType: false,
            Accept: "application/json",
            reportProgress : true,
            observe : 'events',
        };
    }

    redirectionPageURl(){
        let url='';
        let windowUrl = (window.location.pathname).split("/");
        if(windowUrl.length){
            for(let i=1; i<windowUrl.length; i++){
                url += windowUrl[i] + '/';
            }
            url = url.replace(/\/$/, "");
            if(url) return {status:true,url:url};
            else return {status:false,url:''};
        }
        return {status:false,url:''};
    }

    validate(extension): Observable < any >{
        return this.http.get(`${environment.url}${extension}`,this.options)
            .pipe((map((res:any) => res)))
            .catch((err: HttpErrorResponse) =>
                this.handleError(err)
            )
    }

    get(extension,params?:null){
        return this.http.get(`${environment.url}${extension}`,this.options)
            .pipe((map((res:any) => res.data)))
            .catch((err: HttpErrorResponse) =>
                this.handleError(err)
            )
    }
    getRequest(extension,params?:null){
        return this.http.get(`${environment.url}${extension}`,this.options)
            .pipe((map((res:any) => res)))
            .catch((err: HttpErrorResponse) =>
                this.handleError(err)
            )
    }
    post(extension,body): Observable < any >{
        return this.http.post(`${environment.url}${extension}`,body, this.options)
            .pipe((map((res:any) => res)))
            .catch((err: HttpErrorResponse) =>
                this.handleError(err)
            )
    }
    postFile(extension,body){
        return this.http.post(`${environment.url}${extension}`,body, this.postHeaders)
            .pipe((map((res:any) => res)))
            .catch((err: HttpErrorResponse) =>
                this.handleError(err)
            )
    }
    put(extension,body): Observable < any >{
        return this.http.put(`${environment.url}${extension}`,body, this.options)
            .pipe((map((res:any) => res)))
            .catch((err: HttpErrorResponse) =>
                this.handleError(err)
            )
    }
    delete(extension){
        return this.http.delete(`${environment.url}${extension}`, this.options)
            .pipe((map((res:any) => res)))
            .catch((err: HttpErrorResponse) =>
                this.handleError(err)
            )
    }

    private handleError(error: Response | any) {
        var [message,self] = ["",this];
        let displayMessage = async function(error){
            if( ('error' in error) && ('message' in error.error || 'error' in error.error) ){
                let errorMessage = ('message' in error.error && error.error.message) ?  error.error.message : ('error' in error.error && error.error.error) ? error.error.error : '';
                if(errorMessage){
                    await Swal.fire(
                        {
                            icon: 'error',
                            title: 'Error',
                            text: errorMessage
                        }
                    );
                }

            }
        }

        /**
         * Application is not getting response from server HTTP response 0
         */
        if(error.status === 0){
            // displayMessage(
            //   {
            //     error : {
            //       error : "Application has stopped working."
            //     }
            //   }
            // );
            // (setTimeout(function(){ self.destroy.activeSessions(5000,message); }, 5000));
        }

        /**
         * Custom error
         */
        if(error.status === 400){
            displayMessage(error);
            // (setTimeout(function(){ console.clear() }, 1500))
        }
        /**
         * Database error
         */
        if(error.status === 503){
            displayMessage(error);
            //(setTimeout(function(){ self.destroy.activeSessions(5000,message); }, 5000));
        }
        /**
         * Internal server error(API payload error)
         */
        if(error.status === 500){
            displayMessage(error);
            //(setTimeout(function(){ console.clear() }, 1500))
        }
        /**
         * Session, license etc error
         */
        if(error.status === 401 || error.status === 402 || error.status === 403){
            displayMessage(error);
            //(setTimeout(function(){ self.destroy.activeSessions(5000,message); }, 5000));
        }

        return Observable.throw(message);
    }

}