import { Component, OnInit } from '@angular/core';
import { ReferralService } from './referral.service';
import { environment } from 'enviornments/environment';

@Component({
    selector: 'app-referral',
    templateUrl: './referral.component.html',
    styleUrls: ['./referral.component.scss']
})

export class ReferralComponent implements OnInit {
    env=environment;
    user: any = {};
    referralList:any;
    loading: boolean;
    textMessage:any; 
    msgHideAndShow:boolean=false;  
    errors: boolean;
    display1:boolean;
    constructor(private referralService: ReferralService) { }

    ngOnInit() {
        this.getUserProfile();
        this.referrals();
    }

    getUserProfile() {
        this.referralService.profile().toPromise().then(res => {
            this.user = res;
        }).catch(e => {});
    }

    referrals() {
        this.referralService.referrals().toPromise().then(res => {
            this.referralList = res;
        }).catch(e => {});
    }
    textMessageFunc(msgText){  
        this.textMessage=msgText+" Copied to Clipboard";  
        this.msgHideAndShow=true;  
        setTimeout(() => {  
          this.textMessage="";  
          this.msgHideAndShow=false;  
        }, 1000);  
         
      }
    copyInputMessage(inputElement) {  
        inputElement.select();  
        document.execCommand('copy');  
        inputElement.setSelectionRange(0, 0);  
        this.textMessageFunc('Text');    
          
      }
    
    showReadMore() {
        this.display1 = true;
        
    }
}
