import { Component, OnInit } from '@angular/core';
import { GroupFollowersService } from './followers.service';
import {Router} from '@angular/router';
import {ConfirmationService} from 'primeng/api';
import {MessageService} from 'primeng/api';
import {HttpClient} from '@angular/common/http';
import { ProfileService } from '../../../pages/profile/profile.service';
import { generalHelper } from "../../../helpers/generalHelper";
import Swal from "sweetalert2";
import { imagePathHelper } from "../../../helpers/imagePathHelper";
import { CommunityService } from 'app/website/community/community.service';
import { environment } from 'enviornments/environment';

@Component({
    selector: 'app-group-followers',
    templateUrl: './followers.component.html',
    styleUrls: ['./followers.component.scss']
})

export class GroupFollowersComponent implements OnInit {
  env=environment;
    generalHelper = generalHelper;
    user: any = {};
    loading: boolean;
    errors: boolean;
    Pricing: any;
    Price= false;
    Pendingfollowers:any=[];
    allPendingfollowers:any=[];
    totalPendingFollowers = 0;
    showPendingRecord: any = 100;
    Allfollowers:any=[];
    approveFollowers:any=[];
    totalFollowers = 0;
    showRecord: any = 100;
    AllfollowedByMe:any=[];
    followedByMe:any=[];
    totalfollowedByMe = 0;
    showfollowedByMeRecord: any = 100;
    followedByMelist=false;
    pendingfollowerslist=true;
    imagePath = imagePathHelper;
    followerslist=false;
    FollowersEventNextItr: number;
    followedByMeEventNextItr: number;
    PendingFollowersEventNextItr: number;
    tabListName:any='';
    userfollowers:any=[];
    constructor(private followersService: GroupFollowersService,private profileService: ProfileService, private CommunityService: CommunityService, public http:HttpClient,private router: Router, private confirmationService: ConfirmationService, private messageService: MessageService) { }

    ngOnInit() {
        
        this.getFollowersList();
        this.showListingPanel('PendingfollowersList')
        this.getFollowersPendingList();
        this.getUserFollowersList();
        this.getUserProfile();
    }

    getUserProfile() {
        this.profileService.profile().subscribe(res => {
            this.user = res.id;
        },error => {});
    }

    showListingPanel(tab){
        this.tabListName=tab;
        if(tab=='followersList'){this.followerslist=true;this.pendingfollowerslist=false;this.followedByMelist=false;}
        else if(tab=='PendingfollowersList'){this.followerslist=false;this.pendingfollowerslist=true;this.followedByMelist=false;}
        else{this.followerslist=false;this.pendingfollowerslist=false;this.followedByMelist=true;}
    }
    
    getFollowersList() {
        this.followersService.getFollowersList().then((res) => {
            if (res.success) {
                this.manageFollowers(res.data.followingMe, 0);
                this.Allfollowers = res.data.followingMe;
                this.manageFollowedByMe(res.data.followedByMe, 0);
                this.AllfollowedByMe = res.data.followedByMe;
                // this.followersService.setFollowers(res.data.followingMe);
                // this.followersService.setFollowedUser(res.data.followedByMe);
            }
        }).catch((error) => {});
    }

    getUserFollowersList() {
      
      this.followersService.getUserFollowersList().then((res) => {
          if (res.success) {
              this.userfollowers = res.data
          }
      }).catch((error) => {});
      
  }

  getUserFollowersStatus(listusername){
    let user= (this.userfollowers.find((el)=> { return el.username==listusername}));
    console.log('uuuuuuuuuu',user)
    return user.status ? `${user.status}` : 'nnnn';
    
  }

    filterFollowers(event) {
        this.approveFollowers = [];
        if (event) {
          this.FollowersSearch(this.Allfollowers, event);
          this.totalFollowers	=	this.Allfollowers.length;
        } else {
          this.manageFollowers(this.Allfollowers, 0);
          this.totalFollowers	=	this.Allfollowers.length;
        }
      }
      
      FollowersSearch(array, search) {
        return array.filter((el) => {
          for (const param in el) {
            if (el.hasOwnProperty(param) ) {
              if ( el[param] &&  el[param].toString().toLowerCase().indexOf(search.toLowerCase()) !== -1) {
                this.approveFollowers.push(el);
                break;
              }
            }
          }
        });
      }
    
    manageFollowers(data, itr) {
        this.approveFollowers = [];
        const startItr    = (itr * this.showRecord);
        const endItr      = (startItr + this.showRecord) - 1;
        for (let i = 0; i < data.length; i++) {
          if (i >= startItr && i <= endItr) {
            this.approveFollowers.push(data[i]);
          }
        }
        this.FollowersEventNextItr = startItr + 1;
    }
    
    filterFollowedByMe(event) {
        this.followedByMe = [];
        if (event) {
          this.FollowedByMeSearch(this.AllfollowedByMe, event);
          this.totalfollowedByMe	=	this.AllfollowedByMe.length;
        } else {
          this.manageFollowedByMe(this.AllfollowedByMe, 0);
          this.totalfollowedByMe	=	this.AllfollowedByMe.length;
        }
      }
      
      FollowedByMeSearch(array, search) {
        return array.filter((el) => {
          for (const param in el) {
            if (el.hasOwnProperty(param) ) {
              if ( el[param] &&  el[param].toString().toLowerCase().indexOf(search.toLowerCase()) !== -1) {
                this.followedByMe.push(el);
                break;
              }
            }
          }
        });
      }
    
      manageFollowedByMe(data, itr) {
        this.followedByMe = [];
        const startItr    = (itr * this.showfollowedByMeRecord);
        const endItr      = (startItr + this.showfollowedByMeRecord) - 1;
        for (let i = 0; i < data.length; i++) {
          if (i >= startItr && i <= endItr) {
            this.followedByMe.push(data[i]);
          }
        }
        this.followedByMeEventNextItr = startItr + 1;
    }

    getFollowersPendingList() {
        this.followersService.getFollowersPendingList().then((res) => {
            if (res.success) {
                this.managePendingFollowers(res.data, 0);
                this.Pendingfollowers = res.data;
            }
        }).catch((error) => {});
    }

    PendingFollowersSearch(array, search) {
        return array.filter((el) => {
          for (const param in el) {
            if (el.hasOwnProperty(param) ) {
              if ( el[param] &&  el[param].toString().toLowerCase().indexOf(search.toLowerCase()) !== -1) {
                this.allPendingfollowers.push(el);
                break;
              }
            }
          }
        });
      }

    filterPendingFollowers(event) {
        this.allPendingfollowers = [];
        if (event) {
          this.PendingFollowersSearch(this.Pendingfollowers, event);
          this.totalPendingFollowers	=	this.Pendingfollowers.length;
        } else {
          this.managePendingFollowers(this.Pendingfollowers, 0);
          this.totalPendingFollowers	=	this.Pendingfollowers.length;
        }
      }

    managePendingFollowers(data, itr) {
        this.allPendingfollowers = [];
        const startItr    = (itr * this.showRecord);
        const endItr      = (startItr + this.showRecord) - 1;
        for (let i = 0; i < data.length; i++) {
          if (i >= startItr && i <= endItr) {
            this.allPendingfollowers.push(data[i]);
          }
        }
        this.PendingFollowersEventNextItr = startItr + 1;
    }

    async requestFollow(action,requestId) {
        const params = {
            'requestId': requestId,
            'action':action
        }
        Swal.fire({
          icon: 'info',
          title: 'Action Request',
          showCancelButton: true,
          html: 'Do you want to take this action ?',
          confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> Yes',
              confirmButtonAriaLabel: 'Yes',
              cancelButtonText:
                '<i class="fa fa-thumbs-down"></i> No',
              cancelButtonAriaLabel: 'No'
      }).then((result) => {
          if (result.isConfirmed) {
            this.followersService.requestFollow(params).subscribe((res: any) => {
              this.getFollowersList();
              this.getFollowersPendingList();
              Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: res.message
              }).then( function(){
                window.location.reload();
            }
            );
              
          }, (err: any) => {
              Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: err.error
                  
              })
          });
          }
  });

    } 

    async requestFollowBack(ID) {
      const params = {
          'to_user': ID,
          'from_user':this.user
      }
      Swal.fire({
          icon: 'info',
          title: 'Send Request',
          showCancelButton: true,
          html: 'Do you want to send your request ?',
          confirmButtonText:
              '<i class="fa fa-thumbs-up"></i> Yes',
            confirmButtonAriaLabel: 'Yes',
            cancelButtonText:
              '<i class="fa fa-thumbs-down"></i> No',
            cancelButtonAriaLabel: 'No'
      }).then((result) => {
          if (result.isConfirmed) {
              this.CommunityService.requestFollow(params).subscribe((res: any) => {
                  this.getFollowersList();
                  if(res.success){
                      this.getUserProfile();
                      Swal.fire({
                          icon: 'success',
                          title: 'Success',
                          text: res.message
                      });
                  }else{
                      Swal.fire({
                          icon: 'error',
                          title: 'Error',
                          text: res.message
                      });
                  }
              }, (err: any) => {
                  Swal.fire({
                      icon: 'error',
                      title: 'Error',
                      text: err.error
                      
                  });
              });
          }
  });

  } 


    getConfirmation(header: string, message: string) {
        return new Promise((resolve, reject) => {
            this.confirmationService.confirm({
                message: message,
                header: header,
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    resolve(true);
                },
                reject: () => {
                    resolve(false);
                }
            });
        });
    }
    
}
